const enTranslation = {
  elite_speaker: "Elite Speaker",
  user_management: "User Management",
  account_management: "Account management",
  become_a_speaker_list: "Become a Speaker List",
  unapproved_comments: "Unapproved Comments",
  recommended_tags_management: "Recommended Tags management",
  tags_management: "Tags management",
  product_listing: "Products listing",
  archive_products: "Archive Products",
  general_settings: "General Settings",
  title: "Title",
  title_paragraph: "Title Paragraph",
  paragraph: "Paragraph",
  video_link: "Video Link",
  terms_constions: "Terms & conditions",
  add_more: "Add More",
  submit: "Submit",
  remove: "Remove",

  assessment_management: "Assessment Management",
  rank: "Rank",
  name: "Name",
  email: "Email",
  seats_category: "Seats Category",
  seats: "Seats",
  total: "Total",
  invite_category: "Invite Category",
  invites: "Invites",
  rating: "Rating",
  sheep_rating: "Sheep Rating",
  tags: "Tags",
  edit_tags: "Edit tags",
  actions: "Actions",
  export: "Export",
  add_expert: "Add Expert",
  loading: "Loading",
  search: "Search",
  select: "Select",
  select_role: "Select Role",
  freeze_account: "Freeze account",
  unfreeze_account: "Unfreeze account",
  edit: "Edit",
  edited:"Edited",
  remarks: "Remarks",
  email_list: "Email list",
  delete: "Delete",
  transporter: "Transporter",
  merge: "Merge",
  tag_names: "Tag Names",
  categories: "Categories",
  save: "Save",
  reset_rating: "Reset rating",
  confirm_to_delete: "Confirm to delete",
  are_you_sure_to_do_this: "Are you sure to do this.",
  yes: "Yes",
  no: "No",
  copy_to_kongress: "Copy to Kongress",
  transport_the_expert_to_the_following_kongress_domain:
    "Transport the expert to the following kongress domain",
  digistore_id: "Digistore ID",
  links: "Links",
  home_page_term: "Homepage displayed term",
  home_page_link: "Link/URL Homepage",
  home_page_link_tooltip1: "Please enter the actual link here - i.e. the URL of the homepage. If users now click on the previously entered term, a new window will open and the homepage entered here will be displayed. Please enter the link in full, or preferably call up the homepage and copy the link above from the browser. This ensures that there are no typos. Please always try the links briefly to see if everything works as desired.",
  news_letter_term: "Newsletter displayed term",
  news_letter_link:  "Link/URL Newsletter",
  news_letter_link_tooltip1:"Please enter the actual link to the homepage here, where you can register for the newsletter. If users now click on the previously entered term, a new window will open and the page entered here for the newsletter registration will be displayed. Please enter the link in full, or ideally call up the newsletter registration page and copy the link above from the browser. This ensures that there are no typos. Please always try the links briefly to see if everything works as desired.",
  freebie_term: "Freebie displayed term",
  freebie_link: "Link/URL Freebie Page",
  freebie_link_tooltip1:"Please enter the actual link here - i.e. the URL of the freebie page. Now, when users click on the previously entered term, a new window will open and the freebie or gift page entered here will be displayed. Please enter the link in full, or it is best to call up the freebie page or gift page and copy the link above from the browser. This ensures that there are no typos. Always try the links briefly to see if everything works as desired.",
  contact_email_term: "Contact email displayed term",
  contact_email: "Contact Email address",
  contact_email_tooltip1: "Please enter the actual email here, i.e. the contact email to which the request should be sent. If users now click on the previously entered term, the standard e-mail browser is opened and an e-mail is prepared for the address entered here. Please ensure that there are no typographical errors in the email address. You are also welcome to try the contact e-mail function to see if everything works as desired.",
  social_media_links: "Social media links",
  facebook_link: "Facebook link",
  copy: "Copy",
  instagram_link: "Instagram link",
  twitter_link: "Twitter link",
  youtube_link: "YouTube link",
  vimeo_link: "Vimeo link",
  pinterest_link: "Pinterest link",
  xing_link: "Xing link",
  linkedin_link: "LinkedIn link",
  telegram_link: "Telegram link",
  podcast_link: "Podcast link",
  spotify_link: "Spotify link",
  clubhouse_link: "Clubhouse link",
  tiktok_link: "TikTok link",
  products: "Products",
  archived_products: "Archived products",
  expert_short_description: "Expert short description",
  expert_long_description: "Expert long description",
  merge_accounts: "Merge Accounts",
  cancel: "Cancel",
  update: "Update",
  recommend_a_tag: "Recommend a Tag",
  add_new_expert: "Add new expert",
  edit_expert: "Edit expert",
  photo: "Photo",

  all_user_roles: "All user roles",
  superAdmin: "SuperAdmin",
  manager: "Manager",
  viewer: "Viewer",
  all_user_statuses: "All user statuses",
  active: "Active",
  inactive: "Inactive",
  allowed_domains: "Allowed Domains",
  role: "Role",
  status: "Status",
  add_user: "Add User",
  save_settings: "Save Settings",
  update_user: "Update User",
  account_name: "Account Name",
  host: "Host",
  domain: "Domain",
  last_synced: "Last Synced",
  edit_account: "Edit Account",
  public_domain: "Public Domain",
  database_settings: "Database Settings",
  user: "User",
  password: "Password",
  database_name: "Database Name",
  no_data_found: "No Data Found!",

  id: "ID",
  full_name: "Full Name",

  sr: "Sr.",
  author: "Author",
  comment: "Comment",
  back: "Back",

  recommended_by: "Recommended by",
  recommended_for: "Recommended for",
  approve: "Approve",

  sub_tags: "Sub-tags",
  manage_child_tags: "Manage Child Tags",
  add_tag: "Add Tag",

  digistore_link: "Digistore link",
  change_password: "Change Password",
  add_account: "Add Account",
  refresh_account_successfully_scheduled:
    "Refresh Account Successfully Scheduled",
  synced_in_progress: "Synced in progress",
  name_is_required: "Name is required",
  public_domain_is_required: "Public domain is required",
  host_is_required: "Host is required",
  user_is_required: "User is required",
  password_is_required: "Password is required",
  database_name_is_required: "Database name is required",
  phone: "Phone",
  view: "View",
  reject: "Reject",
  are_you_sure_delete_product: "Are you sure you want to delete this product?",
  additional_information: "Additional Information",
  description_details: "Description Details",
  short_description: "Short Description:",
  long_description: "Long Description:",
  direct_links: "Direct Links",
  no_data: "No Data",
  view_product: "View Product",
  no_products_available: "No products available",
  social_links: "Social Media Links",
  no_social_links_available: "No social links available",
  tag: "Tag",
  tags: "Tags",
  tag_is_required: "Tag is required",

  confirm_to_freeze_unfreeze: "Confirm to freeze/unfreeze",
  confirm_to_restore: "Confirm to restore",
  more: "more",
  neuen_tag_vorschlagen: "Neuen Tag vorschlagen",
  download_me: "Download me",
  product_listing: "Product Listing",
  details: "Details",

  product_listing: "Product Listing",
  details: "Details",
  products: "Products",
  archived_assessment: "Archived Assessment",
  recommended_tags: "Recommended Tags",
  unapproved_comments: "Unapproved Comments",
  application_list: "Application List",
  account: "Account",
  welcome: "Welcome",
  expert: "Expert",
  shop: "Shop",
  assessment: "Assessment",
  application_settings: "Application Settings",
  logout: "Logout",

  experts_welcome_message1:
    " We're glad you're here, in the expert backend of our conference software platform from",
  experts_welcome_message2:
    "Please take a quick look at the video. Here you can make the settings for your profile, your interview, and the products you want to offer. If you have any questions, please contact gregor@teiledeinebotschaft.de",
  experts_welcome_message3:
    "Best regards, Gregor Dorsch (Managing Director and Founder of TeileDeineBotschaft.de)",
  experts_welcome_guide: "Experts: A short welcome and a short guide",
  experts_application_guide:
    "Please apply here, enter all the required information, and we will get back to you promptly. Thank you.",
  online_congress: "Online Congress",
  title_expert_eg: "Title (e.g., Prof., Dr., etc.)",
  expert_short_description_40: "Expert Short Description (<40 Characters)",
  email_address_of_expert: "Email address of the expert",
  mobile_number: "Mobile Number",
  profile_description: "Profile Description (professional background)",
  no_products_found: "No products found",
  type_product_title: "Type product title...",
  add_product: "Add Product",
  product_title: "Product Title",
  price: "Price",
  description: "Description",
  product_link: "Product Link",
  product_image: "Product Image:",
  crop_preview: "Crop Preview",
  upload_a_photo: "Upload a photo",
  crop_your_image: "Crop Your Image",
  ok:"OK",
  image_upload_tooltip:"Please upload the image and then select a square crop.",
  image_upload_tooltip_expert:"Upload the main image of the product here. With the upload, you can choose a square crop. The crop will be automatically optimized and saved as a square with 300x300 pixels. Give it a try; it's quite simple—just make sure that in the end, we only accept square images.",
  no_image:"No image",
  product_image_tooltip:"Upload the main image of the product here. With the upload, you can choose a square crop. The crop will be automatically optimized and saved as a square with 300x300 pixels. Give it a try; it's quite simple—just make sure that in the end, we only accept square images.",
  product_image:"Product Image",
  product_name_title_description_tooltip: "Product name, product title, or product description that will be used as the title or heading for the product. If a product title is entered, the following details (link to the product) and the product image are mandatory fields! Therefore, create zero, one, two, or three products that should be offered in the congress through the congress shop.",
  product_link_tooltip: "Please provide the link to the sales page or booking page of the product, service, etc. If possible, please create an affiliate link for the kongress organizer. Thank you.",
  
  product_title_required: "Product title is required",
  product_link_required: "Product link is required",
  product_updated_successfully: "Product updated successfully",
  new_product_added_successfully: "New product added successfully",
  product_deleted_successfully: "Product deleted successfully!",
  edit_product: "Edit Product",
  first_name_required: "First Name is required",
  digistore_id_required: "Digistore-ID is required",
  profile_image_required: "Profile image is required",
  email_address_required: "Email address is required",
  valid_email_required: "Please enter a valid email address",
  updated_user_successfully: "Updated user successfully!",
  expert_short_description_tooltip: "The expert abbreviation should be a very short and keyword-like mini-designation of the speaker's expertise. This designation is included under the name of the expert. It is limited to a maximum of 40 characters and should be as short as possible so that there are no line breaks in the view. The wording can be kept a little funny, nice, exaggerated, attractive. Good examples of expert short descriptions would be: star biologist, Oscar winner, online guru, bestselling author, meditation master, practice expert, implementation master, etc. Is the idea of this field clearly described?",
  digistore_id_tooltip:" You will find your Digistore ID in your Digistore account after logging in in the top left corner. The Digistore ID is required for the automatic assignment of affiliate commissions and the corresponding tracking. Digistore is one of the most common methods for online offers and online products and offers a very good, reliable affiliate system. If you don't have a Digistore account yet, you can create a new one here free of charge: https://www.digistore24.com/de/signup. You can easily set up an affiliate account unless you plan to sell your own products via Digistore. If you intend to do this, you can create a vendor and affiliate account right away. Also free of charge. If you have any questions, please feel free to contact technik@teiledeinebotschaft.de.",
  email_address_of_expert_tooltip: "Please enter the email address here. This email will also be added to the user management system. With this email address (and the corresponding password), experts can log in to the frontend to follow the kongress and also access the expert backend to edit, adjust, and add to their profile, products, and interview descriptions. If you are the expert and have arrived here via the expert login, we warmly welcome you! When we refer to",
  profile_description_tooltip: "Career background, resume, or description of the expert. This description will be displayed in the expert profile on the page, both when logged out for everyone to see and in the logged-in member area.",
  direct_links_tooltip: "These links appear directly in the expert profile. They are visible to everyone, both in the logged out and in the logged-in area (members area).",
  social_links_tooltip: "The links to social media channels appear as small icons directly in the expert profile. They are visible to everyone, both in the logged-out state and in the logged-in area (member area).",
  social_links_tooltip: "The links to social media channels appear as small icons directly in the expert profile. They are visible to everyone, both in the logged-out state and in the logged-in area (member area).",
  application_terms_conditions:"Please confirm the terms and conditions.",
  application_terms_conditions_tooltip:"Please confirm that the terms and conditions for participation as an expert have been understood, ensuring that a fair principle of participation and contribution is maintained. Together, we will create a large audience, in which everyone should be able to participate.",
  submit_application:"Submit Application",
  products_services_courses:"Products • Services • Courses",
  products_services_courses_tooltip:"You can list zero, one, two, or three products, which will be displayed in the shop. These products can then be directly linked under the interview video, creating a great sales opportunity from the interview. The kongress shop only links to the actual product sales page.",
  application_email_list:"How large is your email list?",
  application_email_list_tooltip:" Free text description including formatting. Here you can e.g. B. Insert the CV or a description of the expert.",
  first_name:"First Name",
  last_name:"Last Name",
  error_last_name_required:"Last Name is required",
  error_confirm_terms_conditions:"Please confirm the terms and conditions. Thank you.",
  product:"Product",
  error_product_title:"title is required if an image is provided.",
  error_product_link:"link is required if an image is provided.",
  error_product_image:"image is required.",
  form_submit_successfully:"Form sumbitted successfully!",  
  become_a_speaker:"Become a speaker",
  login:"Login",
  forgot_password:"Forgot Password",
  small_list:"Small List",
  over:"Over",
  save_changes:"Save Changes",
   
  success_speaker_delete:"Speaker user deleted successfully!",
  success_approved_user:"Approved user successfully!",
  success_rejected_user:"Rejected user successfully!",
  success_expert_copied:"Expert copied successfully",
  success_tags_requested:"Tags requested successfully!",
  success_settings_updated:"Setting updated successfully",

  thank_you:"Thank you",
  thank_you_recieve:"Thank you for submitting your application! We have successfully received it.",
  your_kongress_team:"Your Kongress team",
  thank_you_review:" Please allow us some time to review your details, and we will get back to you shortly. You will also receive a confirmation email, which includes a phone number for urgent matters. We're looking forward to speaking with you soon.",

  are_you_sure_delete:"Are you sure to delete this application",
  do_you_want_to_approve:"Do you want to approve the application",
  do_you_want_to_reject:"Do you want to reject the application",
  error_title_required:"Title is required!",
  do_you_want_to_approve_tags:"Do you want to approve the tags?",
  approve_tags:"Approve Tags",
  required:"Required!",
  error_something_went_wrong:"Something went wrong. Please try again.",
  confirm_to_recommend_tags: "Confirmation to recommend the tags",
  are_you_sure_recommended_tags: "Are you sure you want to recommend the tags?",
  
  application_email_template:"Application Email",
  email_template:"Email Template",
  email_subject:"Subject",
  email_content:"Email Content",
  email_regards:"Regards",
  preview:"Preview",
  email_save_template:"Save Template",
  add_template:"Add Template",
  email_template_list:"Email Template List",
  add_new_email_template:"Add New Email Template",
  update_email_template:"Update Email Template",
  code:"Code",
  replacements:"Replacements",
  message:"Message",
  no_email_templates_available:"No email templates available",
  error_code_required:"Code is required!",
  error_subject_required:"Subject is required!",
  error_replacements_required:"Replacement is required!",
  update:"Update",
  no_message:"No message",
  session_expired:'Your session has expired. Please log in again.',
  make_expert:"Make Expert",
  password_optional:"Password (optional)",

};

export default enTranslation;
