import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SideBar from "../Dashboard/SideBar";
import {
  deleteEmailTemplate,
  getEmailTemplateList,
  postEmailTemplate,
  updateEmailTemplate,
} from "../../Components/action/emailTemplate";
import { t } from "i18next";
import { confirmAlert } from "react-confirm-alert";
import Pagination from "react-js-pagination";

const EmailTemplateForm = () => {
  const [formData, setFormData] = useState({
    title: "",
    code: "",
    subject: "",
    message: "",
    replacements: "",
  });

  const [emailList, setEmailList] = useState([]);
  const [isEmailList, setIsEmailList] = useState(true);
  const [editIndex, setEditIndex] = useState(null);
  const [errors, setErrors] = useState({});
  const [modalMessage, setModalMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(2);
  const [totalPages,setTotalPages] = useState(null)
  const [totalRecords,setTotalRecords] = useState(null)
  const [limits, setLimits] = useState(null);
  const [isLoader,setIsLoader] = useState(false)

  const fetchEmailTemplateList = async () => {
    try {
      const response = await getEmailTemplateList({ page,perPage });
      const data = response?.data?.responseData;
      setTotalPages(data?.totalPages)
      setTotalRecords(data?.totalRecords)
      setEmailList(data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    fetchEmailTemplateList();
  }, [page]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({ ...prevData, message: data }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.title) newErrors.title = t('error_title_required');
    if (!formData.code) newErrors.code = t('error_code_required');
    if (!formData.subject) newErrors.subject =t('error_subject_required');
    if (!formData.replacements) newErrors.replacements = t('error_replacements_required');
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
        setIsLoader(true)
        try {
          if (editIndex !== null) {
            const datatToSend = {};
            datatToSend.title = formData?.title;
            datatToSend.code = formData?.code;
            datatToSend.subject = formData?.subject;
            datatToSend.replacements = formData?.replacements;
            datatToSend.message = formData?.message;
            await updateEmailTemplate(editIndex, { ...datatToSend });
            fetchEmailTemplateList();
          } else {
            await postEmailTemplate({ ...formData });
            fetchEmailTemplateList();
          }
          setIsLoader(false)
          setFormData({
            title: "",
            code: "",
            subject: "",
            message: "",
            replacements: "",
          });
          setEditIndex(null);
          setIsEmailList(true);
          
        } catch (error) {
          setIsLoader(false)
        }
    }
  };

  const handleEdit = (email) => {
    setFormData(email);
    setEditIndex(email?.id);
    setIsEmailList(false);
    setIsLoader(false)
  };

  const handleDelete = async (email) => {
    confirmAlert({
      title: t("confirm_to_delete"),
      message: t("are_you_sure_to_do_this"),
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            try {
              await deleteEmailTemplate(email?.id);
              await fetchEmailTemplateList();
            } catch ({ response }) {}
          },
        },
        {
          label: t("no"),
        },
      ],
    });
  };

  const handleCancel = () => {
    setIsEmailList(true);
    setFormData({
      title: "",
      code: "",
      subject: "",
      message: "",
      replacements: "",
    });
    setEditIndex(null);
    setIsLoader(false)
  };

  const handleMessageClick = (message) => {
    setModalMessage(message); 
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); 
    setModalMessage(""); 
  };


  const handlePageChange = (page) => {
   setPage(page)
//    fetchEmailTemplateList();
  };


  return (
    <>
      <SideBar />
      <div className="content-page">
        <div className="mt-0 py-5 px-10 max-h-[98%] overflow-auto">
          <div className="my-8 flex justify-between">
            <p className="text-2xl font-bold">
              {isEmailList ?t('email_template_list') :editIndex?t('update_email_template'):t('add_new_email_template')}
            </p>
            {isEmailList && (
              <button
                className="bg-btnColor text-white rounded-md hover:scale-105"
                onClick={() => setIsEmailList(false)}
              >
                {t('add_template')}

              </button>
            )}
          </div>
          {isEmailList ? (
            <div className="userlisting">
              <table className="min-w-full dataTable table-auto border-collapse shadow-md rounded-lg overflow-hidden bg-white">
                <thead className="">
                  <tr className="">
                    <th className="border p-4 text-left font-semibold">
                      {t('title')}
                    </th>
                    <th className="border p-4 text-left font-semibold">{t('code')}</th>
                    <th className="border p-4 text-left font-semibold">
                      {t('email_subject')}
                    </th>
                    <th className="border p-4 text-left font-semibold">
                     {t('replacements')}
                    </th>
                    <th className="border p-4 text-left font-semibold">
                      {t('message')}
                    </th>
                    <th className="border p-4 text-center font-semibold">
                      {t('actions')}
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  {emailList.length === 0 ? (
                    <tr>
                      <td
                        colSpan="5"
                        className="border p-4 text-center text-gray-500"
                      >
                        {t('no_email_templates_available')}
                      </td>
                    </tr>
                  ) : (
                    emailList.map((email, index) => (
                      <tr
                        key={index}
                        className="hover:bg-gray-100 border border-b-2 py-2 transition-all duration-300 ease-in-out"
                      >
                        <td className=" p-4">{email.title}</td>
                        <td className="p-4">{email.code}</td>
                        <td className="p-4">{email.subject}</td>
                        <td className="p-4">{email.replacements}</td>
                        <td className="p-4">
                          <span
                            className="underline hover:cursor-pointer text-btnColor"
                            onClick={() => handleMessageClick(email.message)}
                          >
                            {t('view')}
                          </span>
                        </td>
                        <td className="p-4">
                          <div className="flex gap-4 justify-center">
                            <img
                              onClick={() => handleEdit(email)}
                              src="/images/edit.png"
                              alt="Edit"
                              className="hover:cursor-pointer"
                            />
                            <img
                              onClick={() => handleDelete(email)}
                              src="/images/delete.png"
                              alt="Delete"
                              className="hover:cursor-pointer"
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              
              </table>

              {totalPages > 1 && (
                <div style={{ textAlign: "center" }}>
                  <Pagination
                    activePage={page}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalRecords}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                  />
                </div>
              )}

            </div>
          ) : (
            <form
              className="flex flex-col lg:flex-row gap-8 overflow-auto"
              onSubmit={handleSubmit}
            >
              {/* Left Section */}
              <div className="w-full lg:w-2/3 bg-gray-100 shadow-md p-6 rounded-lg">
                <div className="mb-4 flex gap-10 w-full">
                  <div className="w-[50%]">
                    <label
                      className="block text-2xl font-medium mb-2"
                      htmlFor="title"
                    >
                      {t('title')}
                    </label>
                    <input
                      type="text"
                      id="title"
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                      className={`w-full px-4 form-control py-2 border rounded-md`}
                    />
                    {errors.title && (
                      <p className="text-red-500 text-2xl mt-1">
                        {errors.title}
                      </p>
                    )}
                  </div>

                  <div className="w-[50%]">
                    <label
                      className="block text-2xl font-medium mb-2"
                      htmlFor="code"
                    >
                      {t('code')}
                    </label>
                    <input
                      type="text"
                      id="code"
                      name="code"
                      value={formData.code}
                      onChange={handleInputChange}
                      className={`w-full px-4 form-control py-2 border rounded-md `}
                      disabled={editIndex?true:false}
                    />
                    {errors.code && (
                      <p className="text-red-500 text-2xl mt-1">
                        {errors.code}
                      </p>
                    )}
                  </div>
                </div>

                <div className="mb-4">
                  <label
                    className="block text-2xl font-medium mb-2"
                    htmlFor="subject"
                  >
                   {t('email_subject')}
                  </label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    className={`w-full px-4 form-control py-2 border rounded-md`}
                  />
                  {errors.subject && (
                    <p className="text-red-500 text-2xl mt-1">
                      {errors.subject}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label
                    className="block text-2xl font-medium mb-2"
                    htmlFor="replacements"
                  >
                    {t('replacements')}
                  </label>
                  <input
                    type="text"
                    id="replacements"
                    name="replacements"
                    value={formData.replacements}
                    onChange={handleInputChange}
                    className={`w-full px-4 form-control py-2 border rounded-md `}
                  />
                  {errors.replacements && (
                    <p className="text-red-500 text-2xl mt-1">
                      {errors.replacements}
                    </p>
                  )}
                </div>

                <div className="mb-4 custom-ckeditor">
                  <label
                    className="block text-2xl font-medium mb-2"
                    htmlFor="message"
                  >
                    {t('message')}
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={formData.message}
                    onChange={handleEditorChange}
                  />
                </div>

                <div className="flex justify-end gap-4">
                  <button
                    type="button"
                    className="px-4 py-2 bg-gray-200 text-gray-700 hover:scale-105 rounded-md"
                    onClick={handleCancel}
                  >
                    {t('cancel')}
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-btnColor text-white hover:scale-105 rounded-md"
                    disabled={isLoader?true:false}
                  >
                    {isLoader?`${t('loading')}...` :editIndex?t('update'):t('save')}
                  </button>
                </div>
              </div>
              <div className="w-full lg:w-1/2 bg-gray-100 shadow-md p-6 rounded-lg">
                <h3 className="text-2xl font-bold mb-4">{t('preview')}</h3>
                <div
                  className="border p-4 rounded-md min-h-[200px] max-h-[500px] overflow-auto bg-white"
                  dangerouslySetInnerHTML={{
                    __html: formData.message || t('no_message'),
                  }}
                />
              </div>
            </form>
          )}
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg top-0 shadow-lg p-6 max-h-[90%] overflow-auto w-full lg:w-1/2">
            <div className="w-full flex justify-end">
              <button
                className="text-red-600 text-lg font-bold mb-4"
                onClick={handleCloseModal}
              >
                ✖
              </button>
            </div>
            <div
              className="text-2xl"
              dangerouslySetInnerHTML={{
                __html: modalMessage || "No message",
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default EmailTemplateForm;
