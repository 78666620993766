const deTranslation = {
  elite_speaker: "Elite Sprecher",
  user_management: "Benutzerverwaltung",
  account_management: "Kontoverwaltung",
  become_a_speaker_list: "Liste der Sprecher werden",
  unapproved_comments: "Ungeprüfte Kommentare",
  recommended_tags_management: "Verwaltung empfohlener Tags",
  tags_management: "Tag-Verwaltung",
  product_listing: "Produktauflistung",
  archive_products: "Produkte archivieren",
  general_settings: "Allgemeine Einstellungen",
  title: "Titel",
  title_paragraph: "Titel Absatz",
  paragraph: "Absatz",
  video_link: "Video-Link",
  terms_constions: "Allgemeine Geschäftsbedingungen",
  add_more: "Mehr hinzufügen",
  submit: "Absenden",
  remove: "Entfernen",

  assessment_management: "Bewertungsmanagement",
  rank: "Rang",
  name: "Name",
  email: "E-Mail",
  seats_category: "Sitzkategorie",
  seats: "Sitze",
  total: "Gesamt",
  invite_category: "Einladungskategorie",
  invites: "Einladungen",
  rating: "Bewertung",
  sheep_rating: "Schafbewertung",
  tags: "Tags",
  edit_tags: "Tags bearbeiten",
  actions: "Aktionen",
  export: "Exportieren",
  add_expert: "Experten hinzufügen",
  loading: "Lädt",
  search: "Suche",
  select: "Auswählen",
  select_role: "Rolle auswählen",
  freeze_account: "Konto einfrieren",
  unfreeze_account: "Konto auftauen",
  edit: "Bearbeiten",
  edited: "Bearbeitet",
  remarks: "Bemerkungen",
  email_list: "E-Mail Liste",
  delete: "Löschen",
  transporter: "Transporteur",
  merge: "Zusammenführen",
  tag_names: "Tag-Namen",
  categories: "Kategorien",
  save: "Speichern",
  reset_rating: "Bewertung zurücksetzen",
  confirm_to_delete: "Löschen bestätigen",
  are_you_sure_to_do_this: "Bist du sicher, dass du das tun möchtest?",
  yes: "Ja",
  no: "Nein",
  copy_to_kongress: "Kopiere zum Kongress",
  transport_the_expert_to_the_following_kongress_domain: "Transportiere den Experten zu folgendem Kongress-Domain",
  digistore_id: "Digistore-ID",
  links: "Links",
  home_page_term: "Homepage-Anzeigebegriff",
  home_page_link: "Link/URL Homepage",
  home_page_link_tooltip1: "Bitte gib hier den tatsächlichen Link ein, z.B. die URL der Homepage. Wenn Benutzer jetzt auf den zuvor eingegebenen Begriff klicken, öffnet sich ein neues Fenster und die hier eingegebene Homepage wird angezeigt. Bitte gib den Link vollständig ein oder rufe die Homepage auf und kopiere den Link oben aus dem Browser. So vermeidest du Tippfehler. Bitte teste immer die Links kurz, um sicherzustellen, dass alles wie gewünscht funktioniert.",
  news_letter_term: "Newsletter-Anzeigebegriff",
  news_letter_link: "Link/URL Newsletter",
  news_letter_link_tooltip1: "Bitte gib hier den tatsächlichen Link zur Homepage ein, wo du dich für den Newsletter anmelden kannst. Wenn Benutzer jetzt auf den zuvor eingegebenen Begriff klicken, öffnet sich ein neues Fenster und die Seite für die Newsletter-Anmeldung wird angezeigt. Gib den Link bitte vollständig ein oder rufe die Anmeldeseite auf und kopiere den Link aus dem Browser. So vermeidest du Tippfehler. Teste die Links immer kurz, um sicherzustellen, dass alles funktioniert.",
  freebie_term: "Freebie-Anzeigebegriff",
  freebie_link: "Link/URL Freebie-Seite",
  freebie_link_tooltip1: "Bitte gib hier den tatsächlichen Link ein, d.h. die URL der Freebie-Seite. Wenn Benutzer jetzt auf den zuvor eingegebenen Begriff klicken, öffnet sich ein neues Fenster und die Freebie- oder Geschenkseite wird angezeigt. Gib den Link bitte vollständig ein oder rufe die Freebie-Seite auf und kopiere den Link aus dem Browser. So vermeidest du Tippfehler. Teste die Links immer kurz, um sicherzustellen, dass alles funktioniert.",
  contact_email_term: "Kontakt-E-Mail-Anzeigebegriff",
  contact_email: "Kontakt-E-Mail-Adresse",
  contact_email_tooltip1: "Bitte gib hier die tatsächliche E-Mail-Adresse ein, an die die Anfrage gesendet werden soll. Wenn Benutzer jetzt auf den zuvor eingegebenen Begriff klicken, öffnet sich der Standard-E-Mail-Client und eine E-Mail wird an die hier eingegebene Adresse vorbereitet. Stelle sicher, dass keine Tippfehler in der E-Mail-Adresse sind. Du kannst die Kontakt-E-Mail-Funktion auch testen, um zu sehen, ob alles funktioniert.",
  social_media_links: "Social Media Links",
  facebook_link: "Facebook-Link",
  copy: "Kopieren",
  instagram_link: "Instagram-Link",
  twitter_link: "Twitter-Link",
  youtube_link: "YouTube-Link",
  vimeo_link: "Vimeo-Link",
  pinterest_link: "Pinterest-Link",
  xing_link: "Xing-Link",
  linkedin_link: "LinkedIn-Link",
  telegram_link: "Telegram-Link",
  podcast_link: "Podcast-Link",
  spotify_link: "Spotify-Link",
  clubhouse_link: "Clubhouse-Link",
  tiktok_link: "TikTok-Link",
  products: "Produkte",
  archived_products: "Archivierte Produkte",
  expert_short_description: "Kurze Beschreibung des Experten",
  expert_long_description: "Lange Beschreibung des Experten",
  merge_accounts: "Konten zusammenführen",
  cancel: "Abbrechen",
  update: "Aktualisieren",
  recommend_a_tag: "Tag empfehlen",
  add_new_expert: "Neuen Experten hinzufügen",
  edit_expert: "Experten bearbeiten",
  photo: "Foto",

  all_user_roles: "Alle Benutzerrollen",
  superAdmin: "SuperAdmin",
  manager: "Manager",
  viewer: "Betrachter",
  all_user_statuses: "Alle Benutzerstatus",
  active: "Aktiv",
  inactive: "Inaktiv",
  allowed_domains: "Erlaubte Domains",
  role: "Rolle",
  status: "Status",
  add_user: "Benutzer hinzufügen",
  save_settings: "Einstellungen speichern",
  update_user: "Benutzer aktualisieren",
  account_name: "Kontoname",
  host: "Host",
  domain: "Domain",
  last_synced: "Letzte Synchronisation",
  edit_account: "Konto bearbeiten",
  public_domain: "Öffentliche Domain",
  database_settings: "Datenbank-Einstellungen",
  user: "Benutzer",
  password: "Passwort",
  database_name: "Datenbankname",
  no_data_found: "Keine Daten gefunden!",

  id: "ID",
  full_name: "Vollständiger Name",

  sr: "Nr.",
  author: "Autor",
  comment: "Kommentar",
  back: "Zurück",

  recommended_by: "Empfohlen von",
  recommended_for: "Empfohlen für",
  approve: "Genehmigen",

  sub_tags: "Untertags",
  manage_child_tags: "Verwalte Untertags",
  add_tag: "Tag hinzufügen",

  digistore_link: "Digistore-Link",
  change_password: "Passwort ändern",
  add_account: "Konto hinzufügen",
  refresh_account_successfully_scheduled: "Kontoaktualisierung erfolgreich geplant",
  synced_in_progress: "Synchronisierung läuft",
  name_is_required: "Name ist erforderlich",
  public_domain_is_required: "Öffentliche Domain ist erforderlich",
  host_is_required: "Host ist erforderlich",
  user_is_required: "Benutzer ist erforderlich",
  password_is_required: "Passwort ist erforderlich",
  database_name_is_required: "Datenbankname ist erforderlich",
  phone: "Telefon",
  view: "Ansicht",
  reject: "Ablehnen",
  are_you_sure_delete_product: "Bist du sicher, dass du dieses Produkt löschen möchtest?",
  additional_information: "Zusätzliche Informationen",
  description_details: "Beschreibung Details",
  short_description: "Kurze Beschreibung:",
  long_description: "Lange Beschreibung:",
  direct_links: "Direktlinks",
  no_data: "Keine Daten",
  view_product: "Produkt ansehen",
  no_products_available: "Keine Produkte verfügbar",
  social_links: "Social Media Links",
  no_social_links_available: "Keine Social Links verfügbar",
  tag: "Tag",
  tags: "Tags",
  tag_is_required: "Tag ist erforderlich",

  confirm_to_freeze_unfreeze: "Bestätigen, um einzufrieren/aufzutauen",
  confirm_to_restore: "Bestätigen, um wiederherzustellen",
  more: "Mehr",
  neuen_tag_vorschlagen: "Neuen Tag vorschlagen",
  download_me: "Downloaden",
  product_listing: "Produktauflistung",
  details: "Details",

  product_listing: "Produktauflistung",
  details: "Details",
  products: "Produkte",
  archived_assessment: "Archivierte Bewertung",
  recommended_tags: "Empfohlene Tags",
  unapproved_comments: "Ungeprüfte Kommentare",
  application_list: "Bewerbungsliste",
  account: "Konto",
  welcome: "Willkommen",
  expert: "Experte",
  shop: "Shop",
  assessment: "Bewertung",
  application_settings: "Anwendungseinstellungen",
  logout: "Abmelden",

  experts_welcome_message1: "Wir freuen uns, dass du hier bist, im Experten-Backend unserer Konferenzsoftware von",
  experts_welcome_message2: "Bitte schaue dir das kurze Video an. Hier kannst du die Einstellungen für dein Profil, dein Interview und die Produkte, die du anbieten möchtest, vornehmen. Wenn du Fragen hast, kontaktiere gregor@teiledeinebotschaft.de",
  experts_welcome_message3: "Mit besten Grüßen, Gregor Dorsch (Geschäftsführer und Gründer von TeileDeineBotschaft.de)",
  experts_welcome_guide: "Experten: Eine kurze Begrüßung und ein kurzer Leitfaden",
  experts_application_guide: "Bitte bewirb dich hier, gib alle erforderlichen Informationen ein, und wir melden uns schnell bei dir. Vielen Dank.",
  online_congress: "Online Kongress",
  title_expert_eg: "Titel (z.B. Prof., Dr., etc.)",
  expert_short_description_40: "Kurze Expertenbeschreibung (<40 Zeichen)",
  email_address_of_expert: "E-Mail-Adresse des Experten",
  mobile_number: "Handynummer",
  profile_description: "Profilbeschreibung (beruflicher Hintergrund)",
  no_products_found: "Keine Produkte gefunden",
  type_product_title: "Produkttitel eingeben...",
  add_product: "Produkt hinzufügen",
  product_title: "Produkttitel",
  price: "Preis",
  description: "Beschreibung",
  product_link: "Produkt-Link",
  product_image: "Produktbild:",
  crop_preview: "Zuschau-Vorschau",
  upload_a_photo: "Foto hochladen",
  crop_your_image: "Bild zuschneiden",
  ok: "OK",
  image_upload_tooltip: "Bitte lade das Bild hoch und wähle dann einen quadratischen Zuschnitt.",
  image_upload_tooltip_expert: "Lade hier das Hauptbild des Produkts hoch. Mit dem Upload kannst du einen quadratischen Zuschnitt wählen. Der Zuschnitt wird automatisch optimiert und als Quadrat mit 300x300 Pixeln gespeichert. Probier es aus! Es ist ganz einfach—achte nur darauf, dass wir am Ende nur quadratische Bilder akzeptieren.",
  no_image: "Kein Bild",
  product_image_tooltip: "Lade hier das Hauptbild des Produkts hoch. Mit dem Upload kannst du einen quadratischen Zuschnitt wählen. Der Zuschnitt wird automatisch optimiert und als Quadrat mit 300x300 Pixeln gespeichert. Probier es aus! Es ist ganz einfach—achte nur darauf, dass wir am Ende nur quadratische Bilder akzeptieren.",
  product_image: "Produktbild",
  product_name_title_description_tooltip: "Produktname, Produkttitel oder Produktbeschreibung, die als Titel oder Überschrift für das Produkt verwendet wird. Wenn ein Produkttitel eingegeben wird, sind die folgenden Details (Link zum Produkt) und das Produktbild obligatorische Felder! Daher erstelle null, ein, zwei oder drei Produkte, die über den Kongress-Shop angeboten werden sollen.",
  product_link_tooltip: "Bitte gib den Link zur Verkaufsseite oder Buchungsseite des Produkts, der Dienstleistung usw. an. Wenn möglich, erstelle bitte einen Affiliate-Link für den Kongress-Organisator. Vielen Dank.",

  product_title_required: "Produkttitel ist erforderlich",
  product_link_required: "Produktlink ist erforderlich",
  product_updated_successfully: "Produkt erfolgreich aktualisiert",
  new_product_added_successfully: "Neues Produkt erfolgreich hinzugefügt",
  product_deleted_successfully: "Produkt erfolgreich gelöscht!",
  edit_product: "Produkt bearbeiten",
  first_name_required: "Vorname ist erforderlich",
  digistore_id_required: "Digistore-ID ist erforderlich",
  profile_image_required: "Profilbild ist erforderlich",
  email_address_required: "E-Mail-Adresse ist erforderlich",
  valid_email_required: "Bitte eine gültige E-Mail-Adresse eingeben",
  updated_user_successfully: "Benutzer erfolgreich aktualisiert!",
  expert_short_description_tooltip: "Die kurze Beschreibung des Experten sollte eine sehr kurze und stichwortartige Mini-Bezeichnung der Expertise des Sprechers sein. Diese Bezeichnung wird unter dem Namen des Experten angezeigt. Sie ist auf maximal 40 Zeichen begrenzt und sollte so kurz wie möglich sein, damit es keine Zeilenumbrüche in der Ansicht gibt. Formulierungen wie: Star-Biologe, Oscar-Gewinner, Online-Guru, Bestseller-Autor, Meditations-Meister, Praxis-Experte, Umsetzung-Meister usw. sind gut. Ist das Ziel dieses Feldes klar beschrieben?",
  digistore_id_tooltip: "Du findest deine Digistore-ID in deinem Digistore-Konto nach dem Login oben links. Die Digistore-ID ist erforderlich für die automatische Zuweisung von Affiliate-Provisionen und die entsprechende Nachverfolgung. Digistore ist eine der gängigsten Methoden für Online-Angebote und Produkte und bietet ein sehr gutes und zuverlässiges Affiliate-System. Wenn du noch kein Digistore-Konto hast, kannst du hier kostenlos ein neues erstellen: https://www.digistore24.com/de/signup.",
  email_address_of_expert_tooltip: "Bitte gib hier die E-Mail-Adresse des Experten ein. Diese E-Mail wird auch in das Benutzerverwaltungssystem aufgenommen. Mit dieser E-Mail-Adresse (und dem entsprechenden Passwort) können Experten sich im Frontend anmelden, um den Kongress zu verfolgen und auch auf das Backend des Experten zugreifen, um ihr Profil, Produkte und Interview-Beschreibungen zu bearbeiten.",
  profile_description_tooltip: "Karriere-Hintergrund, Lebenslauf oder Beschreibung des Experten. Diese Beschreibung wird im Expertenprofil auf der Seite angezeigt, sowohl im ausgeloggten Zustand für alle sichtbar als auch im eingeloggten Mitgliederbereich.",
  direct_links_tooltip: "Diese Links erscheinen direkt im Expertenprofil. Sie sind sowohl im ausgeloggten als auch im eingeloggten Zustand sichtbar.",
  social_links_tooltip: "Die Links zu Social Media-Kanälen erscheinen als kleine Icons direkt im Expertenprofil. Sie sind für alle sichtbar, sowohl im ausgeloggten Zustand als auch im eingeloggten Bereich (Mitgliederbereich).",
  application_terms_conditions: "Bitte bestätige die AGB.",
  application_terms_conditions_tooltip: "Bitte bestätige, dass die Teilnahmebedingungen für die Teilnahme als Experte verstanden wurden, um sicherzustellen, dass ein faires Prinzip der Teilnahme und des Beitrags gewahrt bleibt.",
  submit_application: "Bewerbung einreichen",
  products_services_courses: "Produkte • Dienstleistungen • Kurse",
  products_services_courses_tooltip: "Du kannst null, ein, zwei oder drei Produkte auflisten, die im Shop angezeigt werden. Diese Produkte können dann direkt unter dem Interview-Video verlinkt werden, was eine großartige Verkaufsgelegenheit aus dem Interview schafft.",
  application_email_list: "Wie groß ist deine E-Mail-Liste?",
  application_email_list_tooltip: "Freitext-Beschreibung einschließlich Formatierungen. Hier kannst du z.B. den Lebenslauf oder eine Beschreibung des Experten einfügen.",
  first_name: "Vorname",
  last_name: "Nachname",
  error_last_name_required: "Nachname ist erforderlich",
  error_confirm_terms_conditions: "Bitte bestätige die Teilnahmebedingungen. Vielen Dank.",
  product: "Produkt",
  error_product_title: "Titel ist erforderlich, wenn ein Bild bereitgestellt wird.",
  error_product_link: "Link ist erforderlich, wenn ein Bild bereitgestellt wird.",
  error_product_image: "Bild ist erforderlich.",
  form_submit_successfully: "Formular erfolgreich eingereicht!",
  become_a_speaker: "Werden Sie ein Sprecher",
login: "Anmelden",
forgot_password: "Passwort vergessen",
small_list: "Kleine Liste",
over: "Über",
save_changes: "Änderungen speichern",

success_speaker_delete: "Sprecher-Benutzer erfolgreich gelöscht!",
success_approved_user: "Benutzer erfolgreich genehmigt!",
success_rejected_user: "Benutzer erfolgreich abgelehnt!",
success_expert_copied: "Experte erfolgreich kopiert!",
success_tags_requested: "Tags erfolgreich angefordert!",
success_settings_updated: "Einstellungen erfolgreich aktualisiert!"
,
thank_you: "Vielen Dank",
  thank_you_recieve: "Vielen Dank für das Einreichen Ihrer Bewerbung! Wir haben sie erfolgreich erhalten.",
  your_kongress_team: "Ihr Kongress-Team",
  thank_you_review: "Bitte geben Sie uns etwas Zeit, um Ihre Angaben zu prüfen, und wir werden uns in Kürze bei Ihnen melden. Sie erhalten auch eine Bestätigungs-E-Mail, die eine Telefonnummer für dringende Angelegenheiten enthält. Wir freuen uns darauf, bald mit Ihnen zu sprechen.",

  are_you_sure_delete: "Bist du sicher, dass du diese Bewerbung löschen möchtest?",
  do_you_want_to_approve: "Möchtest du die Bewerbung genehmigen?",
  do_you_want_to_reject: "Möchtest du die Bewerbung ablehnen?",
  error_title_required: "Titel ist erforderlich!",
  do_you_want_to_approve_tags: "Möchtest du die Tags genehmigen?",
  approve_tags: "Tags genehmigen",
  required: "Erforderlich!",
  error_something_went_wrong: "Etwas ist schiefgelaufen. Bitte versuche es erneut.",
  confirm_to_recommend_tags: "Bestätigung, die Tags zu empfehlen",
  are_you_sure_recommended_tags: "Bist du sicher, dass du die Tags empfehlen möchtest?",
  

  application_email_template: "Bewerbungs-E-Mail",
  email_template: "E-Mail-Vorlage",
  email_subject: "Betreff",
  email_content: "E-Mail-Inhalt",
  email_regards: "Mit freundlichen Grüßen",
  preview: "Vorschau",
  email_save_template: "Vorlage speichern",
  add_template: "Vorlage hinzufügen",
  email_template_list: "E-Mail-Vorlagenliste",
  add_new_email_template: "Neue E-Mail-Vorlage hinzufügen",
  update_email_template: "E-Mail-Vorlage aktualisieren",
  code: "Code",
  replacements: "Ersatzwerte",
  message: "Nachricht",
  no_email_templates_available: "Keine E-Mail-Vorlagen verfügbar",
  error_code_required: "Code wird benötigt!",
  error_subject_required: "Betreff wird benötigt!",
  error_replacements_required: "Ersatzwerte werden benötigt!",
  update: "Aktualisieren",
  no_message: "Keine Nachricht",
  session_expired: "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.",
  make_expert: "Experte machen",
  password_optional:"Passwort (freiwillig)"

};

export default deTranslation;
