import React from "react";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  KONGRESS_ATUH_KEY,
  COPY_KONGRESS_END_POINT,
} from "../../../Components/Common/constants";
import StarRatings from "react-star-ratings";
import {
  speakerDetail,
  deleteProduct,
  fetchDeletedProducts,
  restoreArchievedProduct,
} from "../../../Components/action/common";
import { TagsInput } from "react-tag-input-component";
import Select from "react-select";
import _ from "lodash";
import axios from "axios";
import "./style.css";
import { Formik, Field, Form } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const CopySchema = Yup.object().shape({
  // domainName: Yup.string()
  //   .matches(
  //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  //     "Enter correct url!"
  //   )
  //   .required("Required!"),
  domainName: Yup.string().required("Required!"),
  refUserName: Yup.string().required("Required!"),
  refUserDigistoreId: Yup.string().required("Required!"),

  // lastName: Yup.string()
  //     .min(2, 'Too Short!')
  //     .max(50, 'Too Long!')
  //     .required('Required'),
  // email: Yup.string().email('Invalid email').required('Required'),
});

function CopyToCongressModalNew({
  show,
  onHide,
  assessment,
  fetchAssessmentDetail,
  params,
  loggedInData,
}) {
  const {t}=useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [commentValue, setCommentValue] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [domainName, setDomainName] = useState("");
  const [formInitialValues, setFormInitialValues] = useState({});
  const [deletedProductsArray, setDeletedProductsArray] = useState([]);
  const [fetchingData, setFetchingData] = useState({});
  const [apiUrl, setApiUrl] = useState("");
  const [domainError, setDomainError] = useState("");
  const [allowDomainsValue, setAllowDomainsValue] = useState([]);

  const isValidUrl = (str) => {
    if (
      /^(http(s?):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
        str
      )
    ) {
      console.log("YES");
      return true;
    } else {
      console.log("NO");
      return false;
    }
  };
  useEffect(() => {
    console.log(assessment?.id, "aaaaaa");
    fetchSpeakerDetail(assessment?.id);
    fetchArchievedProducts();

    const uniqueDomains = [
      ...new Set(loggedInData?.allowedDomains?.split("\n")),
    ];

    const filteredDomains = uniqueDomains.filter(
      (domain) => domain.trim() !== ""
    );
    setAllowDomainsValue(filteredDomains);
  }, []);

  const fetchSpeakerDetail = async (params) => {
    try {
      let response = await speakerDetail(params);
      console.log(
        response?.data?.responseData,
        "response?.data?.responseData?.data"
      );
      let data = response?.data?.responseData;
      data = Object.assign(data, { domainName: "" });
      setFormInitialValues(data);
      console.log("dddddd", data);
      setTimeout(() => {
        setFetchingData(false);
      }, 500);
    } catch ({ response }) {
      setFetchingData(false);
    }
  };

  const onSubmitFeedBack = async (payload) => {
    console.log(console.log("payload", payload));
    let products = [];
    if (payload?.products?.length) {
      payload?.products?.forEach((obj) => {
        products.push(formInitialValues?.RefUserProducts[parseInt(obj)]);
      });
    }
    console.log("payload_products", products);
    payload = Object.assign(payload, {
      email: formInitialValues?.refUserEmail,
      user_name: payload?.refUserName,
      auth_key: KONGRESS_ATUH_KEY,
      affiliate: payload?.refUserDigistoreId,
      products: products,
      auth_key: KONGRESS_ATUH_KEY,
    });

    let dName = payload?.domainName.replace(/\/+$/, "");

    let url = dName + COPY_KONGRESS_END_POINT;
    let requestBody = payload;

    // setIsLoader(true)

    // fetch(url, {
    //   method: "POST",
    //   body: JSON.stringify(requestBody),
    //   headers: {
    //     "Content-type": "application/json; charset=UTF-8",
    //   },
    // })
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error(`HTTP error! status: ${response.status}`);
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
    //     toast.success("Expert copied successfully");
    //     setIsLoader(false)
    //     onHide();
    //   })
    //   .catch((error) => {
    //     setIsLoader(false)
    //   });

    try {
      setIsLoader(true);

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      if (!response.ok) {
        // Extract the error response body for additional details
        const errorData = await response.json();
        throw new Error(errorData?.message);
      }

      const data = await response.json();

      toast.success(t('success_expert_copied'));
      onHide();
    } catch (error) {
      console.error("Error in submission", error);

      const errorMessage =
        error?.message || "An unexpected error occurred. Please try again.";

      toast.error(errorMessage);

      // else if(error?.message=="Failed to fetch") {
      //   toast.error("Domain Doesn't Exist!");
      // }
    } finally {
      setIsLoader(false);
    }
  };

  const updateTags = (obj) => {
    setSelectedTags(obj);
  };

  const deleteUserProduct = async (obj, index) => {
    console.log(obj, "ooooooo");
    try {
      let response = await deleteProduct(obj?.id);
      fetchSpeakerDetail(assessment?.id);
      fetchArchievedProducts();
    } catch ({ response }) {
      setFetchingData(false);
    }
  };
  const restoreProduct = async (obj, index) => {
    console.log(obj, "ooooooo");
    try {
      let response = await restoreArchievedProduct(obj?.id);
      fetchSpeakerDetail(assessment?.id);
      fetchArchievedProducts();
    } catch ({ response }) {
      setFetchingData(false);
    }
  };

  const fetchArchievedProducts = async () => {
    try {
      let response = await fetchDeletedProducts({ refUserId: assessment?.id });
      setDeletedProductsArray(response?.data?.responseData);
    } catch ({ response }) {
      //setFetchingData(false);
    }
  };

  console.log("selectedTags", deletedProductsArray);

  return (
    <Modal
      className="modal copy_modal"
      show={show}
      onHide={() => {
        onHide();
      }}
    >
      <div className="modal-lg">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <span aria-hidden="true">
              <font style={{ verticalAlign: "inherit" }}>
                <font style={{ verticalAlign: "inherit" }}>×</font>
              </font>
            </span>
          </button>
          <h4 className="modal-title" id="assignModalLabel">
            <font style={{ verticalAlign: "inherit" }}>
              <font style={{ verticalAlign: "inherit" }}>{t('copy_to_kongress')}</font>
            </font>
          </h4>
        </div>
        <div className="modal-body formLog">
          <div className="comman_response" />
          <Formik
            validationSchema={CopySchema}
            initialValues={formInitialValues}
            enableReinitialize={true}
            onSubmit={onSubmitFeedBack}
          >
            {({ errors, touched, values, setFieldValue }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="col-lg-12 form-group">
                      <div className="form-group" style={{ minWidth: "300px" }}>
                        <label className="control-label" htmlFor="domainName">
                          {t('transport_the_expert_to_the_following_kongress_domain')}                        </label>
                        <Select
                          placeholder={`${t('select')}...`}
                          id="domainName"
                          name="domainName"
                          options={allowDomainsValue.map((domain) => ({
                            label: domain.trim(),
                            value: domain.trim(),
                          }))}
                          isClearable={true}
                          styles={{
                            control: (base) => ({ ...base, minHeight: 40 }),
                          }}
                          value={
                            values.domainName
                              ? {
                                  label: values.domainName,
                                  value: values.domainName,
                                }
                              : null
                          }
                          onChange={(selectedOption) =>
                            setFieldValue(
                              "domainName",
                              selectedOption ? selectedOption.value : ""
                            )
                          }
                          isMulti={false}
                        />

                        {errors.domainName ? (
                          <div className="error">{errors.domainName}</div>
                        ) : null}
                      </div>

                      {/* <label className="control-label" htmlFor="domainName">
                        Domain (Kongress url)
                      </label>
                      <Field
                        id="domainName"
                        name="domainName"
                        placeholder=""
                        className="form-control"
                      /> */}
                      {/* {errors.allowDomainName ? (
                        <div className="error">{errors.allowDomainName}</div>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <label
                        className="control-label"
                        htmlFor="refUserDigistoreId"
                      >
                        Digistore ID
                      </label>
                      <Field
                        id="refUserDigistoreId"
                        name="refUserDigistoreId"
                        placeholder=""
                        className="form-control"
                        disabled={true}
                      />
                    </div>
                    <div className="col-lg-6 form-group">
                      <label className="control-label" htmlFor="lastName">
                        {t('email')}
                      </label>
                      <Field
                        id="refUserEmail"
                        name="refUserEmail"
                        className="form-control"
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <label
                        className="control-label"
                        htmlFor="refUserDigistoreId"
                      >
                        {t('title')}
                      </label>
                      <Field
                        id="title"
                        name="title"
                        className="form-control"
                        disabled={true}
                      />
                    </div>
                    <div className="col-lg-6 form-group">
                      <label className="control-label" htmlFor="refUserName">
                        {t('name')}
                      </label>
                      <Field
                        id="refUserName"
                        name="refUserName"
                        className="form-control"
                        disabled={true}
                      />
                    </div>
                  </div>

                  <fieldset>
                    <legend>{t('links')}</legend>

                    <div className="row">
                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="home_page_term"
                        >
                          {t('home_page_term')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="home_page_term"
                          name="home_page_term"
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="home_page_link"
                        >
                          {t('home_page_link')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="home_page_link"
                          name="home_page_link"
                        />
                      </div>

                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="home_page_term"
                        >
                          {t('news_letter_term')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="newsletter_term"
                          name="newsletter_term"
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="newsletter_link"
                        >
                          {t('news_letter_link')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="newsletter_link"
                          name="newsletter_link"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 form-group">
                        <label className="control-label" htmlFor="freebie_term">
                          {t('freebie_term')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="freebie_term"
                          name="freebie_term"
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <label
                          disabled={true}
                          className="control-label"
                          htmlFor="freebie_link"
                        >
                          {t('freebie_link')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="freebie_link"
                          name="freebie_link"
                        />
                      </div>

                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="contact_email_term"
                        >
                         {t('contact_email_term')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="contact_email_term"
                          name="contact_email_term"
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="contact_email_address"
                        >
                          {t('contact_email')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="contact_email_address"
                          name="contact_email_address"
                        />
                      </div>
                    </div>
                  </fieldset>

                  <fieldset>
                    <legend>{t('social_media_links')}</legend>
                    <div className="row">
                      <div className="col-lg-3 form-group">
                        <label
                          className="control-label"
                          htmlFor="facebook_link"
                        >
                          {t('facebook_link')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="facebook_link"
                          name="facebook_link"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <label
                          className="control-label"
                          htmlFor="instagram_link"
                        >
                          {t('instagram_link')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="instagram_link"
                          name="instagram_link"
                        />
                      </div>

                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="twitter_link">
                          {t('twitter_link')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="twitter_link"
                          name="twitter_link"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="youtube_link">
                          {t('youtube_link')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="youtube_link"
                          name="youtube_link"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="vimeo_link">
                          {t('vimeo_link')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="vimeo_link"
                          name="vimeo_link"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <label
                          className="control-label"
                          htmlFor="pinterest_link"
                        >
                          {t('pinterest_link')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="pinterest_link"
                          name="pinterest_link"
                        />
                      </div>

                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="xing_link">
                          {t('xing_link')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="xing_link"
                          name="xing_link"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <label
                          className="control-label"
                          htmlFor="linkedin_link"
                        >
                          {t('linkedin_link')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="linkedin_link"
                          name="linkedin_link"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 form-group">
                        <label
                          className="control-label"
                          htmlFor="telegram_link"
                        >
                         {t('telegram_link')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="telegram_link"
                          name="telegram_link"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="podcast_link">
                          {t('podcast_link')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="podcast_link"
                          name="podcast_link"
                        />
                      </div>

                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="spotify_link">
                          {t('spotify_link')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="spotify_link"
                          name="spotify_link"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <label
                          className="control-label"
                          htmlFor="clubhouse_link"
                        >
                          {t('clubhouse_link')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="clubhouse_link"
                          name="clubhouse_link"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 form-group">
                        <label className="control-label" htmlFor="tiktok_link">
                          {t('tiktok_link')}
                        </label>
                        <Field
                          disabled={true}
                          className="form-control"
                          id="tiktok_link"
                          name="tiktok_link"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <strong>{t('products')}</strong>
                        <div role="group" aria-labelledby="checkbox-group">
                          <ul>
                            {formInitialValues?.RefUserProducts?.length > 0 ? (
                              formInitialValues?.RefUserProducts?.map(
                                (obj, index) => {
                                  return (
                                    <li key={index}>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="products"
                                          value={index.toString()}
                                        />
                                        &nbsp;
                                        <span
                                          className="product_title"
                                          title={obj.product_title}
                                        >
                                          {obj.product_title}
                                        </span>
                                      </label>
                                      {(loggedInData?.role == "SUPER_ADMIN" ||
                                        loggedInData?.role == "MANAGER") && (
                                        <span>
                                          &nbsp;&nbsp;
                                          <i
                                            title={t('delete')}
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                            onClick={() =>
                                              deleteUserProduct(obj, index)
                                            }
                                          ></i>
                                        </span>
                                      )}
                                    </li>
                                  );
                                }
                              )
                            ) : (
                              <></>
                            )}
                          </ul>
                        </div>
                      </div>
                      {(loggedInData?.role == "SUPER_ADMIN" ||
                        loggedInData?.role == "MANAGER") && (
                        <div className="col-lg-6">
                          <strong>{t('archived_products')}</strong>
                          <div role="group" aria-labelledby="checkbox-group">
                            <ul>
                              {deletedProductsArray?.length > 0 ? (
                                deletedProductsArray?.map((obj, index) => {
                                  return (
                                    <li key={index}>
                                      <label>
                                        <span
                                          className="product_title"
                                          title={obj.product_title}
                                        >
                                          {obj.product_title}
                                        </span>
                                      </label>
                                      <span>
                                        &nbsp;&nbsp;
                                        <i
                                          title="Restore"
                                          className="fa fa-undo"
                                          aria-hidden="true"
                                          onClick={() =>
                                            restoreProduct(obj, index)
                                          }
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </fieldset>
                  {fetchingData == false && (
                    <div className="row">
                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="expert_short_description"
                        >
                          {t('expert_short_description')}
                        </label>
                        <Field
                          disabled={true}
                          component="textarea"
                          className="form-control"
                          id="expert_short_description"
                          name="expert_short_description"
                        />
                      </div>

                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="expert_long_description"
                        >
                          {t('expert_long_description')}
                        </label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={values?.expert_long_description}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            //console.log('Editor is ready to use!', editor);
                          }}
                          onChange={(event, editor) => {
                            setFieldValue(
                              "expert_long_description",
                              editor.getData()
                            );
                            //console.log(editor.getData());
                          }}
                          onBlur={(event, editor) => {
                            //console.log('Blur.', editor);
                          }}
                          onFocus={(event, editor) => {
                            //console.log('Focus.', editor);
                          }}
                          disabled={true}
                        />
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-lg-12 form-group ">
                      <button
                        type="submit"
                        className="pull-right btn btn-primary loadingbtns"
                        disabled={isLoader ? true : false}
                      >
                        {isLoader ? `${t('loading')}...` : t('copy')}
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          {/* <form className="changePasswordForm" onSubmit={handleSubmit(onSubmitFeedBack)}>
                        <div className="voucher_outer">
                            <div className="row">

                                <div className="form-group">
                                    <label className="control-label">Domain (Kongress url)</label>
                                    <div>
                                        <input type="text" className="form-control input-lg" onChange={(e) => setDomainName(e.target.value)} />
                                        {domainError && <div className="error error_user_name" >{domainError}</div>}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label">Name</label>
                                    <div>
                                        <input type="text" className="form-control input-lg" readOnly defaultValue={assessment?.refUserName} />
                                        <div className="error error_user_name" >{errors?.name?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Email</label>
                                    <div>
                                        <input type="text" className="form-control input-lg" readOnly defaultValue={assessment?.refUserEmail} />
                                        <div className="error error_user_name" >{errors?.name?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Digistore24 ID</label>
                                    <div>
                                        <input type="text" className="form-control input-lg" readOnly defaultValue={assessment?.refUserDigistoreId} />
                                        <div className="error error_user_name" >{errors?.name?.message}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div >
                            <button type="submit" className="btn btn-primary modelChangePasswordSave loadingbtns">{isLoader ? 'Loading...' : 'Copy'}</button>
                        </div>
                        <div >
                            <a href={apiUrl} target="_blank" id="api_url">{setApiUrl}</a>
                        </div>

                    </form> */}
        </div>
      </div>
    </Modal>
  );
}

export default CopyToCongressModalNew;
