import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import { Routes } from "react-router-dom";
import AccountList from "./Account/AccountList";
import AccountsDetail from "./Assessment/AccountsDetail";
import Assessment from "./Assessment/Assessment";
import Dashboard from "./Dashboard/Dashboard";
import Tags from "./Tags/Tags";
import Login from "./Login/Login";
import RecommendedTags from "./Tags/RecommendedTags";
import UnapprovedComments from "./Assessment/UnapprovedComments";
import ProductsListing from "./Products/ProductsListing";
import ArchiveProductsListing from "./Products/ArchiveProductsListing";

import PageNotFound from "../Components/Common/PageNotFound";
import AssessmentNew from "./Assessment/AssessmentNew";
import BecomeSpeaker from "./BecomeSpeaker/BecomeSpeaker";
import BecomeSpeakerList from "./BecomeSpeakerList/BecomeSpeakerList";
import Welcome from "./ExpertsSection/Welcome";
import Experts from "./ExpertsSection/Experts";
import Shop from "./ExpertsSection/Shop";
import ExpertGeneralSettings from "./ExpertGeneralSettings/ExpertGeneralSettings";
import { useSelector } from "react-redux";
import EmailTemplateForm from "./EmailTemplate/EmailTemplateForm";
import EmailTemplateListing from "./EmailTemplate/EmailTemplateListing";

function Layout() {
  const navigate = useNavigate();
  const { loggedInData } = useSelector((state) => state.admin);

  return (
    <Routes>
      <Route path="/" element={<Login />} />

      {loggedInData?.role === "EXPERT_USER" && (
        <>
          <Route path="/welcome-new" element={<Welcome />} />
          <Route path="/experts-new" element={<Experts />} />
          <Route path="/shop-new" element={<Shop />} />
        </>
      )}
      {loggedInData?.role == "SUPER_ADMIN" && (
        <>
          <Route
            path="/experts-general-settings"
            element={<ExpertGeneralSettings />}
          />
          <Route path="/admin" element={<Dashboard />} />
          <Route path="/become-speaker-users" element={<BecomeSpeakerList />} />
          <Route path="/email-template" element={<EmailTemplateForm/>}/>
          {/* <Route path="/email-template" element={<EmailTemplateListing/>}/> */}


        </>
      )}

      {(loggedInData?.role == "SUPER_ADMIN" ||
        loggedInData?.role == "MANAGER") && (
        <>
          <Route path="/UnapprovedComments" element={<UnapprovedComments />} />
       
          <Route
            path="/assessment-archieved"
            element={<Assessment pageType="archieved" />}
          />

          <Route path="/products" element={<ProductsListing />} />
          <Route
            path="/archive-products"
            element={<ArchiveProductsListing />}
          />
        </>
      )}
      <Route path="/become-speaker" element={<BecomeSpeaker />} />

      <Route path="/account" element={<AccountList />} />
      <Route path="/assessment-old" element={<Assessment />} />
      <Route path="/assessment" element={<AssessmentNew />} />

      <Route path="/tags" element={<Tags />} />
      <Route path="/recommendedTags" element={<RecommendedTags />} />
      <Route path="accounts" element={<AccountsDetail />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Layout;
