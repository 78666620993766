import { t } from "i18next";
import Modal from "react-modal";

const DeleteUserModal = ({
  deletetableData,
  handleDelete,
  handleDeleteCancel,
}) => {
  return (
    <Modal
      isOpen={true}
      onRequestClose={handleDeleteCancel}
      className="modalFormDelete"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
    >
      <div className="text-center p-4">
        <p className="text-[14px]">
          {t('are_you_sure_delete')} {deletetableData?.name}?</p>
        <div className="mt-4 flex justify-center space-x-4">
         
          <button
            className="btn btn-default"
            id="changePasswordCancel"
            onClick={handleDeleteCancel}
          >
            <font style={{ verticalAlign: "inherit" }}>
              <font style={{ verticalAlign: "inherit" }}>{t('no')}</font>
            </font>
          </button>

          <button
            className="btn btn-theme modelChangePasswordSave loadingbtns"
            onClick={() => handleDelete(deletetableData?.id)}
          >
            {t('yes')}
          </button>
          
        </div>
      </div>
    </Modal>
  );
};

export default DeleteUserModal;
