import React, { useState, useEffect } from "react";
import { Table, Column, AutoSizer } from "react-virtualized";
import SideBar from "../Dashboard/SideBar";
import "./TailwindBecomeSpeaker.css";
import {
  deleteBecomeSpeaker,
  getBecomeSpeakerList,
  updateBecomeSpeaker,
} from "../../Components/action/becomeSpeakerAction";

import DeleteUserModal from "./ModalsBecomeSpeaker/DeleteUserModal";
import toast from "react-hot-toast";
import ViewDetailsBecomeSpeaker from "./ModalsBecomeSpeaker/ViewDetailsBecomeSpeaker";
import { t } from "i18next";
import ApproveRejectedConfirmationModal from "./ModalsBecomeSpeaker/ApproveRejectedConfirmationModal";

const BecomeSpeakerList = () => {
  const [speakerBecomeData, setSpeakerBecomeData] = useState([]);
  const [currentDataSpeaker, setCurrentDataSpeaker] = useState([]);
  const [isDeletable, setIsDeletable] = useState(false);
  const [deletetableData, setDeletableData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [viewModal, setViewModal] = useState(false);
  const [viewModalData, setViewModalData] = useState(null);
  const [searchText, setSearchText] = useState("");

  const [approveRejectData, setApproveRejectData] = useState(null);
  const [approveRejectModal, setApproveRejectModal] = useState(false);
  const [typeApproveReject, setTypeApproveReject] = useState(null);

  const fetchBecomeSpeaker = () => {
    setLoading(true);
    getBecomeSpeakerList()
      .then((response) => {
        const data = response?.data?.responseData?.data;
        setSpeakerBecomeData(data);
        if (searchText.trim() !== "") {
          const filteredData = data.filter((becomeSpeaker) => {
            const normalizedSearchValue = searchText.toLowerCase();

            return (
              becomeSpeaker?.name
                ?.toLowerCase()
                .includes(normalizedSearchValue) ||
              becomeSpeaker?.id?.toString().includes(searchText) ||
              becomeSpeaker?.expertUserEmail
                ?.toLowerCase()
                .includes(normalizedSearchValue) ||
              becomeSpeaker?.detailJsonResponse?.phone_number
                ?.toLowerCase()
                .includes(normalizedSearchValue) ||
              becomeSpeaker?.detailJsonResponse?.designation_name
                ?.toLowerCase()
                .includes(normalizedSearchValue)
            );
          });
          setCurrentDataSpeaker(filteredData);
        } else {
          setCurrentDataSpeaker(data);
        }
        setLoading(false);
      })
      .catch((error) => console.log(error, "errrr"));
  };

  useEffect(() => {
    fetchBecomeSpeaker();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteBecomeSpeaker(id);
      setIsDeletable(false);
      toast.success(t("success_speaker_delete"));

      fetchBecomeSpeaker();
    } catch (error) {
      console.error("Error deleting speaker:", error);
      setIsDeletable(true);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeletable(false);
  };

  const openCloseApproveRejectModal = (type, data, checked) => {
    setApproveRejectData(data);
    setTypeApproveReject(type);
    data?setApproveRejectModal(true):setApproveRejectModal(false)
    if (checked) {
      fetchBecomeSpeaker();
    }
  };

  const viewModalHandler = (rowData) => {
    setViewModalData(rowData);
    setViewModal(true);
  };
  const closeModalHandler = () => {
    setViewModal(false);
    setViewModalData(null);
  };

  const handleSearch = (value) => {
    if (value.trim() !== "") {
      setSearchText(value);
      const filteredData = currentDataSpeaker.filter((becomeSpeaker) => {
        const normalizedSearchValue = value.toLowerCase();

        return (
          becomeSpeaker?.name?.toLowerCase().includes(normalizedSearchValue) ||
          becomeSpeaker?.id?.toString().includes(value) ||
          becomeSpeaker?.expertUserEmail
            ?.toLowerCase()
            .includes(normalizedSearchValue) ||
          becomeSpeaker?.detailJsonResponse?.phone_number
            ?.toLowerCase()
            .includes(normalizedSearchValue) ||
          becomeSpeaker?.detailJsonResponse?.designation_name
            ?.toLowerCase()
            .includes(normalizedSearchValue)
        );
      });

      setCurrentDataSpeaker(filteredData);
    } else {
      setCurrentDataSpeaker(speakerBecomeData);
    }
  };

  return (
    <>
      <SideBar />
      <div className="content-page">
        <div className="UserLoader" />
        <div className="content userpage">
          <div className="flex justify-between">
            <h2 className="page-title w-[50%]">{t("become_a_speaker_list")}</h2>
            <input
              style={{ padding: "3px 0px 5px 5px" }}
              type="text"
              className="form-control w-[30%]"
              name="searchUser"
              placeholder={t("search")}
              onChange={(e) => handleSearch(e?.target?.value)}
            />
          </div>

          <div className="bg-white shadow-md rounded-lg overflow-hidden h-[90vh] become-listing">
            {loading ? (
              <div className="loading">{t("loading")}...</div>
            ) : (
              <>
                {/* {currentDataSpeaker && currentDataSpeaker.length > 0 ? ( */}
                <AutoSizer>
                  {({ height, width }) => (
                    <Table
                      width={width}
                      height={height}
                      headerHeight={100}
                      rowHeight={60}
                      rowCount={currentDataSpeaker.length}
                      rowGetter={({ index }) => currentDataSpeaker[index]}
                      overscanRowCount={10}
                    >
                      {/* Designation Column */}
                      <Column
                        label="ID"
                        dataKey="id"
                        width={50}
                        cellRenderer={({ rowData }) => <div>{rowData?.id}</div>}
                      />

                      {/* Full Name Column */}
                      <Column
                        label={t("full_name")}
                        dataKey="first_name"
                        cellRenderer={({ rowData }) => {
                          const designation =
                            rowData?.detailJsonResponse?.designation_name || "";
                          const name = rowData?.name || "";

                          return (
                            <>
                              <img
                                src={
                                  rowData?.detailJsonResponse
                                    ?.user_profile_picture != "" &&
                                  rowData?.detailJsonResponse
                                    ?.user_profile_picture
                                    ? rowData?.detailJsonResponse
                                        ?.user_profile_picture
                                    : "/images/profile_pic.png"
                                }
                                alt="profile"
                                className="h-[40px] w-[40px] rounded-full mr-3"
                              />
                              {/* <span>{`${designation} ${name}`.trim()}</span> */}
                              <span>{`${name}`.trim()}</span>
                            </>
                          );
                        }}
                        width={300}
                      />

                      {/* Email Column */}
                      <Column
                        label={t("email")}
                        dataKey="expert_email"
                        width={300}
                        cellRenderer={({ rowData }) => (
                          <div
                            style={{
                              whiteSpace: "normal",
                              wordBreak: "break-word",
                            }}
                          >
                            {rowData?.expertUserEmail ?? ""}
                          </div>
                        )}
                      />

                      {/* Phone Column */}
                      <Column
                        label={t("phone")}
                        dataKey="phone_number"
                        width={140}
                        cellRenderer={({ rowData }) => (
                          <div>{rowData?.detailJsonResponse?.phone_number}</div>
                        )}
                      />
                      <Column
                        label={t("status")}
                        dataKey="status"
                        width={140}
                        cellRenderer={({ rowData }) => {
                          const status = {
                            0: "Pending",
                            1: "Accepted",
                            2: "Rejected",
                          }[rowData?.status];

                          const statusClass = {
                            0: "bg-gray-500",
                            1: "bg-green-500",
                            2: "bg-red-500",
                          }[rowData?.status];
                          const statusText = status ?? "";

                          return (
                            <span
                              className={` text-white status ${statusClass}`}
                            >
                              {statusText}
                            </span>
                          );
                        }}
                      />


                      {/* <Column
                       
                        dataKey="view"
                        width={50}
                        cellRenderer={({ rowData }) => (
                          <button
                            className="text-white bg-red-900 px-4 py-2 hover:bg-btnColor border-none"
                            onClick={() => viewModalHandler(rowData)}
                          >
                            {t("view")}
                          </button>
                        )}
                      /> */}

                      {/* Actions Column */}
                      <Column
                        label={t("actions")}
                        dataKey="actions"
                        width={320}
                        cellRenderer={({ rowData }) => (
                          <div className="flex space-x-4 items-center">
                             <button
                            className="text-white bg-red-900 px-4 py-2 hover:bg-btnColor border-none"
                            onClick={() => viewModalHandler(rowData)}
                          >
                            {t("view")}
                          </button>

                            <button
                              onClick={() =>
                                openCloseApproveRejectModal(1, rowData,false)
                              }
                              className="bg-red-900 hover:bg-btnColor text-white px-4 py-2 border-none rounded "
                            >
                              {t("approve")}
                            </button>
                            <button
                              onClick={() =>
                                openCloseApproveRejectModal(2, rowData,false)
                              }
                              className="bg-red-900 hover:bg-btnColor text-white px-4 py-2 border-none rounded"
                            >
                              {t("reject")}
                            </button>
                            <img
                              title="Delete user"
                              src="./images/delete.png"
                              className="hover:cursor-pointer h-[18px] w-[18px]"
                              onClick={() => {
                                setDeletableData(rowData);
                                setIsDeletable(true);
                              }}
                            />
                          </div>
                        )}
                      />
                    </Table>
                  )}
                </AutoSizer>
                {/* ) : (
                  <div className="flex justify-center items-center h-full text-gray-500 text-2xl">
                    No Data Found
                  </div>
                )} */}
              </>
            )}
          </div>
        </div>
      </div>
      {isDeletable && (
        <DeleteUserModal
          setDeletableData={setDeletableData}
          deletetableData={deletetableData}
          handleDelete={handleDelete}
          handleDeleteCancel={handleDeleteCancel}
        />
      )}
      {viewModal && (
        <ViewDetailsBecomeSpeaker
          setViewModal={setViewModal}
          viewModalData={viewModalData}
          closeModalHandler={closeModalHandler}
        />
      )}

      {approveRejectModal && (
        <ApproveRejectedConfirmationModal
          approveRejectData={approveRejectData}
          type={typeApproveReject}
          openCloseApproveRejectModal={openCloseApproveRejectModal}
        />
      )}
    </>
  );
};

export default BecomeSpeakerList;
