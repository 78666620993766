import { Suspense } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import Layout from './pages/Layout';
import "./i18n"

function App() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<div className="initial-loader" />}>
          <Layout />
        </Suspense>
      </BrowserRouter>

      <Toaster
        position="top-center"
        reverseOrder={false}
      />


    </>
  );
}

export default App;
