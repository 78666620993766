import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en/translation';
import deTranslation from './locales/de/translation';
import { DEFAULT_LANGUAGE } from './Components/Common/constants';
import frTranslation from './locales/fr/translation';

const defaultLanguage = DEFAULT_LANGUAGE;

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation, 
      },
      de: {
        translation: deTranslation, 
      },
      fr:{
        translation:frTranslation,
      }
    },
    lng: defaultLanguage,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ['en', 'de','fr'],
    debug: true,
  });

export default i18n;
