import React from "react";
import { useState } from "react";
import {
  fetchTags,
  deleteTag,
  deleteTagRequest,
} from "../../Components/action/tags";

import ManageTag from "../Dashboard/Modal/ManageTag";
import SideBar from "../Dashboard/SideBar";
import TopHeader from "../Dashboard/TopHeader";
import { useDispatch, useSelector } from "react-redux";
//import { setTagList } from '../../redux/slices/admin'
import { useEffect } from "react";
import Pagination from "react-js-pagination";
import { authentication } from "../../Components/action/utilities";
import { useNavigate, Navigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { approveTag } from "../../Components/action/tags";
import Select from "react-select";
import axios from "axios";
import { t } from "i18next";
var CancelToken = axios.CancelToken;
var cancel;

function RecommendedTags() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [tagObject, setTagObject] = useState(null);
  const [showTagModal, setShowTagModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [parentTags, setParentTags] = useState([]);
  const [limits, setLimits] = useState(null);
  const [tagList, setTagList] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState(null);

  const [totalRecords, setTotalRecords] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [params, setParams] = useState({
    pageNumber: 1,
    sortBy: "id",
    sortOrder: "asc",
    isRecommended: 1,
    status: 0,
  });
  const [timer, setTimer] = useState(null);

  // console.log(tagList, "tagList")
  // const fetchTagsListing = async (params) => {
  //   try {
  //     let response = await fetchTags(params);

  //     setTagList(response?.data?.responseData);
  //     setLimits(response?.data?.responseData?.limit);
  //     setTotalRecords(response?.data?.responseData?.totalRecords);
  //     setTotalPages(response?.data?.responseData?.totalPages);
  //     setCurrentPage(response?.data?.responseData?.page);
  //   } catch ({ response }) { }
  // };

  const fetchTagsListing = (params) => {
    if (typeof cancel == "function") {
      cancel();
    }
    axios({
      method: "GET",
      params: params,
      // url: "/tags/list",
      url: "refUser/tags",
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    }).then((response) => {
      setTagList(response?.data?.responseData);
      setLimits(response?.data?.responseData?.limit);
      setTotalRecords(response?.data?.responseData?.totalRecords);
      setTotalPages(response?.data?.responseData?.totalPages);
      setCurrentPage(response?.data?.responseData?.page);
    });
  };

  const onDeleteTag = (obj) => {
    confirmAlert({
      title: t("confirm_to_delete"),
      message: t("are_you_sure_to_do_this"),
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            try {
              let response = await deleteTagRequest(obj.id);
              fetchTagsListing(params);
              console.log(response, "response");
            } catch ({ response }) {
              //         response.status===401 && navigate("/")
              // response.status===401 && localStorage.clear()
            }
          },
        },
        {
          label: t("no"),
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const handlePageChange = (pageNumber) => {
    let localParams = JSON.parse(JSON.stringify(params));
    localParams = Object.assign(localParams, { pageNumber: pageNumber });
    setParams(localParams);
    fetchTagsListing(localParams);
  };

  useEffect(() => {
    fetchTagsListing(params);
  }, [activePage]);

  const fetchChildTags = (parentId) => {
    let localParams = JSON.parse(JSON.stringify(params));
    localParams = Object.assign(localParams, { parentId: parentId });
    setParams(localParams);
    fetchTagsListing(localParams);
  };

  const search = (value) => {
    let updatedParams = Object.assign(params, {
      searchText: value,
      pageNumber: 1,
    });
    if (!value) {
      delete updatedParams.searchText;
    }
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      fetchTagsListing(updatedParams);
    }, 500);
    setTimer(newTimer);
    setParams(updatedParams);
  };

  const updateTagStatus = async (id) => {
    confirmAlert({
      title: t("approve_tags"),
      message: t("do_you_want_to_approve_tags"),
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            try {
              let response = await approveTag(id);
              fetchTagsListing(params);
            } catch ({ response }) {}
          },
        },
        {
          label: t("no"),
        },
      ],
    });
  };

  if (!authentication()) return <Navigate to={"/"} />;

  return (
    <>
      {/* <TopHeader /> */}
      <SideBar />

      <div className="content-page">
        {/* Start content */}
        <div className="UserLoader" />
        <div className="content userpage">
          <div className="container">
            <div className="filter_options">
              <h4 className="page-title">
                <font style={{ verticalAlign: "inherit" }}>
                  {t("recommended_tags_management")}
                </font>
              </h4>
              <div className="filter_options_inner">
                <div className="form-group">
                  <input
                    style={{ padding: "3px 0px 5px 5px" }}
                    type="text"
                    className="form-control"
                    name="searchUser"
                    placeholder={t("search")}
                    onChange={(e) => search(e?.target?.value)}
                  />
                </div>
              </div>
            </div>
            <div className="filter_options">
              {params.parentId ? (
                <button
                  onClick={() => {
                    let localParams = JSON.parse(JSON.stringify(params));
                    delete localParams.parentId;
                    setParams(localParams);
                    fetchTagsListing(localParams);
                  }}
                >
                  {t("back")}
                </button>
              ) : (
                <></>
              )}
            </div>

            <div className="userlisting row">
              <div className="table-responsive custom-center-ui tagManagment">
                <table
                  id="loginUser"
                  className="display dataTable accountManagement "
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th className="th-account-name">
                        <font style={{ verticalAlign: "inherit" }}>
                          {t("tags")}
                        </font>
                      </th>
                      <th className="th-account-name">
                        <font style={{ verticalAlign: "inherit" }}>
                          {t("recommended_by")}
                        </font>
                      </th>

                      <th className="th-account-name">
                        <font style={{ verticalAlign: "inherit" }}>
                          {t("recommended_for")}
                        </font>
                      </th>

                      <th className="th-action">
                        <font style={{ verticalAlign: "inherit" }}>
                          {t("actions")}
                        </font>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tagList?.data?.length == 0 && (
                      <tr>
                        <td colSpan={"100%"}>{t("no_data_found")}</td>
                      </tr>
                    )}
                    {tagList?.data?.map((tag, index) => {
                      return (
                        <tr className="userInfo">
                          <td>
                            {(parseInt(currentPage) - 1) * parseInt(limits) +
                              (index + 1)}
                          </td>
                          <td className="user_unique_id">{tag?.Tag?.name}</td>
                          <td>
                            {tag?.recommendedByUser?.name} [
                            {tag?.recommendedByUser?.email}]
                          </td>

                          <td>
                            {tag?.RefUser
                              ? tag?.RefUser?.refUserName +
                                " [" +
                                tag?.RefUser?.refUserEmail +
                                "]"
                              : "-"}
                          </td>

                          <td className="td-action">
                            <span className="action-span">
                              {
                                <button onClick={() => updateTagStatus(tag.id)}>
                                  {t("approve")}
                                </button>
                              }

                              {/* <img
                                src="./images/edit.png"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setTagObject(tag);
                                  setShowTagModal(true);
                                }}
                              /> */}
                              <img
                                src="./images/delete.png"
                                style={{ cursor: "pointer" }}
                                onClick={() => onDeleteTag(tag)}
                              />

                              {/* <i className="fa fa-edit" style={{ cursor: "pointer" }}></i> */}
                              {/* <i className='fa fa-trash' onClick={() => onDeleteTag(tag)}></i> */}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {totalPages > 1 && (
                <div style={{ textAlign: "center" }}>
                  <Pagination
                    activePage={parseInt(params.pageNumber)}
                    itemsCountPerPage={limits}
                    totalItemsCount={totalRecords}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                  />
                </div>
              )}
              <div className="row">
                <div style={{}} className="pagnations">
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showTagModal && (
        <ManageTag
          parentTags={parentTags}
          show={showTagModal}
          onHide={() => {
            setShowTagModal(false);
            setTagObject(null);
          }}
          params={params}
          callback={fetchTagsListing}
          tagObject={tagObject}
        />
      )}
    </>
  );
}

export default RecommendedTags;
