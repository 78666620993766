import React from "react";

import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function TagsPopUp({ show, onHide, tagsNameData }) {
  const {t}=useTranslation();
  return (
    <Modal
      className="tagmodal fade in"
      style={{ display: "block", paddingLeft: "0px" }}
      show={show}
      onHide={() => {
        onHide();
      }}
      backdrop="static"
    >
      <div className="modal-contenth" id="changePasswordPanel">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <span aria-hidden="true">
              <font style={{ verticalAlign: "inherit" }}>
                <font style={{ verticalAlign: "inherit" }}>×</font>
              </font>
            </span>
          </button>
          <h4 className="modal-title" id="assignModalLabel">
            <font style={{ verticalAlign: "inherit" }}>
              <font style={{ verticalAlign: "inherit" }}>{t('tag_names')}</font>
            </font>
          </h4>
        </div>
        <div className="modal-body">
          <div className="tagsDiv">
          {tagsNameData?.map((ele) => {
            return <div className="tag-wrappernew poptag">{ele}</div>;
          })}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default TagsPopUp;
