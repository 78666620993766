import { t } from "i18next";
import Modal from "react-modal";
import { updateBecomeSpeaker } from "../../../Components/action/becomeSpeakerAction";

const ApproveRejectedConfirmationModal = ({
  approveRejectData,
  openCloseApproveRejectModal,
  type,
}) => {
  const handleApprove = async () => {
    try {
      if (approveRejectData?.status != type) {
        let dataSend = {};
        dataSend.id = approveRejectData?.id;
        dataSend.status = type;
        await updateBecomeSpeaker(dataSend);
        // type === "approve"
        //   ? toast.success(t("success_approved_user"))
        //   : toast.success(t("success_rejected_user"));

        openCloseApproveRejectModal(null, null, true);
      }
    } catch (error) {
      console.error("Error approve reject speaker:", error);
    }
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={()=>openCloseApproveRejectModal(null, null, false)}
      className="modalFormDelete"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
    >
      <div className="text-center p-4">
        <p className="text-[14px]">
          {type===1?t("do_you_want_to_approve"):t("do_you_want_to_reject")} {approveRejectData?.name}?
        </p>
        <div className="mt-4 flex justify-center space-x-4">
          <button
            className="btn btn-default"
            id="changePasswordCancel"
            onClick={()=>openCloseApproveRejectModal(null, null, false)}
          >
            <font style={{ verticalAlign: "inherit" }}>
              <font style={{ verticalAlign: "inherit" }}>{t("no")}</font>
            </font>
          </button>

          <button
            className="btn btn-theme modelChangePasswordSave loadingbtns"
            onClick={() => handleApprove()}
          >
            {t("yes")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ApproveRejectedConfirmationModal;
