import { configureStore } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  persistStore, persistReducer,
  FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { BASE_URL } from '../Components/Common/constants';
// import { clearSession } from '../utilities';
import { rootReducer } from './slices';
import tokenTimeSlice from './slices/tokenTimeSlice';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import { refreshTokenAPI } from './refreshToken';
// import logger from "react-logger"


// creating and persisting redux store
const persistConfig = { key: 'root', storage };

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer:persistedReducer,

  // reducer:{ persistedReducer,timeTokenData:tokenTimeSlice},
  


  // Restrict Redux Devtool in Production
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['persist/PERSIST', FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ['items.dates'],
      },
    }),

});

export const persistor = persistStore(store);

// ------------------------ axios interceptor request -------------------------
axios.interceptors.request.use(
  (request) => {
    const accessToken = typeof window !== "undefined" ? window.localStorage.getItem("token") : null;
    if (accessToken) {
      request.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    const UTCOffset = new Date().getTimezoneOffset();
    request.headers['utcoffset'] = UTCOffset;
    request.headers['language'] = "en";
    request.baseURL = BASE_URL;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// ------------------------ axios interceptor response -------------------------
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // If token has expired (401 error), try refreshing the token
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Flag to avoid infinite retry loop

      const refreshToken = typeof window !== "undefined" ? window.localStorage.getItem("refreshToken") : null;

      if (refreshToken) {
        try {
          // Attempt to refresh the token using the refresh token
          const response = await refreshTokenAPI(refreshToken);

          const newToken = response?.data?.responseData?.token;
          const newRefreshToken = response?.data?.responseData?.refreshToken;

          if (newToken) {
            // Set the new token and refresh token
            localStorage.setItem("token", newToken);
            localStorage.setItem("refreshToken", newRefreshToken);

            // Update the axios request with the new token
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

            // Reattempt the original request with the new token
            return axios(originalRequest);
          }
        } catch (refreshError) {
          console.error("Refresh token error:", refreshError);
        }
      }

      // If refresh token is expired or failed, clear the session
      if (typeof window !== "undefined") {
        window.localStorage.clear();
        caches.keys().then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName);
          });
        });
      }

      toast.error(t('session_expired'));

      // Redirect to the login page if refresh token is also expired
      if (typeof window !== "undefined") {
        window.location.href = "/";  // Change this if you're using react-router
      }

      return Promise.reject(error);
    }

    // Handle other errors (400, etc.)
    switch (error.response?.status) {
      case 400:
        toast.error(error.response?.data?.message || 'Bad request.');
        break;
      default:
        toast.error(t('error_something_went_wrong'));
        break;
    }

    return Promise.reject(error);
  }
);



