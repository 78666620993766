import React, { useEffect, useState } from "react";
import SideBar from "../Dashboard/SideBar";
import Switch from "react-switch";
import "./TailwindGeneralSettings.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  getExpertGeneralSettings,
  postExpertGeneralSettings,
} from "../../Components/action/becomeSpeakerAction";
import toast from "react-hot-toast";
import { t } from "i18next";

const ExpertGeneralSettings = () => {
  const [formData, setFormData] = useState({
    sections: [{ title: "", paragraph: "" }],
    titleHeader: "",
    titleParagraph: "",
    videoLink: "",
    jaCheckBox1: true,
    jaCheckBox2: true,
    jaCheckBox3: true,
    jaText1: "",
    jaText2: "",
    jaText3: "",
  });

  const fetchGeneralSettings = async () => {
    await getExpertGeneralSettings().then((res) => {
      const data = res?.data?.responseData?.applicationSettings;
      if (data) {
        setFormData({
          sections: data.sections || [{ title: "", paragraph: "" }],
          titleHeader: data.titleHeader || "",
          titleParagraph: data.titleParagraph || "",
          videoLink: data.videoLink || "",
          jaCheckBox1: data.jaCheckBox1 !== undefined ? data.jaCheckBox1 : true,
          jaCheckBox2: data.jaCheckBox2 !== undefined ? data.jaCheckBox2 : true,
          jaCheckBox3: data.jaCheckBox3 !== undefined ? data.jaCheckBox3 : true,
          jaText1: data.jaText1 || "",
          jaText2: data.jaText2 || "",
          jaText3: data.jaText3 || "",
        });
      }
    });
  };

  useEffect(() => {
    fetchGeneralSettings();
  }, []);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedSections = [...formData.sections];
    updatedSections[index][name] = value;
    setFormData({ ...formData, sections: updatedSections });
  };

  const handleNonSectionInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSwitchChange = (name, checked) => {
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const addNewSection = () => {
    setFormData({
      ...formData,
      sections: [...formData.sections, { title: "", paragraph: "" }],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let applicationSettings = {
      applicationSettings: {
        ...formData,
        termConditions: [],
      },
    };

    let termConditions = [];

    [1, 2, 3].forEach((item) => {
      let termCondition = {};
      termCondition.id = `jaCheckBox${item}`;
      termCondition.label = formData?.[`jaText${item}`] || "";
      termCondition.status = formData?.[`jaCheckBox${item}`] || false;
      termConditions.push(termCondition);
    });

    if (applicationSettings?.applicationSettings) {
      applicationSettings.applicationSettings.termConditions = termConditions;
    }

    await postExpertGeneralSettings(applicationSettings);
    toast.success(t('success_settings_updated'));
  };

  const removeSection = (index) => {
    const updatedSections = formData.sections.filter((_, i) => i !== index);
    setFormData({ ...formData, sections: updatedSections });
  };

  return (
    <>
      <SideBar />
      <div className="content-page">
        <div className="UserLoader" />
        <div className="content userpage formGeneralSettings">
          <div className="container">
            <form onSubmit={handleSubmit} className="w-full flex justify-center">
              <div className="w-[90%]">
            <h3>{t('general_settings')}</h3>
              {/* Title Header */}
              <div className="flex flex-col w-full mt-10  mb-4">
                <label htmlFor="titleHeader" className="mb-2">
                  {t('title')}
                </label>
                <input
                  type="text"
                  id="titleHeader"
                  name="titleHeader"
                  value={formData.titleHeader}
                  onChange={handleNonSectionInputChange}
                  className="form-control  border rounded-lg p-2"
                  placeholder="Type title"
                />
              </div>

              {/* Title Paragraph */}
              <div className="flex flex-col w-full  mb-4">
                <label htmlFor="titleParagraph" className="mb-2">
                  {t('title_paragraph')}
                </label>
                {/* <textarea
                  id="titleParagraph"
                  name="titleParagraph"
                  value={formData.titleParagraph}
                  onChange={handleNonSectionInputChange}
                  className="form-control  border rounded-lg p-2"
                  placeholder="Type title paragraph"
                  rows={4}
                /> */}

                <CKEditor
                  editor={ClassicEditor}
                  data={formData.titleParagraph}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFormData((prev) => ({
                      ...prev,
                      titleParagraph: data,
                    }));
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                  disabled={false}
                />
              </div>

              {/* Video Link */}
              <div className="flex flex-col w-full  mb-4">
                <label htmlFor="videoLink" className="mb-2">
                  {t('video_link')}
                </label>
                <input
                  type="url"
                  id="videoLink"
                  name="videoLink"
                  value={formData.videoLink}
                  onChange={handleNonSectionInputChange}
                  className="form-control  border rounded-lg p-2"
                  placeholder="Type video URL"
                />
              </div>
              <div className="mb-4">
                <h3 className="text-btnColor">{t('details')}</h3>
                {formData.sections.map((section, index) => (
                  <div key={index}>
                    {" "}
                    {/* Title Input */}
                    <div className="flex flex-col w-full mb-4">
                      <label
                        htmlFor={`title-${index}`}
                        className="flex items-cType mb-2"
                      >
                        {t('title')}
                      </label>
                      <input
                        type="text"
                        id={`title-${index}`}
                        name="title"
                        value={section.title}
                        onChange={(e) => handleInputChange(e, index)}
                        className="form-control  border rounded-lg p-2"
                        placeholder="Type title"
                      />
                    </div>
                    {/* Paragraph Textarea */}
                    <div className="flex flex-col w-full mb-4">
                      <label
                        htmlFor={`paragraph-${index}`}
                        className="flex items-cType mb-2"
                      >
                        {t('paragraph')}
                      </label>
                      <textarea
                        id={`paragraph-${index}`}
                        name="paragraph"
                        value={section.paragraph}
                        onChange={(e) => handleInputChange(e, index)}
                        className="form-control  border rounded-lg p-2"
                        placeholder="Type paragraph"
                        rows={4}
                      />
                    </div>
                    {/* Remove Section Button */}
                    {formData.sections.length > 1 && (
                      <div className="w-full flex justify-end">
                        <button
                          type="button"
                          onClick={() => removeSection(index)}
                          className="bg-red-600 border-none text-white p-2 rounded-lg mt-2"
                        >
                          {t('remove')}
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="flex items-cType mb-4">
                <button
                  type="button"
                  onClick={addNewSection}
                  className="bg-btnColor text-white p-2 rounded-lg"
                >
                  + {t('add_more')}
                </button>
              </div>

              {/* JA Checkboxes using React Switch */}
              <div className="flex flex-col w-full  mb-4">
                <h3 className="text-btnColor">{t('terms_constions')}</h3>
                <div className="flex flex-col gap-6">
                  <div className="flex items-cType gap-4">
                    {/* <Switch
                      id="jaCheckBox1"
                      checked={formData.jaCheckBox1}
                      onChange={(checked) =>
                        handleSwitchChange("jaCheckBox1", checked)
                      }
                      onColor="#4CAF50"
                      offColor="#ccc"
                    /> */}
                     <label className="switch">
                      <input
                        type="checkbox"
                        checked={formData?.jaCheckBox1}
                        onChange={(e) =>
                          handleSwitchChange("jaCheckBox1", e.target.checked)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                    <input
                      type="text"
                      id="jaText1"
                      name="jaText1"
                      value={formData.jaText1}
                      onChange={handleNonSectionInputChange}
                      className="form-control  border rounded-lg p-2"
                      placeholder="Type Terms & Conditions 1...."
                    />
                  </div>

                  <div className="flex items-cType gap-4">
                    {/* <Switch
                      id="jaCheckBox2"
                      checked={formData.jaCheckBox2}
                      onChange={(checked) =>
                        handleSwitchChange("jaCheckBox2", checked)
                      }
                    /> */}

                     <label className="switch">
                      <input
                        type="checkbox"
                        checked={formData?.jaCheckBox2}
                        onChange={(e) =>
                          handleSwitchChange("jaCheckBox2", e.target.checked)
                        }
                      />
                      <span className="slider round"></span>
                    </label>


                    <input
                      type="text"
                      id="jaText2"
                      name="jaText2"
                      value={formData.jaText2}
                      onChange={handleNonSectionInputChange}
                      className="form-control  border rounded-lg p-2"
                      placeholder="Type Terms & Conditions 2...."
                    />
                  </div>

                  <div className="flex items-cType gap-4">
                    {/* <Switch
                      id="jaCheckBox3"
                      checked={formData.jaCheckBox3}
                      onChange={(checked) =>
                        handleSwitchChange("jaCheckBox3", checked)
                      }
                      onColor="#4CAF50"
                      offColor="#ccc"
                    /> */}

                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={formData?.jaCheckBox3}
                        onChange={(e) =>
                          handleSwitchChange("jaCheckBox3", e.target.checked)
                        }
                      />
                      <span className="slider round"></span>
                    </label>

                    <input
                      type="text"
                      id="jaText3"
                      name="jaText3"
                      value={formData.jaText3}
                      onChange={handleNonSectionInputChange}
                      className="form-control  border rounded-lg p-2"
                      placeholder="Type Terms & Conditions 3...."
                    />
                  </div>
                </div>
              </div>

              <div className="w-full  text-center ">
                <button
                  type="submit"
                  className="bg-btnColor text-white mb-10 hover:scale-105 p-3 px-10 rounded-lg "
                >
                  {t('submit')}
                </button>
              </div>

              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpertGeneralSettings;
