import React, {useState} from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { addAccount } from '../../../Components/action/common'
import { useDispatch } from 'react-redux'
import { PushAccountList } from '../../../redux/slices/admin'
import { t } from 'i18next'

function AddAccount({ show, onHide, callback }) {
  const dispatch=useDispatch()
  const { register, handleSubmit, formState: { errors } } = useForm()
  const [submitting, setSubmitting] = useState(false);

  const onAddAccount = async (data) => {
    let requestBody = {
      "name": data?.name,
      "host": data?.host,
      "userName": data?.userName,
      "password": data?.password,
      "databaseName":data?.databaseName,
      "publicDomainName": data?.publicDomainName
    }
    try {
      setSubmitting(true);
      let response = await addAccount(requestBody)
      setSubmitting(false)
      dispatch(PushAccountList(response?.data?.responseData?.data))
      callback();
      onHide()
    } catch ({ response }) {
      setSubmitting(false)
    }
  }

  return (
    <Modal id="addExpertUser" className="modal fade" show={show} onHide={() => { onHide() }}>
      <div className="modal-header">
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onHide}><span aria-hidden="true">×</span></button>
        <h4 className="modal-title text-xs-center">{t('add_account')}</h4>
      </div>
      <div className="modal-body formLog">
        <div className="comman_response"> </div>
        <form onSubmit={handleSubmit(onAddAccount)}>
          <div className="form-group">
            <label className="control-label">{t('account_name')}</label>
            <div>
              <input type="text" className="form-control input-lg" name="name" {...register("name", {
                required: {
                  value: true,
                  message: t('name_is_required')
                }
              })} />
              <div className="error error_first_name" >{errors?.name?.message}</div>
            </div>
          </div>
          <div className="form-group">
            <label className="control-label">{t('public_domain')}</label>
            <div>
              <input className="form-control input-lg" id="publicDomainName" name="publicDomainName"  {...register("publicDomainName", {
                required: {
                  value: true,
                  message:t('public_domain_is_required')
                }
              })} />
              <div className="error error_publicDomainName" >{errors?.publicDomainName?.message}</div>
            </div>
          </div>
          <fieldset className="custom_fieldset">
              <legend className='custom_legend'>{t('database_settings')}</legend>
          <div className="form-group">
            <label className="control-label">{t('host')}</label>
            <div>
              <input className="form-control input-lg" id="host" name="host"  {...register("host", {
                required: {
                  value: true,
                  message: t('host_is_required')
                }
              })} />
              <div className="error error_email" >{errors?.host?.message}</div>
            </div>
          </div>
          <div className="form-group">
            <label className="control-label">{t('user')}</label>
            <div>
              <input className="form-control input-lg" id="email" name="userName"  {...register("userName", {
                required: {
                  value: true,
                  message: t('user_is_required')
                }
              })} />
              <div className="error error_email" >{errors?.userName?.message}</div>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label">{t('password')}</label>
            <div>
              <input type="password" className="form-control input-lg" name="password" {...register("password", {
                required: {
                  value: true,
                  message: t('password_is_required')
                }
              })} />
              <div className="error error_password" >{errors?.password?.message}</div>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label">{t('database_name')}</label>
            <div>
              <input className="form-control input-lg" id="databaseName" name="databaseName"  {...register("databaseName", {
                required: {
                  value: true,
                  message: t('database_name_is_required')
                }
              })} />
              <div className="error error_databaseName" >{errors?.databaseName?.message}</div>
            </div>
          </div>
          </fieldset>
         


          <div className="form-group">

            <button type='submit' className="btnblue saveUser loadingbtns btn btn-theme">
              {submitting?<><i class="fa fa-circle-o-notch fa-spin"></i> {t('loading')}</>: t('save_settings')}</button>

          </div>
        </form>
      </div>
    </Modal>
  )
}

export default AddAccount


