import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { t } from "i18next";

function SideBar() {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const { loggedInData } = useSelector((state) => state.admin);
  useEffect(() => {
    let path = window.location.pathname;
    if (path == "/account") {
      setIsActive(true);
    } else if (path == "/become-speaker-users") {
      setIsActive(true);
    } else if (path == "/admin") {
      setIsActive(true);
    } else if (path == "/assessment") {
      setIsActive(true);
    } else if (path == "/assessment-old") {
      setIsActive(true);
    } else if (path == "/unapprovedComments") {
      setIsActive(true);
    } else if (path == "/tags") {
      setIsActive(true);
    } else if (path == "/recommendedTags") {
      setIsActive(true);
    } else if (path == "/shop-new") {
      setIsActive(true);
    } else if (path == "/experts-new") {
      setIsActive(true);
    } else if (path == "/welcome-new") {
      setIsActive(true);
    } else if (path == "/experts-general-settings") {
      setIsActive(true);
    } else if (path == "/assessment-archieved") {
      setIsActive(true);
    } else if (path == "/products") {
      setIsActive(true);
    } else if (path == "/archive-products") {
      setIsActive(true);
    } else if (path == "/email-template") {
      setIsActive(true);
    }
  }, []);
  return (
    <div className="left side-menu">
      <div className="sidebar-inner">
        {/*- Divider */}
        <div id="sidebar-menu">
          <ul>
            <div className="text-center sidebar_logo">
              <a className="logo">
                <img
                  className="img-responsive"
                  src="./images/logo-200x200.svg"
                />
                {/* <span><font style={{verticalAlign: 'inherit'}}>TDB Speaker Agency</font></span> */}
              </a>
              <li className="text-muted menu-title">
                <font style={{ verticalAlign: "inherit" }}>
                  {t("elite_speaker")}
                </font>
              </li>
            </div>

            {loggedInData?.role === "EXPERT_USER" && (
              <>
                <li>
                  <Link
                    className={`waves-effect waves-light ${
                      isActive == true &&
                      window.location.pathname == "/welcome-new"
                        ? "active"
                        : ""
                    }`}
                    to="/welcome-new"
                  >
                    <span>
                      <font
                        style={{ verticalAlign: "inherit", fontSize: "14px" }}
                      >
                        {t("welcome")}
                      </font>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`waves-effect waves-light ${
                      isActive == true &&
                      window.location.pathname == "/experts-new"
                        ? "active"
                        : ""
                    }`}
                    to="/experts-new"
                  >
                    <span>
                      <font
                        style={{ verticalAlign: "inherit", fontSize: "14px" }}
                      >
                        {t("expert")}
                      </font>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`waves-effect waves-light ${
                      isActive == true &&
                      window.location.pathname == "/shop-new"
                        ? "active"
                        : ""
                    }`}
                    to="/shop-new"
                  >
                    <span>
                      <font
                        style={{ verticalAlign: "inherit", fontSize: "14px" }}
                      >
                        {t("shop")}
                      </font>
                    </span>
                  </Link>
                </li>
              </>
            )}

            {loggedInData?.role != "EXPERT_USER" && (
              <li>
                <Link
                  className={`waves-effect waves-light ${
                    isActive == true &&
                    window.location.pathname == "/assessment"
                      ? "active"
                      : ""
                  }`}
                  to="/assessment"
                >
                  <i className="fa fa-id-badge" />{" "}
                  <span>
                    <font style={{ verticalAlign: "inherit" }}>
                      {t("assessment")}
                    </font>
                  </span>
                </Link>
              </li>
            )}

            {loggedInData?.role == "SUPER_ADMIN" && (
              <>
                <li>
                  <Link
                    className={`waves-effect waves-light ${
                      isActive == true && window.location.pathname == "/admin"
                        ? "active"
                        : ""
                    }`}
                    to="/admin"
                  >
                    <i className="fa fa-user" />
                    <span>{t("user")}</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`waves-effect waves-light ${
                      isActive == true && window.location.pathname == "/account"
                        ? "active"
                        : ""
                    }`}
                    to="/account"
                  >
                    <i className="fa fa-lock" /> <span>{t("account")}</span>{" "}
                  </Link>
                </li>
                {loggedInData?.role == "SUPER_ADMIN" && (
                  <li>
                    <Link
                      className={`waves-effect waves-light ${
                        isActive == true &&
                        window.location.pathname == "/become-speaker-users"
                          ? "active"
                          : ""
                      }`}
                      to="/become-speaker-users"
                    >
                      <i className="fa fa-list-alt" />{" "}
                      <span>{t("application_list")}</span>{" "}
                    </Link>
                  </li>
                )}
              </>
            )}
            {(loggedInData?.role == "SUPER_ADMIN" ||
              loggedInData?.role == "MANAGER") && (
              <>
                <li>
                  <Link
                    className={`waves-effect waves-light ${
                      isActive == true &&
                      window.location.pathname == "/unapprovedComments"
                        ? "active"
                        : ""
                    }`}
                    to="/unapprovedComments"
                  >
                    <i className="fa fa-tags" />{" "}
                    <span>{t("unapproved_comments")}</span>{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    className={`waves-effect waves-light ${
                      isActive == true &&
                      window.location.pathname == "/recommendedTags"
                        ? "active"
                        : ""
                    }`}
                    to="/recommendedTags"
                  >
                    <i className="fa fa-tags" />{" "}
                    <span>{t("recommended_tags")}</span>{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    className={`waves-effect waves-light ${
                      isActive == true && window.location.pathname == "/tags"
                        ? "active"
                        : ""
                    }`}
                    to="/tags"
                  >
                    <i className="fa fa-tags" /> <span>{t("tags")}</span>{" "}
                  </Link>
                </li>
              </>
            )}
            {/* <li>
              <Link
                className={`waves-effect waves-light ${
                  isActive == true && window.location.pathname == "/assessment"
                    ? "active"
                    : ""
                }`}
                to="/assessment"
              >
                <i className="fa fa-id-badge" />{" "}
                <span>
                  <font style={{ verticalAlign: "inherit" }}>Assessment</font>
                </span>
              </Link>
            </li> */}

            {(loggedInData?.role == "SUPER_ADMIN" ||
              loggedInData?.role == "MANAGER") && (
              <li>
                <Link
                  className={`waves-effect waves-light ${
                    isActive == true &&
                    window.location.pathname == "/assessment-archieved"
                      ? "active"
                      : ""
                  }`}
                  to="/assessment-archieved"
                >
                  <i className="fa fa-archive" />{" "}
                  <span>
                    <font style={{ verticalAlign: "inherit" }}>
                      {t("archived_assessment")}
                    </font>
                  </span>
                </Link>
              </li>
            )}

            {(loggedInData?.role == "SUPER_ADMIN" ||
              loggedInData?.role == "MANAGER") && (
              <li>
                <Link
                  className={`waves-effect waves-light ${
                    isActive == true && window.location.pathname == "/products"
                      ? "active"
                      : ""
                  }`}
                  to="/products"
                >
                  <i className="fa fa-archive" />{" "}
                  <span>
                    <font style={{ verticalAlign: "inherit" }}>
                      {t("products")}
                    </font>
                  </span>
                </Link>
              </li>
            )}

            {(loggedInData?.role == "SUPER_ADMIN" ||
              loggedInData?.role == "MANAGER") && (
              <li>
                <Link
                  className={`waves-effect waves-light ${
                    isActive == true &&
                    window.location.pathname == "/archive-products"
                      ? "active"
                      : ""
                  }`}
                  to="/archive-products"
                >
                  <i className="fa fa-archive" />{" "}
                  <span>
                    <font style={{ verticalAlign: "inherit" }}>
                      {t("archive_products")}
                    </font>
                  </span>
                </Link>
              </li>
            )}
          </ul>
          <div className="clearfix" />
          <ul>
            {loggedInData?.role == "SUPER_ADMIN" && (
              <>
                <li>
                  <Link
                    className={`waves-effect waves-light ${
                      isActive == true &&
                      window.location.pathname == "/experts-general-settings"
                        ? "active"
                        : ""
                    }`}
                    to="/experts-general-settings"
                  >
                    <i className="fa  fa-pencil-square" />{" "}
                    <span>{t("application_settings")}</span>{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    className={`waves-effect waves-light ${
                      isActive == true &&
                      window.location.pathname == "/email-template"
                        ? "active"
                        : ""
                    }`}
                    to="/email-template"
                  >
                    <i className="fa  fa-pencil-square" />{" "}
                    <span>{t("application_email_template")}</span>{" "}
                  </Link>
                </li>
              </>
            )}

            <li
              className="logout_wrpr"
              onClick={() => {
                localStorage.clear();
                navigate("/");
              }}
            >
              <a href="/#">
                <i className="fa fa-archive" />{" "}
                <span>
                  <font style={{ verticalAlign: "inherit" }}>
                    {t("logout")}
                  </font>
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div className="clearfix" />
      </div>
    </div>
  );
}

export default SideBar;
