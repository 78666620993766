import { t } from "i18next";
import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { postMakeExpert } from "../../Components/action/makeExpert";

function AssessmentMakeExpertModal({
  show,
  onHide,
  assessment,
}) {

  const [password, setPassword] = useState(null);
  const [isLoader, setIsLoader] = useState(false);

  const handleSubmit=async(e)=>{
    e.preventDefault();
    const dataToSend={};
    dataToSend.refUserId=assessment?.id
    if(password)
    {
        dataToSend.password=password
    }
    setIsLoader(true)
   try {
    await postMakeExpert(dataToSend)
    setIsLoader(false)
    onHide();
   } catch (error) {
    setIsLoader(false)
   }
    
  }

  return (
    <Modal
      className="modala fade in"
      style={{ display: "block", paddingLeft: "0px" }}
      show={show}
      onHide={() => {
        onHide();
      }}
      backdrop="static"
    >
      <div className="modal-contenth" id="changePasswordPanel">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <span aria-hidden="true">
              <font style={{ verticalAlign: "inherit" }}>
                <font style={{ verticalAlign: "inherit" }}>×</font>
              </font>
            </span>
          </button>
          <h4 className="modal-title" id="assignModalLabel">
            <font style={{ verticalAlign: "inherit" }}>
              <font style={{ verticalAlign: "inherit" }}>
                {t("make_expert")}
              </font>
            </font>
          </h4>
        </div>
        <div className="modal-body formLog">
          <div className="comman_response" />
          <form
            className="changePasswordForm"
            onSubmit={handleSubmit}
          >
            <div className="voucher_outer">
              <div className="row">
                <div className="form-group col-sm-12">
                  <label className="control-label">{t("name")}</label>
                  <div>
                    <input
                      type="text"
                      className="form-control input-lg"
                      name="name"
                      defaultValue={assessment?.name}
                      disabled
                    />
                  </div>
                </div>

                <div className="form-group col-sm-12">
                  <label className="control-label">{t("password_optional")}</label>
                  <div>
                    <input
                      type="text"
                      className="form-control input-lg"
                      name="name"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>

              </div>
            </div>
            <div>
              <button
                type="submit"
                className="btn btn-primary modelChangePasswordSave loadingbtns"
              >
                {isLoader ? `${t("loading")}...` : t("submit")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default AssessmentMakeExpertModal;
