import React, { useState } from 'react'
import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { deleteComment, fetchComment, postComments } from '../../../Components/action/common'
import { useDispatch, useSelector } from 'react-redux'
import { setCommentList } from '../../../redux/slices/admin'
import moment from 'moment'
import { Rating } from 'react-simple-star-rating';
import { GiSheep } from "react-icons/gi";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { useTranslation } from 'react-i18next'


function CommentsNew({ show, onHide, assessment, fetchAssessmentDetail, params }) {
  const {t}=useTranslation();
  const dispatch = useDispatch()
  const { register, handleSubmit, formState: { errors }, reset, getValues, setValue } = useForm()
  const { fetchComments, loggedInData } = useSelector(state => state.admin)
  const [isLoder, setIsLoader] = useState(false)
  const [startRating, setStartRating] = useState(1)
  const [sheepRating, setSheepRating] = useState(0)
  const [editCommentObj, setEditCommentObj] = useState(null)
  const [editCommentIndex, setEditCommentIndex] = useState(null)


  const onCommentSubmit = async (values) => {
    setIsLoader(true)
    let requestBody = {
      "refUserId": assessment?.id,
      "comments": values?.comments,
      "starRating": startRating,
      "sheepRating": sheepRating,
      "starRatingComment": values?.starRatingComment,
      "sheepRatingComment": values?.sheepRatingComment
    }
    if (editCommentIndex != null) {
      requestBody = Object.assign(requestBody, { id: editCommentObj?.id })
    }
    try {
      let response = await postComments(requestBody)
      setEditCommentIndex(null);
      setEditCommentObj(null);
      reset()
      fetchCommentsList()
      setIsLoader(false)
      fetchAssessmentDetail(params);
      // onHide()
    } catch ({ response }) {
      setIsLoader(false)
    }
  }

  const onDeleteComment = async (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              let response = await deleteComment({ "id": id });
              fetchCommentsList()
            } catch ({ response }) {

            }
          }
        },
        {
          label: 'No',
          //onClick: () => alert('Click No')
        }
      ]
    });

    // try {
    //   let response = await deleteComment({ "id": id })
    //   fetchCommentsList()
    // } catch ({ response }) {

    // }
  }

  const fetchCommentsList = async () => {
    try {
      let response = await fetchComment({ "refUserId": assessment?.id })
      dispatch(setCommentList(response?.data?.responseData?.data))
    } catch ({ response }) {

    }
  }

  useEffect(() => {
    fetchCommentsList()
  }, [])


  const editComment = (obj, index) => {
    setValue('comments', obj?.comments);
    setValue('sheepRating', obj?.sheepRating);
    setValue('sheepRatingComment', obj?.sheepRatingComment);
    setValue('starRating', obj?.starRating)
    setValue('starRatingComment', obj?.starRatingComment)
    setStartRating(obj?.starRating);
    setSheepRating(obj?.sheepRating);
    setEditCommentObj(obj);
    setEditCommentIndex(index);
    const element = document.getElementById("comment_box");
    element.scrollIntoView();
  }
  console.log('loggedInData', loggedInData)
  return (
    <Modal className="modal right fade" show={show} onHide={() => { onHide() }}>
      <div className="modal-header" id = "comment_box">
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onHide}><span aria-hidden="true">×</span></button>
        <h4 className="modal-title text-xs-center">{t('remarks')}</h4>

      </div>
      <div className="modal-body formLog">
        <div className="comman_response" > </div>
        <form onSubmit={handleSubmit(onCommentSubmit)}>
          <div className="form-group">
            <textarea className='formStyle' {...register("comments", {
              required: {
                value: true,
                message: "Required"
              }
            })}></textarea>
            <div className="error error_email" >{errors?.comments?.message}</div>
          </div>
          <div className='form-group'>
            <h4 className="modal-title text-xs-center">{t('sheep_rating')} <button type="button" className="button_link pull-right" onClick = {() => {
              setSheepRating(0);
              
            }}>{t('reset_rating')}</button></h4>
            <Rating
              initialValue={sheepRating}
              fillIcon={<GiSheep size={50} color={"#000000"} />}
              emptyIcon={<GiSheep size={50} color={"#cccccc"} />}
              onClick={(r) => setSheepRating(r)}
            />
            <textarea className='formStyle' style={{ minHeight: "80px" }} {...register("sheepRatingComment")}></textarea>
          </div>
          <div className='form-group'>
            <h4 className="modal-title text-xs-center">{t('rating')} <button type="button" className="button_link pull-right" onClick = {() => {
              setStartRating(0);
              
            }}>{t('reset_rating')}</button></h4>
            <Rating
              initialValue={startRating}
              onClick={(r) => setStartRating(r)}
            />
            <textarea className='formStyle' style={{ minHeight: "80px" }} {...register("starRatingComment")}></textarea>
          </div>
          <div className="form-group">
            {
              editCommentIndex != null &&
              <button type='button' className="btn btn-cancel " style={{marginRight: "10px"}} onClick = {() => {
                setValue('comments', "");
                setValue('sheepRating', 0);
                setValue('sheepRatingComment', "");
                setValue('starRating', 0)
                setValue('starRatingComment', "")
                setStartRating(0);
                setSheepRating(0);
                setEditCommentObj(null);
                setEditCommentIndex(null);
              }}>{t('cancel')}</button>
            }
            <button type='submit' className="btnblue saveUser loadingbtns btn btn-primary">{isLoder ? `${t('loading')}...` : editCommentIndex == null ? t('save'):t('update')}</button>
          </div>
        </form>

        <div className='comments'>
          {
            fetchComments?.map((el, index) => {
              return (
                <div className='comments-list' key={el?.id}>

                  <div className='comments-msg'>
                    <div className='comments-row'>
                      {
                        loggedInData?.role == "SUPER_ADMIN" || loggedInData?.role == "MANAGER" || loggedInData?.id == el?.userId
                          ?
                          <>
                            <span className='edit-msg' onClick={() => editComment(el, index)}><i title="Edit" className="fa fa-edit" style={{ cursor: "pointer", fontSize: "14px" }}></i></span>
                            <span className='close-msg' onClick={() => onDeleteComment(el?.id)}>×</span>
                          </>

                          :
                          <></>
                      }

                      <div className='comments-by'>
                        <span className='st-name'>{el?.User?.name.charAt(0).toUpperCase()}</span>
                      </div>
                      <div className='comments-data'>
                        <div className='by-user'>
                          <div className='posted-by'> {el?.User?.name.toUpperCase()}{el?.updatedByAdmin ? <small className="edited_tag">{`(${t('edited')})`}</small>:<></>} </div>
                          <div className='comment-date'>
                            {moment(el?.createdAt).format('YYYY-MM-DD')}
                          </div>
                        </div>
                        <div className='comments-pl'>
                          {el?.comments}
                        </div>
                        <div className='comments-pl'>
                          <Rating
                            initialValue={el?.sheepRating}
                            fillIcon={<GiSheep size={30} color={"#000000"} />}
                            emptyIcon={<GiSheep size={30} color={"#cccccc"} />}
                            readonly
                          />
                        </div>
                        <div className='comments-pl'>
                          {el?.sheepRatingComment}
                        </div>
                        <div className='comments-pl'>
                          <Rating
                            initialValue={el?.starRating}
                            readonly
                          />
                        </div>
                        <div className='comments-pl'>
                          {el?.starRatingComment}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }

        </div>
      </div>
    </Modal>
  )
}

export default CommentsNew