import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { addTag, addTagViewer } from "../../../Components/action/tags";
import { useDispatch } from "react-redux";
import { PushAccountList } from "../../../redux/slices/admin";
import { fetchTagList } from "../../../Components/action/common";
import Select from "react-select";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { confirmAlert } from "react-confirm-alert";

function ManageRecommendedTagNew({ recommendedFor,fetchAssessmentDetail, show, onHide }) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [tagList, setTagList] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    getTags();
  }, [recommendedFor]);

  const getTags = async () => {
    try {
      let response = await fetchTagList({ status: 0 });
      let tags = response?.data?.responseData;

      const dd = tags?.filter((obj) => {
        const tagNamesArray = recommendedFor?.tagNames
          ?.split(",")
          ?.map((name) => name.trim());
        return !tagNamesArray?.includes(obj.name?.toString());
      });
      setTagList(dd);
    } catch ({ response }) {}
  };

  const updateTags = (obj) => {
    setSelectedTags(obj);
  };

  const onUpdateTag = async () => {
    setSuccessMessage(false);
    setErrorMessage(false);
    let dataToSend = {};
    let tagId = selectedTags?.map((item) => item?.id);
    dataToSend = { tagId, recommendedFor: recommendedFor?.id };
    confirmAlert({
      title: t("confirm_to_recommend_tags"),
      message: t("are_you_sure_recommended_tags"),
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            try {
              await addTagViewer(dataToSend);
              fetchAssessmentDetail();
              onHide();
            } catch ({ response }) {
              
            }
          },
        },
        {
          label: t("no"),
          //onClick: () => alert('Click No')
        },
      ],
    });
  };
  return (
    <Modal
      id="addExpertUser"
      className="modal fade"
      show={show}
      onHide={() => {
        onHide();
      }}
    >
      <div className="modal-header">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onHide}
        >
          <span aria-hidden="true">×</span>
        </button>
        <h4 className="modal-title text-xs-center">{t("recommend_a_tag")}</h4>
      </div>
      <div className="modal-body formLog">
        <div className="comman_response"> </div>
        <form onSubmit={handleSubmit(onUpdateTag)}>
          <div className="form-group" style={{ minWidth: "300px" }}>
            {successMessage ? (
              <span className="success_message">{successMessage}</span>
            ) : errorMessage ? (
              <span className="error_message">{errorMessage}</span>
            ) : (
              <></>
            )}
          </div>

          {showForm ? (
            <>
              <div className="form-group">
                <label className="control-label">
                  <font style={{ verticalAlign: "inherit" }}>{t("tags")}</font>
                </label>
                <Select
                  placeholder={`${t("select")}...`}
                  getOptionLabel={(obj) => obj.name}
                  getOptionValue={(obj) => obj.id}
                  closeMenuOnSelect={false}
                  value={selectedTags}
                  isMulti
                  options={tagList}
                  onChange={(obj) => {
                    updateTags(obj);
                  }}
                />
                <div className="error error_first_name">
                  {errors?.tag?.message}
                </div>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btnblue saveUser loadingbtns btn btn-theme"
                >
                  {submitting ? (
                    <>
                      <i class="fa fa-circle-o-notch fa-spin"></i>{" "}
                      {t("loading")}
                    </>
                  ) : (
                    t("save")
                  )}
                </button>
              </div>
            </>
          ) : null}
        </form>
      </div>
    </Modal>
  );
}

export default ManageRecommendedTagNew;
