import React, { useState, useCallback, useRef } from "react";
import Cropper from "react-cropper";
import { useDropzone } from "react-dropzone";
import Modal from "react-modal";
import "cropperjs/dist/cropper.css";
import { postPhotoBecomeSpeaker } from "../../../Components/action/becomeSpeakerAction";
import { BASE_URL } from "../../../Components/Common/constants";
import axios from "axios";
import { t } from "i18next";

const ExpertCropImage = ({addUpdateProductPicture }) => {
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const cropperRef = useRef(null);

  // Handle file drop
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const img = new Image();
      img.onload = () => {
        // if (img.width < 300 || img.height < 300) {
        //   setError("Image must be at least 300x300 pixels.");
        // } else {
        //   setError("");
          const reader = new FileReader();
          reader.onload = () => {
            setImage(reader.result);
            setIsModalOpen(true); // Open the modal for cropping
          };
          reader.readAsDataURL(file);
        // }
      };
      img.src = URL.createObjectURL(file);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxFiles: 1,
    onDrop,
  });


  const handleCropConfirm = async () => {
    if (cropperRef.current?.cropper !== "undefined") {
        // Get the cropped image as base64 string
        const croppedData = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();

        // Convert base64 to Blob
        const base64Data = croppedData.split(',')[1]; // Remove the base64 metadata (e.g., "data:image/png;base64,")
        const byteCharacters = atob(base64Data); // Decode base64 string
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
            const slice = byteCharacters.slice(offset, offset + 1024);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            byteArrays.push(new Uint8Array(byteNumbers));
        }

        const blob = new Blob(byteArrays, { type: 'image/png' }); // Adjust MIME type based on your image format
        const file = new File([blob], 'cropped-image.png', { type: 'image/png' });

        // Prepare FormData and append the file
        const formData = new FormData();
        formData.append('file', file);

        try {
            // Send the image file via POST request
            // const response = await postPhotoBecomeSpeaker(formData);
            const response = await axios({
              method: 'POST',
              url: `${BASE_URL}/attachment/upload`,
              data: formData,
          });
            
            setCroppedImage(response?.data?.responseData?.filePath);
            setIsModalOpen(false);
            addUpdateProductPicture(response?.data?.responseData?.filePath);
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    }
}


  const handleCropCancel = () => {
    setIsModalOpen(false);
    setImage(null);
  };


  
  return (
    <div className="space-y-6 z-0">
      <label className="preview_title_img">{t('crop_preview')}</label>
      <div className="w-full flex gap-10 items-start">
        {croppedImage ? (
          <>
            <div className="w-[148px] h-[148px]">
              <img
                src={croppedImage}
                alt="Cropped Preview"
                className="w-full h-full object-cover rounded border border-gray-300"
              />
            </div>
            {/* {error && <p className="text-red-500 mt-2">{error}</p>} */}
          </>
        ) : (
          <div className="w-[148px] h-[148px] rounded-[25px] flex items-center justify-center border-2 border-gray-400 text-gray-600  bg-gray-100">
            {t('no_image')}
          </div>
        )}

        <div {...getRootProps()} className="cursor-pointer">
          {/* <input {...getInputProps()} /> */}
          <input
            {...getInputProps({
              accept: "image/*", 
            })}
          />
          <span className="upload-btn">{t('upload_a_photo')}</span>
          <span
            data-tooltip-id="tooltip-right"
            data-tooltip-place="right"
            className="ml-2 text-blue-600 cursor-pointer tooltipForm"
          >
            <img
              src="/images/becomeS/question.png"
              className="w-[20px] h-[20px] "
            />
            <span className="tooltiptext">
              {t('image_upload_tooltip_expert')}
            </span>
          </span>

        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCropCancel}
        className="modalForm"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <h3 className="text-2xl font-bold mb-4">{t('crop_your_image')}</h3>
        <div className="flex gap-6">
          <div className="relative w-full h-[400px] sm:w-[400px] sm:h-[400px] bg-gray-100">
            {image && (
              <Cropper
                ref={cropperRef}
                style={{ height: 400, width: "100%" }}
                zoomTo={0.5}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={200}
                minCropBoxWidth={200}
                cropBoxResizable={false}
                dragMode="move"
                cropBoxData={{
                  width: 200,
                  height: 200,
                }}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={true}
              />
            )}
          </div>
          <div className="w-[148px] h-[148px]">
            <div
              className="img-preview w-full h-full object-cover rounded border border-gray-300"
              style={{ height: "100%" }}
            />
          </div>
        </div>
        <div className="flex justify-end mt-4 space-x-4">
          <button
            className="bg-gray-700 text-white  hover:scale-105 px-4 border-none py-2 rounded"
            onClick={handleCropCancel}
          >
           {t('cancel')}
          </button>
          <button
            className="bg-btnColor border-none hover:scale-105 text-white px-4 py-2 rounded"
            onClick={handleCropConfirm}
          >
            {t('ok')}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ExpertCropImage;
