const frTranslation = {
    elite_speaker: "Orateur d'élite",
    user_management: "Gestion des utilisateurs",
    account_management: "Gestion du compte",
    become_a_speaker_list: "Liste des orateurs",
    unapproved_comments: "Commentaires non approuvés",
    recommended_tags_management: "Gestion des tags recommandés",
    tags_management: "Gestion des tags",
    product_listing: "Liste des produits",
    archive_products: "Produits archivés",
    general_settings: "Paramètres généraux",
    title: "Titre",
    title_paragraph: "Paragraphe de titre",
    paragraph: "Paragraphe",
    video_link: "Lien vidéo",
    terms_constions: "Termes et conditions",
    add_more: "Ajouter plus",
    submit: "Soumettre",
    remove: "Supprimer",
  
    assessment_management: "Gestion des évaluations",
    rank: "Rang",
    name: "Nom",
    email: "Email",
    seats_category: "Catégorie de sièges",
    seats: "Sièges",
    total: "Total",
    invite_category: "Catégorie d'invitation",
    invites: "Invitations",
    rating: "Évaluation",
    sheep_rating: "Évaluation des moutons",
    tags: "Tags",
    edit_tags: "Modifier les tags",
    actions: "Actions",
    export: "Exporter",
    add_expert: "Ajouter un expert",
    loading: "Chargement",
    search: "Recherche",
    select: "Sélectionner",
    select_role: "Sélectionner un rôle",
    freeze_account: "Geler le compte",
    unfreeze_account: "Dégeler le compte",
    edit: "Modifier",
    edited: "Modifié",
    remarks: "Remarques",
    email_list: "Liste des emails",
    delete: "Supprimer",
    transporter: "Transporteur",
    merge: "Fusionner",
    tag_names: "Noms des tags",
    categories: "Catégories",
    save: "Enregistrer",
    reset_rating: "Réinitialiser l'évaluation",
    confirm_to_delete: "Confirmer la suppression",
    are_you_sure_to_do_this: "Êtes-vous sûr de vouloir faire cela ?",
    yes: "Oui",
    no: "Non",
    copy_to_kongress: "Copier vers Kongress",
    transport_the_expert_to_the_following_kongress_domain: "Transporter l'expert vers le domaine Kongress suivant",
    digistore_id: "ID Digistore",
    links: "Liens",
    home_page_term: "Terme affiché sur la page d'accueil",
    home_page_link: "Lien/URL de la page d'accueil",
    home_page_link_tooltip1: "Veuillez entrer le lien réel ici, c'est-à-dire l'URL de la page d'accueil. Si les utilisateurs cliquent maintenant sur le terme précédemment saisi, une nouvelle fenêtre s'ouvrira et la page d'accueil indiquée ici sera affichée. Veuillez entrer le lien en entier, ou de préférence appeler la page d'accueil et copier le lien ci-dessus à partir du navigateur. Cela garantit qu'il n'y a pas de fautes de frappe. Veuillez toujours tester brièvement les liens pour voir si tout fonctionne comme prévu.",
    news_letter_term: "Terme affiché pour la newsletter",
    news_letter_link: "Lien/URL de la newsletter",
    news_letter_link_tooltip1: "Veuillez entrer le lien réel de la page d'inscription à la newsletter ici. Si les utilisateurs cliquent maintenant sur le terme précédemment saisi, une nouvelle fenêtre s'ouvrira et la page d'inscription à la newsletter sera affichée. Veuillez entrer le lien en entier, ou idéalement appeler la page d'inscription à la newsletter et copier le lien ci-dessus à partir du navigateur. Cela garantit qu'il n'y a pas de fautes de frappe. Veuillez toujours tester brièvement les liens pour voir si tout fonctionne comme prévu.",
    freebie_term: "Terme affiché pour l'offre gratuite",
    freebie_link: "Lien/URL de la page gratuite",
    freebie_link_tooltip1: "Veuillez entrer le lien réel ici, c'est-à-dire l'URL de la page de l'offre gratuite. Lorsque les utilisateurs cliquent maintenant sur le terme précédemment saisi, une nouvelle fenêtre s'ouvrira et la page de l'offre gratuite ou du cadeau sera affichée. Veuillez entrer le lien en entier, ou de préférence appeler la page de l'offre gratuite et copier le lien ci-dessus à partir du navigateur. Cela garantit qu'il n'y a pas de fautes de frappe. Toujours tester brièvement les liens pour vérifier si tout fonctionne comme prévu.",
    contact_email_term: "Terme affiché pour l'email de contact",
    contact_email: "Adresse email de contact",
    contact_email_tooltip1: "Veuillez entrer l'adresse email réelle ici, c'est-à-dire l'email de contact auquel la demande doit être envoyée. Si les utilisateurs cliquent maintenant sur le terme précédemment saisi, le navigateur de messagerie standard s'ouvrira et un email sera préparé pour l'adresse indiquée ici. Veuillez vérifier qu'il n'y a pas de fautes de frappe dans l'adresse email. Vous pouvez également tester la fonction d'email de contact pour vérifier si tout fonctionne comme prévu.",
    social_media_links: "Liens vers les réseaux sociaux",
    facebook_link: "Lien Facebook",
    copy: "Copier",
    instagram_link: "Lien Instagram",
    twitter_link: "Lien Twitter",
    youtube_link: "Lien YouTube",
    vimeo_link: "Lien Vimeo",
    pinterest_link: "Lien Pinterest",
    xing_link: "Lien Xing",
    linkedin_link: "Lien LinkedIn",
    telegram_link: "Lien Telegram",
    podcast_link: "Lien Podcast",
    spotify_link: "Lien Spotify",
    clubhouse_link: "Lien Clubhouse",
    tiktok_link: "Lien TikTok",
    products: "Produits",
    archived_products: "Produits archivés",
    expert_short_description: "Description courte de l'expert",
    expert_long_description: "Description longue de l'expert",
    merge_accounts: "Fusionner les comptes",
    cancel: "Annuler",
    update: "Mettre à jour",
    recommend_a_tag: "Recommander un tag",
    add_new_expert: "Ajouter un nouvel expert",
    edit_expert: "Modifier un expert",
    photo: "Photo",
  
    all_user_roles: "Tous les rôles des utilisateurs",
    superAdmin: "SuperAdmin",
    manager: "Gestionnaire",
    viewer: "Spectateur",
    all_user_statuses: "Tous les statuts des utilisateurs",
    active: "Actif",
    inactive: "Inactif",
    allowed_domains: "Domaines autorisés",
    role: "Rôle",
    status: "Statut",
    add_user: "Ajouter un utilisateur",
    save_settings: "Enregistrer les paramètres",
    update_user: "Mettre à jour l'utilisateur",
    account_name: "Nom du compte",
    host: "Hôte",
    domain: "Domaine",
    last_synced: "Dernière synchronisation",
    edit_account: "Modifier le compte",
    public_domain: "Domaine public",
    database_settings: "Paramètres de la base de données",
    user: "Utilisateur",
    password: "Mot de passe",
    database_name: "Nom de la base de données",
    no_data_found: "Aucune donnée trouvée!",
  
    id: "ID",
    full_name: "Nom complet",
  
    sr: "Sr.",
    author: "Auteur",
    comment: "Commentaire",
    back: "Retour",
  
    recommended_by: "Recommandé par",
    recommended_for: "Recommandé pour",
    approve: "Approuver",
  
    sub_tags: "Sous-tags",
    manage_child_tags: "Gérer les sous-tags",
    add_tag: "Ajouter un tag",
  
    digistore_link: "Lien Digistore",
    change_password: "Changer le mot de passe",
    add_account: "Ajouter un compte",
    refresh_account_successfully_scheduled: "Actualisation du compte planifiée avec succès",
    synced_in_progress: "Synchronisation en cours",
    name_is_required: "Le nom est requis",
    public_domain_is_required: "Le domaine public est requis",
    host_is_required: "L'hôte est requis",
    user_is_required: "L'utilisateur est requis",
    password_is_required: "Le mot de passe est requis",
    database_name_is_required: "Le nom de la base de données est requis",
    phone: "Téléphone",
    view: "Voir",
    reject: "Rejeter",
    are_you_sure_delete_product: "Êtes-vous sûr de vouloir supprimer ce produit ?",
    additional_information: "Informations supplémentaires",
    description_details: "Détails de la description",
    short_description: "Description courte :",
    long_description: "Description longue :",
    direct_links: "Liens directs",
    no_data: "Aucune donnée",
    view_product: "Voir le produit",
    no_products_available: "Aucun produit disponible",
    social_links: "Liens vers les réseaux sociaux",
    no_social_links_available: "Aucun lien social disponible",
    tag: "Tag",
    tags: "Tags",
    tag_is_required: "Le tag est requis",
  
    confirm_to_freeze_unfreeze: "Confirmer pour geler/dégeler",
    confirm_to_restore: "Confirmer pour restaurer",
    more: "Plus",
    neuen_tag_vorschlagen: "Proposer un nouveau tag",
    download_me: "Téléchargez-moi",
    product_listing: "Liste des produits",
    details: "Détails",
  
    product_listing: "Liste des produits",
    details: "Détails",
    products: "Produits",
    archived_assessment: "Évaluation archivée",
    recommended_tags: "Tags recommandés",
    unapproved_comments: "Commentaires non approuvés",
    application_list: "Liste des candidatures",
    account: "Compte",
    welcome: "Bienvenue",
    expert: "Expert",
    shop: "Boutique",
    assessment: "Évaluation",
    application_settings: "Paramètres de l'application",
    logout: "Se déconnecter",
  
    experts_welcome_message1: "Nous sommes ravis que vous soyez ici, dans le backend expert de notre plateforme de logiciel de conférence",
    experts_welcome_message2: "Veuillez jeter un coup d'œil rapide à la vidéo. Ici, vous pouvez configurer votre profil, votre entretien et les produits que vous souhaitez proposer. Si vous avez des questions, contactez gregor@teiledeinebotschaft.de",
    experts_welcome_message3: "Cordialement, Gregor Dorsch (Directeur général et fondateur de TeileDeineBotschaft.de)",
    experts_welcome_guide: "Experts : Un court message de bienvenue et un guide",
    experts_application_guide: "Veuillez postuler ici, remplir toutes les informations requises et nous reviendrons vers vous rapidement. Merci.",
    online_congress: "Congrès en ligne",
    title_expert_eg: "Titre (par exemple, Prof., Dr., etc.)",
    expert_short_description_40: "Description courte de l'expert (<40 caractères)",
    email_address_of_expert: "Adresse email de l'expert",
    mobile_number: "Numéro de téléphone mobile",
    profile_description: "Description du profil (parcours professionnel)",
    no_products_found: "Aucun produit trouvé",
    type_product_title: "Tapez le titre du produit...",
    add_product: "Ajouter un produit",
    product_title: "Titre du produit",
    price: "Prix",
    description: "Description",
    product_link: "Lien du produit",
    product_image: "Image du produit :",
    crop_preview: "Aperçu de la découpe",
    upload_a_photo: "Télécharger une photo",
    crop_your_image: "Découper votre image",
    ok: "OK",
    image_upload_tooltip: "Veuillez télécharger l'image puis sélectionner une découpe carrée.",
    image_upload_tooltip_expert: "Téléchargez l'image principale du produit ici. Vous pouvez choisir une découpe carrée avec le téléchargement. La découpe sera automatiquement optimisée et enregistrée sous forme carrée avec 300x300 pixels. Essayez-le, c'est assez simple—assurez-vous que nous n'acceptons que des images carrées à la fin.",
    no_image: "Pas d'image",
    product_image_tooltip: "Téléchargez l'image principale du produit ici. Vous pouvez choisir une découpe carrée. La découpe sera optimisée et enregistrée sous forme carrée avec 300x300 pixels. Testez-le brièvement pour voir si cela fonctionne comme prévu.",
    product_image: "Image du produit",
    product_name_title_description_tooltip: "Le nom du produit, le titre du produit ou la description du produit qui sera utilisé comme titre ou en-tête du produit. Si un titre de produit est entré, les détails suivants (lien vers le produit) et l'image du produit sont des champs obligatoires ! Créez donc zéro, un, deux ou trois produits à proposer dans le congrès via la boutique du congrès.",
    product_link_tooltip: "Veuillez fournir le lien vers la page de vente ou la page de réservation du produit, du service, etc. Si possible, créez un lien affilié pour l'organisateur du congrès. Merci.",
  
    product_title_required: "Le titre du produit est requis",
    product_link_required: "Le lien du produit est requis",
    product_updated_successfully: "Produit mis à jour avec succès",
    new_product_added_successfully: "Nouveau produit ajouté avec succès",
    product_deleted_successfully: "Produit supprimé avec succès !",
    edit_product: "Modifier le produit",
    first_name_required: "Le prénom est requis",
    digistore_id_required: "L'ID Digistore est requis",
    profile_image_required: "L'image du profil est requise",
    email_address_required: "L'adresse email est requise",
    valid_email_required: "Veuillez entrer une adresse email valide",
    updated_user_successfully: "Utilisateur mis à jour avec succès !",
    expert_short_description_tooltip: "L'abréviation de l'expert doit être une désignation très courte et semblable à un mot-clé de l'expertise de l'orateur. Cette désignation est incluse sous le nom de l'expert. Elle est limitée à un maximum de 40 caractères et doit être aussi courte que possible pour éviter les sauts de ligne dans l'affichage.",
    digistore_id_tooltip: "Vous trouverez votre ID Digistore dans votre compte Digistore après vous être connecté dans le coin supérieur gauche. L'ID Digistore est nécessaire pour l'attribution automatique des commissions d'affiliation et le suivi correspondant.",
    email_address_of_expert_tooltip: "Veuillez entrer l'adresse email ici. Cette adresse email sera également ajoutée au système de gestion des utilisateurs.",
    profile_description_tooltip: "Parcours professionnel, CV ou description de l'expert. Cette description sera affichée dans le profil de l'expert sur la page, tant lorsqu'il est déconnecté pour que tout le monde puisse la voir, qu'en zone membre.",
    direct_links_tooltip: "Ces liens apparaissent directement dans le profil de l'expert. Ils sont visibles par tous, tant dans l'état déconnecté qu'en zone membre.",
    social_links_tooltip: "Les liens vers les canaux de médias sociaux apparaissent sous forme de petites icônes directement dans le profil de l'expert.",
  
    application_terms_conditions: "Veuillez confirmer les termes et conditions.",
    application_terms_conditions_tooltip: "Veuillez confirmer que vous avez compris les termes et conditions de participation en tant qu'expert.",
    submit_application: "Soumettre la candidature",
    products_services_courses: "Produits • Services • Cours",
    products_services_courses_tooltip: "Vous pouvez répertorier zéro, un, deux ou trois produits qui seront affichés dans la boutique.",
    application_email_list: "Quelle est la taille de votre liste d'emails ?",
    application_email_list_tooltip: "Description texte libre y compris le formatage.",
    first_name: "Prénom",
    last_name: "Nom de famille",
    error_last_name_required: "Le nom de famille est requis",
    error_confirm_terms_conditions: "Veuillez confirmer les termes et conditions. Merci.",
    product: "Produit",
    error_product_title: "Le titre est requis si une image est fournie.",
    error_product_link: "Le lien est requis si une image est fournie.",
    error_product_image: "L'image est requise.",
    form_submit_successfully: "Formulaire soumis avec succès !",
    become_a_speaker: "Devenez un orateur",
    login: "Se connecter",
    forgot_password: "Mot de passe oublié",
    small_list: "Liste réduite",
    over: "Fin",
    save_changes: "Enregistrer les modifications",
     
    success_speaker_delete: "Utilisateur orateur supprimé avec succès !",
    success_approved_user: "Utilisateur approuvé avec succès !",
    success_rejected_user: "Utilisateur rejeté avec succès !",
    success_expert_copied: "Expert copié avec succès",
    success_tags_requested: "Tags demandés avec succès !",
    success_settings_updated: "Paramètres mis à jour avec succès",
  };
  
  export default frTranslation;
  