import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import StarRatings from 'react-star-ratings';
import { mergeSimilarAccounts, fetchComment, fetchSimilarRefUsers } from '../../../Components/action/common';
import { TagsInput } from "react-tag-input-component";
import Select from 'react-select';
import { Formik, Field, Form } from 'formik';

import _ from 'lodash';

import './style.css'
import { t } from 'i18next';



function AssessmentMerge({ show, onHide, assessment, fetchAssessmentDetail, params }) {
  const { register, handleSubmit, formState: { errors } } = useForm()
  const [selectedTags, setSelectedTags] = useState([]);
  const [formInitialValues, setFormInitialValues] = useState({})
  const [similarUsers, setSimilarUsers] = useState([]);
  const [numberOfStar, setNumberOfStar] = useState(assessment?.rating)
  const [ratingNumber, setRatingNumber] = useState(assessment?.pigRating)
  const [refUserName, setRefUserName] = useState(assessment?.refUserName)
  const [commentValue, setCommentValue] = useState(null)
  const [isLoader, setIsLoader] = useState(false)


  useEffect(() => {
    setFormInitialValues({accounts: []})
    console.log(assessment, 'assessment')
    getSimilarUsers(assessment?.id);
  }, [])

  


  const getSimilarUsers = async (id) => {
    try {
      let response = await fetchSimilarRefUsers(id);
      let records = response?.data?.responseData;
      let acc = [];
      if(records && records.length > 0){
        records.map((obj, index) => {
          if(obj.groupAccount){
            acc.push(obj.id.toString());
          }
        })
        console.log({accounts: acc}, 'fff')
        setFormInitialValues({accounts: acc});
      }
      setSimilarUsers(records);
    } catch ({ response }) {

    }
  }




  const onSubmitForm = async (payload) => {
    console.log(payload, 'payload')
    try{
      setIsLoader(true)
    try {
      let response = await mergeSimilarAccounts(payload, assessment?.id)
      setIsLoader(false)
      fetchAssessmentDetail(params)
      onHide()
    } catch ({ response }) {
      setIsLoader(false)
    }
    }catch(err){

    }
  }



  console.log('similarUsers?.length', similarUsers?.length)


  return (
    <Modal className="modala fade in" style={{ display: 'block', paddingLeft: '0px' }} show={show} onHide={() => { onHide() }}>

      <div className="modal-contenth" id="changePasswordPanel">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onHide}><span aria-hidden="true"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>×</font></font></span></button>
          <h4 className="modal-title" id="assignModalLabel"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>{t("merge_accounts")}</font></font></h4>
        </div>
        <div className="modal-body formLog">
          <div className="comman_response" />
          <Formik
          initialValues={formInitialValues} 
          enableReinitialize={true} 
          onSubmit={onSubmitForm}
          >
            {({ errors, touched, values, setFieldValue }) => {
              return (
                <Form>
                  <div className="voucher_outer">
                    <div className="row">
                      <div role="group" aria-labelledby="checkbox-group">
                        <ul>
                          {
                            similarUsers?.length > 0
                              ?
                              similarUsers?.map((obj, index) => {

                                return (
                                  <li key={index}>
                                    <label>
                                      <Field type="checkbox" name="accounts" value={obj.id.toString()} />
                                      &nbsp;{obj.refUserName} ({obj.refUserEmail})
                                    </label>

                                    {/* <span>&nbsp;&nbsp;<i title="Delete" className="fa fa-trash" aria-hidden="true" onClick={() => deleteUserProduct(obj, index)}></i></span> */}


                                  </li>
                                )
                              })
                              :
                              <></>
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div >
            <button type="submit" className="btn btn-primary modelChangePasswordSave loadingbtns">{isLoader ? `${t('loading')}...` : t('save')}</button>
          </div>
                </Form>
              )
            }}
          </Formik>

          


        </div>

      </div>
    </Modal >
  )
}

export default AssessmentMerge

