import React from "react";
import SideBar from "../Dashboard/SideBar";
import { t } from "i18next";

const Welcome = () => {
  return (
    <>
      <SideBar />
      <div className="content-page">
        <div className="UserLoader" />
        <div className="content userpage">
          <div className="w-full h-full overflow-auto">
            <div className="">
              <span className="text-blue-400">{t('expert')}</span> / {t('experts_welcome_guide')}
            </div>
            <div className="mt-16 w-full flex justify-center overflow-auto">
              <div className="w-[70%] flex flex-col justify-center">
                <p className="text-[16px] text-center font-semibold">
                 {t('experts_application_guide')}
                </p>

                <div className="flex justify-center mt-6">
                  <div className="relative w-full max-w-[650px]">
                    <iframe
                      src="https://player.vimeo.com/video/810871377?h=8b772be37a"
                      width="640"
                      height="360"
                      frameborder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowfullscreen=""
                    ></iframe>
                  </div>
                </div>

                <p className="mt-10">
                 {t('experts_welcome_message1')}{" "}
                  <strong className="text-blue-400">
                    TeileDeineBotschaft.de
                  </strong>
                  . {t('experts_welcome_message2')}{" "}
                  <strong className="text-blue-400">
                    gregor@teiledeinebotschaft.de
                  </strong>
                  .{t('experts_welcome_message3')}
                </p>

                {/* <div className="flex justify-between py-4 border-t-2 mt-5">
                <span>
                  © 2024 Elevation Group GND LTD, www.teiledeinebotschaft.de
                </span>
                <span>Version: 7.24.11.26</span>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
