import React, { useEffect, useState } from "react";
import CropProductImage from "./FormElements/CropProductImage";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  getExpertData,
  postBecomeSpeaker,
  updateExpertData,
} from "../../Components/action/becomeSpeakerAction";
import CropImage from "../BecomeSpeaker/FormElements/CropImage";
import ExpertCropImage from "./FormElements/ExpertCropImage";
import { t } from "i18next";
import { fetchAllTags } from "../../Components/action/tags";
import Select from "react-select";

const socialMediaPlatforms = [
  {
    name: "Facebook",
    id: "facebook_link",
    imgSrc: "/images/becomeS/facebook_input.svg",
  },
  {
    name: "Instagram",
    id: "instagram_link",
    imgSrc: "/images/becomeS/instagram_input.svg",
  },
  {
    name: "Twitter",
    id: "twitter_link",
    imgSrc: "/images/becomeS/twitter_input.png",
  },
  {
    name: "Youtube",
    id: "youtube_link",
    imgSrc: "/images/becomeS/youtube_input.svg",
  },
  {
    name: "Vimeo",
    id: "vimeo_link",
    imgSrc: "/images/becomeS/vimeo_input.svg",
  },
  {
    name: "Pinterest",
    id: "pinterest_link",
    imgSrc: "/images/becomeS/pinterest_input.svg",
  },
  { name: "Xing", id: "xing_link", imgSrc: "/images/becomeS/xing_input.svg" },
  {
    name: "Linkedin",
    id: "linkedin_link",
    imgSrc: "/images/becomeS/linkedin_input.svg",
  },
  {
    name: "Telegram",
    id: "telegram_link",
    imgSrc: "/images/becomeS/telegram_input.svg",
  },
  {
    name: "Podcast",
    id: "podcast_link",
    imgSrc: "/images/becomeS/podcast_input.svg",
  },
  {
    name: "Spotify",
    id: "spotify_link",
    imgSrc: "/images/becomeS/spotify_input.svg",
  },
  {
    name: "Clubhouse",
    id: "clubhouse_link",
    imgSrc: "/images/becomeS/clubhouse_input.svg",
  },
  {
    name: "TikTok",
    id: "tiktok_link",
    imgSrc: "/images/becomeS/tiktok_input.svg",
  },
];

const ExpertForm = () => {
  const [formData, setFormData] = useState({
    userId: "",
    refUserDigistoreId: "",
    refUserEmail: "",
    refUserPhone: "",
    refUserName: "",
    refUserPicture: "",
    // refUserPictureAll: null,
    title: "",
    expert_short_description: "",
    expert_long_description: null,
    home_page_term: null,
    home_page_link: null,
    newsletter_term: null,
    newsletter_link: null,
    freebie_term: null,
    freebie_link: null,
    contact_email_term: null,
    contact_email_address: null,
    facebook_link: "",
    instagram_link: "",
    twitter_link: "",
    youtube_link: "",
    vimeo_link: "",
    pinterest_link: "",
    xing_link: null,
    linkedin_link: "",
    telegram_link: "",
    podcast_link: "",
    spotify_link: "",
    clubhouse_link: "",
    tiktok_link: "",
  });

  const [errors, setErrors] = useState({});
  const [expertUserData, setUserExpert] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [tagList, setTagList] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

 

  const fetchTagsListing = async (userTags) => {
    try {
      let response = await fetchAllTags();
      const tags=response?.data?.responseData?.data;
      const userTagIds = userTags?.map(tag => tag?.Tag?.id);
    
      const dd = tags?.filter((obj) => {
        return !userTagIds?.includes(obj?.id); 
      });
      setTagList(dd)
      const selected = tags?.filter(tag => userTagIds?.includes(tag.id));
      setSelectedTags(selected);

    } catch ({ response }) {}
  };

  const updateTags = (obj) => {
    setSelectedTags(obj);
  };

  const fetchUserExpert = () => {
    setLoading(true);

    getExpertData()
      .then((res) => {
        const data = res?.data?.responseData?.data[0];
        setUserExpert(data);
        fetchTagsListing(data?.RefUserTags)

        setFormData({
          userId: data.userId || "",
          refUserDigistoreId: data.displayDigistoreId || "",
          refUserEmail: data.refUserEmail || "",
          refUserPhone: data.refUserPhone || "",
          refUserName: data.refUserName || "",
          refUserPicture: data.refUserPicture || "",
          // refUserPictureAll: data.refUserPictureAll || null,
          title: data.title || "",
          expert_short_description: data.expert_short_description || "",
          expert_long_description: data.expert_long_description || null,
          home_page_term: data.home_page_term || null,
          home_page_link: data.home_page_link || null,
          newsletter_term: data.newsletter_term || null,
          newsletter_link: data.newsletter_link || null,
          freebie_term: data.freebie_term || null,
          freebie_link: data.freebie_link || null,
          contact_email_term: data.contact_email_term || null,
          contact_email_address: data.contact_email_address || null,
          facebook_link: data.facebook_link || "",
          instagram_link: data.instagram_link || "",
          twitter_link: data.twitter_link || "",
          youtube_link: data.youtube_link || "",
          vimeo_link: data.vimeo_link || "",
          pinterest_link: data.pinterest_link || "",
          xing_link: data.xing_link || null,
          linkedin_link: data.linkedin_link || "",
          telegram_link: data.telegram_link || "",
          podcast_link: data.podcast_link || "",
          spotify_link: data.spotify_link || "",
          clubhouse_link: data.clubhouse_link || "",
          tiktok_link: data.tiktok_link || "",
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchUserExpert();
    
  }, []);

  const addUpdateProductPicture = (croppedImage) => {
    setFormData((prev) => ({
      ...prev,
      refUserPicture: croppedImage,
    }));
  };

  const linkUrlInput = [
    {
      label: t("home_page_term"),
      tooltip1: t("home_page_link_tooltip1"),
      tooltip2: t("home_page_term"),
      icon: "/images/becomeS/homepageLink.svg",
      inputId: "homepage_display_term",
      inputName: "home_page_term",
      value: formData.home_page_term,
      errorClass: "error_home_page_term",
      urlLabel: t("home_page_link"),
      urlId: "homepage_link_url",
      urlName: "home_page_link",
      urlValue: formData.home_page_link,
      urlErrorClass: "error_home_page_link",
    },
    {
      label: t("news_letter_term"),
      tooltip1: t("news_letter_link_tooltip1"),
      tooltip2: t("news_letter_term"),
      icon: "/images/becomeS/newsletterLink.svg",
      inputId: "newsletter_display_term",
      inputName: "newsletter_term",
      value: formData.newsletter_term,
      errorClass: "error_newsletter_term",
      urlLabel: t("news_letter_link"),
      urlId: "newsletter_link_url",
      urlName: "newsletter_link",
      urlValue: formData.newsletter_link,
      urlErrorClass: "error_newsletter_link",
    },
    {
      label: t("freebie_term"),
      tooltip1: t("freebie_link_tooltip1"),
      tooltip2: t("freebie_term"),
      icon: "/images/becomeS/freebieLink.svg",
      inputId: "freebie_display_term",
      inputName: "freebie_term",
      value: formData.freebie_term,
      errorClass: "error_freebie_term",
      urlLabel: t("freebie_link"),
      urlId: "freebie_link_url",
      urlName: "freebie_link",
      urlValue: formData.freebie_link,
      urlErrorClass: "error_freebie_link",
    },
    {
      label: t("contact_email_term"),
      tooltip1: t("contact_email_tooltip1"),
      tooltip2: t("contact_email_term"),
      icon: "/images/becomeS/contact_email.svg",
      inputId: "contact_display_term",
      inputName: "contact_email_term",
      value: formData.contact_email_term,
      errorClass: "error_contact_email_term",
      urlLabel: t("contact_email"),
      urlId: "contact_email",
      urlName: "contact_email_address",
      urlValue: formData.contact_email_address,
      urlErrorClass: "error_contact_email_address",
    },
  ];

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const updateProfilePicture = (croppedImage) => {
    setFormData((prev) => ({
      ...prev,
      user_profile_picture: croppedImage,
    }));
  };

  const validateForm = () => {
    let errorList = {};
    let isValid = true;
    // if (!formData.first_name) {
    //   isValid = false;
    //   errorList.first_name = t("first_name_required");
    // }
    // if (!formData.last_name) {
    //   isValid = false;
    //   errorList.last_name = t("error_last_name_required");
    // }
    if (!formData.title) {
      isValid = false;
      errorList.title = t("error_title_required");
    }
    if (!formData.refUserName) {
      isValid = false;
      errorList.refUserName = t("first_name_required");
    }

    if (!formData.refUserDigistoreId) {
      isValid = false;
      errorList.refUserDigistoreId = t("digistore_id_required");
    }
    if (!formData.refUserPicture) {
      isValid = false;
      errorList.refUserPicture = t("profile_image_required");
    }

    if (!formData.refUserEmail) {
      isValid = false;
      errorList.refUserEmail = t("email_address_required");
    } else if (!/\S+@\S+\.\S+/.test(formData.refUserEmail)) {
      isValid = false;
      errorList.refUserEmail = t("valid_email_required");
    }
    setErrors(errorList);
    return isValid;
  };

  const submitFormApplication = async () => {
    if (validateForm()) {
      let dataToSend = { ...formData };
      delete dataToSend?.userId;
      console.log(dataToSend, "dddd");
      dataToSend.tags=selectedTags?.map(item=>item?.id)
      const response = await updateExpertData(dataToSend);
      if (response) {
        const data= response?.data?.responseData?.updatedRecord
        setFormData({
          userId: data.userId || "",
          refUserDigistoreId: data.displayDigistoreId || "",
          refUserEmail: data.refUserEmail || "",
          refUserPhone: data.refUserPhone || "",
          refUserName: data.refUserName || "",
          refUserPicture: data.refUserPicture || "",
          // refUserPictureAll: data.refUserPictureAll || null,
          title: data.title || "",
          expert_short_description: data.expert_short_description || "",
          expert_long_description: data.expert_long_description || null,
          home_page_term: data.home_page_term || null,
          home_page_link: data.home_page_link || null,
          newsletter_term: data.newsletter_term || null,
          newsletter_link: data.newsletter_link || null,
          freebie_term: data.freebie_term || null,
          freebie_link: data.freebie_link || null,
          contact_email_term: data.contact_email_term || null,
          contact_email_address: data.contact_email_address || null,
          facebook_link: data.facebook_link || "",
          instagram_link: data.instagram_link || "",
          twitter_link: data.twitter_link || "",
          youtube_link: data.youtube_link || "",
          vimeo_link: data.vimeo_link || "",
          pinterest_link: data.pinterest_link || "",
          xing_link: data.xing_link || null,
          linkedin_link: data.linkedin_link || "",
          telegram_link: data.telegram_link || "",
          podcast_link: data.podcast_link || "",
          spotify_link: data.spotify_link || "",
          clubhouse_link: data.clubhouse_link || "",
          tiktok_link: data.tiktok_link || "",
        });
        toast.success(t("updated_user_successfully"));
      }

      // fetchUserExpert();
    } else {
      return;
    }
  };

  const productInputChange = (e) => {
    const { name, value } = e.target;

    const [bName, title, index] = name.split("_");
    const baseName = bName + "_" + title;

    setFormData((prevState) => {
      const updatedField = { ...prevState[baseName] };
      updatedField[`${baseName}_${index}`] = value;

      return {
        ...prevState,
        [baseName]: updatedField,
      };
    });
  };

  const updateProductPicture = (croppedImage, index) => {
    setFormData((prevState) => {
      const updatedImages = { ...prevState.product_featured_image };
      updatedImages[`p${index}`] = croppedImage;

      return {
        ...prevState,
        product_featured_image: updatedImages,
      };
    });
  };

  const uniqueErrors = [...new Set(Object.values(errors))];
  const handleRemoveImage = () => {
    setFormData((prev) => ({
      ...prev,
      refUserPicture: "",
    }));
  };

  return (
    <article className="flex flex-col gap-10 mt-20">
      {loading ? (
        <div className="loading">{t("loading")}...</div>
      ) : (
        <>
          <div className="flex flex-wrap xl:flex-nowrap gap-[24px] px-4">
            <div className="flex flex-col w-full xl:w-[50%]">
              <label htmlFor="title">{t("title_expert_eg")}</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                className="form-control "
              />
              {errors?.title && (
                <div className="text-red-500 text-[12px] mt-1">
                  {errors?.title}
                </div>
              )}
            </div>

            <div className="flex flex-col w-full xl:w-[50%]">
              <label htmlFor="refUserName">{t("full_name")}</label>
              <input
                type="text"
                id="refUserName"
                name="refUserName"
                value={formData.refUserName}
                onChange={handleInputChange}
                className="form-control "
              />
              {errors?.refUserName && (
                <div className="text-red-500 text-[12px] mt-1 error_refUserName">
                  {errors?.refUserName}
                </div>
              )}
            </div>

            {/* <div className="flex flex-col w-full">
              <label htmlFor="title">{t("title_expert_eg")}</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                className="form-control "
              />
              {errors?.title && (
                <div className="text-red-500 text-[12px] mt-1">
                  {errors?.title}
                </div>
              )}
            </div>

            <div className="flex flex-col w-full">
              <label htmlFor="first_name">{t("first_name")}</label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                className="form-control input-lg"
              />
              {errors?.first_name && (
                <div className="text-red-500 text-[12px] mt-1 error_first_name">
                  {errors?.first_name}
                </div>
              )}
            </div>

            <div className="flex flex-col w-full">
              <label htmlFor="last_name">{t("last_name")}</label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                className="form-control input-lg"
              />
              {errors?.last_name && (
                <div className="text-red-500 text-[12px] mt-1 error_first_name">
                  {errors?.last_name}
                </div>
              )}
            </div> */}
          </div>

          <div className="flex flex-wrap xl:flex-nowrap gap-[24px] px-4">
            <div className="flex flex-col w-full xl:w-[50%] relative">
              <label
                htmlFor="expert_short_description"
                className="flex items-center"
              >
                {t("expert_short_description_40")}
                <span
                  data-tooltip-id="tooltip-right"
                  data-tooltip-place="right"
                  className="ml-2 text-blue-600 cursor-pointer tooltipForm"
                >
                  <img
                    src="/images/becomeS/question.png"
                    className="w-[20px] h-[20px] "
                  />
                  <span className="tooltiptext">
                    {t("expert_short_description_tooltip")}
                  </span>
                </span>
              </label>

              <input
                type="text"
                id="expert_short_description"
                name="expert_short_description"
                maxLength="40"
                value={formData.expert_short_description}
                onChange={handleInputChange}
                className="form-control "
              />
              <div className="text-red-500 text-[12px] mt-1 error_expert_short_description"></div>
            </div>

            <div className="flex flex-col w-full xl:w-[50%]">
              <label htmlFor="refUserDigistoreId" className="flex items-center">
                Digistore-ID
                <span
                  // data-toggle="popover"
                  data-tooltip-id="tooltip-right"
                  data-tooltip-place="right"
                  className="ml-2 text-blue-600 cursor-pointer tooltipForm"
                >
                  <img
                    src="/images/becomeS/question.png"
                    className="w-[20px] h-[20px]"
                  />

                  <span className="tooltiptext">
                    {t("digistore_id_tooltip")}
                  </span>
                </span>
              </label>
              <input
                type="text"
                id="refUserDigistoreId"
                name="refUserDigistoreId"
                className="form-control "
                value={formData.refUserDigistoreId}
                onChange={handleInputChange}
              />
              {errors?.refUserDigistoreId && (
                <div className="text-red-500 text-[12px] mt-1">
                  {errors?.refUserDigistoreId}
                </div>
              )}
            </div>
          </div>

          <div className="w-full px-4">
            <label className="">
              <font style={{ verticalAlign: "inherit" }}>{t("tags")}</font>
            </label>
            <Select
              placeholder={`${t("select")}...`}
              getOptionLabel={(obj) => obj.name}
              getOptionValue={(obj) => obj.id}
              closeMenuOnSelect={false}
              value={selectedTags}
              isMulti
              options={tagList}
              onChange={(obj) => {
                updateTags(obj);
              }}
            />
          </div>

          <div className="flex flex-col px-4">
            <label htmlFor="refUserEmail" className="flex items-center">
              {t("email_address_of_expert")}
              <span
                data-tooltip-id="tooltip-right"
                data-tooltip-place="right"
                className="ml-2 text-blue-600 cursor-pointer tooltipForm"
              >
                <img
                  src="/images/becomeS/question.png"
                  className="w-[20px] h-[20px] "
                />
                <span className="tooltiptext">
                  {" "}
                  {t("email_address_of_expert_tooltip")}
                </span>
              </span>
            </label>
            <input
              type="email"
              id="refUserEmail"
              name="refUserEmail"
              value={formData.refUserEmail}
              onChange={handleInputChange}
              className="form-control "
            />
            {errors?.refUserEmail && (
              <div className="text-red-500 text-[12px] mt-1">
                {errors?.refUserEmail}
              </div>
            )}
          </div>

          <div className="flex flex-col px-4 ">
            <label htmlFor="refUserPhone" className="">
              {t("mobile_number")}
            </label>
            <input
              type="text"
              id="refUserPhone"
              name="refUserPhone"
              value={formData.refUserPhone}
              onChange={handleInputChange}
              className="form-control "
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            <div className="text-red-500 text-[12px] mt-1 error_refUserPhone"></div>
          </div>

          <div className="flex flex-col px-4">
            {formData.refUserPicture ? (
              <div className="mb-2 relative w-[148px] h-[148px]">
                {/* Image */}
                <img
                  src={formData.refUserPicture}
                  alt="Cropped Preview"
                  className="w-full h-full object-cover rounded border border-gray-300"
                />

                <img
                  className="absolute top-1 right-1 w-10 h-10 p-2 bg-white rounded-full hover:cursor-pointer hover:scale-105"
                  title="Delete"
                  src="./images/delete.png"
                  alt="Delete Icon"
                  onClick={handleRemoveImage}
                />
              </div>
            ) : (
              <ExpertCropImage
                addUpdateProductPicture={addUpdateProductPicture}
              />
            )}

            {errors?.refUserPicture && (
              <div className="text-red-500 text-[12px] mt-1 error_refUserName">
                {errors?.refUserPicture}
              </div>
            )}

            {/* <CropImage updateProfilePicture={updateProfilePicture} /> */}
          </div>

          <div className="flex flex-col px-4">
            <label
              htmlFor="expert_long_description"
              className="flex items-center"
            >
              {t("profile_description")}
              <span
                data-tooltip-id="tooltip-right"
                data-tooltip-place="right"
                className="ml-2 text-blue-600 cursor-pointer tooltipForm"
              >
                <img
                  src="/images/becomeS/question.png"
                  className="w-[20px] h-[20px] "
                />
                <span className="tooltiptext">
                  {t("profile_description_tooltip")}
                </span>
              </span>
            </label>
            <textarea
              type="text"
              id="expert_long_description"
              name="expert_long_description"
              value={formData.expert_long_description}
              onChange={handleInputChange}
              className="form-control "
            ></textarea>
            <div className="text-red-500 text-[12px] mt-1 error_expert_long_description"></div>
          </div>

          <div className="flex flex-col px-4 gap-7">
            <label className="mb-2 flex">
              {t("direct_links")}{" "}
              <span
                data-tooltip-id="tooltip-right"
                data-tooltip-place="right"
                className="ml-2 text-blue-600 cursor-pointer tooltipForm"
              >
                <img
                  src="/images/becomeS/question.png"
                  className="w-[20px] h-[20px] "
                />
                <span className="tooltiptext">{t("direct_links_tooltip")}</span>
              </span>
            </label>

            {linkUrlInput.map((field, index) => (
              <div key={index} className="flex gap-16 flex-wrap lg:flex-nowrap">
                <div className="flex items-center gap-7 w-full lg:w-[400px]">
                  <img className="h-[27px] w-[27px] mt-9" src={field.icon} />
                  <div className="flex flex-col w-full">
                    <label
                      htmlFor={field.inputId}
                      className="flex items-center"
                    >
                      {field.label}
                      <span
                        data-tooltip-id="tooltip-right"
                        data-tooltip-place="right"
                        className="ml-2 text-blue-600 cursor-pointer tooltipForm"
                      >
                        <img
                          src="/images/becomeS/question.png"
                          className="w-[20px] h-[20px]"
                        />
                        <span className="tooltiptext">{field.tooltip2}</span>
                      </span>
                    </label>
                    <input
                      type="text"
                      id={field.inputId}
                      name={field.inputName}
                      value={field.value}
                      onChange={handleInputChange}
                      className="form-control "
                    />
                    <div
                      className={`text-red-500 text-[12px] mt-1 ${field.errorClass}`}
                    ></div>
                  </div>
                </div>

                <div className="flex flex-col w-full lg:w-[70%] ml-16 lg:ml-0 ">
                  <label htmlFor={field.urlId} className="flex items-center">
                    {field.urlLabel}
                    <span
                      data-tooltip-id="tooltip-right"
                      data-tooltip-place="right"
                      className="ml-2 text-blue-600 cursor-pointer tooltipForm"
                    >
                      <img
                        src="/images/becomeS/question.png"
                        className="w-[20px] h-[20px]"
                      />
                      <span className="tooltiptext">{field.tooltip1}</span>
                    </span>
                  </label>
                  <input
                    type="text"
                    id={field.urlId}
                    name={field.urlName}
                    value={field.urlValue}
                    onChange={handleInputChange}
                    className="form-control "
                  />
                  <div
                    className={`text-red-500 text-[12px] mt-1 ${field.urlErrorClass}`}
                  ></div>
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-col px-4 gap-7">
            <label className="mb-2 flex">
              {t("social_links")}{" "}
              <span
                data-tooltip-id="tooltip-right"
                data-tooltip-place="right"
                className="ml-2 text-blue-600 cursor-pointer tooltipForm"
              >
                <img
                  src="/images/becomeS/question.png"
                  className="w-[20px] h-[20px] "
                />
                <span className="tooltiptext">{t("social_links_tooltip")}</span>
              </span>
            </label>

            {socialMediaPlatforms.map((platform) => (
              <div className="flex items-center gap-7 w-full" key={platform.id}>
                <img
                  className="h-[27px] w-[27px] mt-9"
                  src={platform.imgSrc}
                  alt={platform.name}
                />
                <div className="flex flex-col w-full">
                  <label htmlFor={platform.id} className="flex items-center">
                    {platform.name}
                  </label>
                  <input
                    type="text"
                    id={platform.id}
                    name={platform.id}
                    value={formData[platform.id] || ""}
                    onChange={handleInputChange}
                    className="form-control "
                    required
                  />
                  <div
                    className={`text-red-500 text-[12px] mt-1 error_${platform.id}`}
                  ></div>
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-center px-10">
            <button
              className="text-white border-none rounded-lg bg-blue-500 py-4 font-semibold"
              onClick={submitFormApplication}
            >
              <span>{t("save_settings")}</span>
              <span className="ml-2">&gt;&gt;</span>
            </button>
            {/* <button
          className="bg-red-600 border-none text-white rounded-lg py-4 font-semibold"
          onClick={submitFormApplication}
        >
          <span>Delete expert profile</span>
          <span className="ml-2">&gt;&gt;</span>
        </button> */}
          </div>

          {/* {Object.keys(errors).length > 0 && (
        <div className="text-red-500 text-[12px]">
          {Object.values(errors).map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )} */}

          {uniqueErrors.length > 0 && (
            <div className="text-red-500 text-[12px]">
              {uniqueErrors.map((error, index) => (
                <p key={index}>{error}</p>
              ))}
            </div>
          )}
        </>
      )}
    </article>
  );
};

export default ExpertForm;
