import { postRefreshToken } from '../Components/action/refreshTokenAction';

export const refreshTokenAPI = async (refreshToken) => {
  try {
    const response = await postRefreshToken({refreshToken});
    return response; 
  } catch (error) {
    throw error;
  }
};
