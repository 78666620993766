import React from "react";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  KONGRESS_ATUH_KEY,
  COPY_KONGRESS_END_POINT,
} from "../../../Components/Common/constants";
import StarRatings from "react-star-ratings";
import { addNewAssesmentUser } from "../../../Components/action/common";
import { TagsInput } from "react-tag-input-component";
import Select from "react-select";
import _ from "lodash";
import axios from "axios";
import "./style.css";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import { validationSchema } from "./editschema";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ExpertCropImageExpert from "../formImageExpert/ExpertCropImageExpert";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
// import * as Yup from 'yup';

function AddNewExpertModalNew({
  show,
  onHide,
  assessment,
  fetchAssessmentDetail,
  params,
  loggedInData,
}) {
  const [isLoader, setIsLoader] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({});
  const [imagePath, setImagePath] = useState(null);
  const [errors, setErrors] = useState({});

  const validateForm = (formData) => {
    let errorList = {};
    let isValid = true;

    if (!formData.title) {
      isValid = false;
      errorList.title = t("error_title_required");
    }

    if (!formData.refUserName) {
      isValid = false;
      errorList.refUserName = t("name_is_required");
    }
    if (!formData.refUserDigistoreId) {
      isValid = false;
      errorList.refUserDigistoreId = t("digistore_id_required");
    }

    if (!formData.refUserEmail) {
      isValid = false;
      errorList.refUserEmail = t("email_address_required");
    } else if (!/\S+@\S+\.\S+/.test(formData.refUserEmail)) {
      isValid = false;
      errorList.refUserEmail = t("valid_email_required");
    }

    setErrors(errorList);
    return isValid;
  };

  const addUpdateProductPicture = (croppedImage) => {
    setImagePath(croppedImage);
  };

  const onSubmit = async (formData) => {
    if (validateForm(formData)) {
      if (imagePath) {
        formData.refUserPicture = imagePath;
      }
      try {
        let response = await addNewAssesmentUser(formData);
        setIsLoader(false);
        fetchAssessmentDetail(params);
        onHide();
      } catch ({ response }) {
        setIsLoader(false);
      }
    }
  };

  return (
    <Modal
      className="modal copy_modal"
      show={show}
      onHide={() => {
        onHide();
      }}
    >
      <div className="modal-lg">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <span aria-hidden="true">
              <font style={{ verticalAlign: "inherit" }}>
                <font style={{ verticalAlign: "inherit" }}>×</font>
              </font>
            </span>
          </button>
          <h4 className="modal-title" id="assignModalLabel">
            <font style={{ verticalAlign: "inherit" }}>
              <font style={{ verticalAlign: "inherit" }}>
                {t("add_new_expert")}
              </font>
            </font>
          </h4>
        </div>
        <div className="modal-body formLog">
          <div className="comman_response" />
          <Formik
            enableReinitialize={true}
            initialValues={formInitialValues}
            // validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ touched, values, setFieldValue }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="col-lg-12 form-group">
                      <label className="control-label" htmlFor="digistorId">
                        Digistore ID
                      </label>
                      <Field
                        id="digistorId"
                        name="refUserDigistoreId"
                        placeholder=""
                        className="form-control"
                      />
                      {errors.refUserDigistoreId ? (
                        <div className="error">{errors.refUserDigistoreId}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <label className="control-label" htmlFor="refUserPhone">
                        {t("mobile_number")}
                      </label>
                      <Field
                        id="refUserPhone"
                        name="refUserPhone"
                        placeholder=""
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-6 form-group">
                      <label className="control-label" htmlFor="lastName">
                        {t("email")}
                      </label>
                      <Field
                        id="refUserEmail"
                        name="refUserEmail"
                        className="form-control"
                      />
                      {errors.refUserEmail ? (
                        <div className="error">{errors.refUserEmail}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <label className="control-label" htmlFor="title">
                        {t("title_expert_eg")}
                      </label>
                      <Field id="title" name="title" className="form-control" />

                      {errors.title ? (
                        <div className="error">{errors.title}</div>
                      ) : null}
                    </div>
                    {/* <div className="col-lg-4 form-group">
                      <label className="control-label" htmlFor="first_name">
                        {t("first_name")}
                      </label>
                      <Field
                        id="first_name"
                        name="first_name"
                        className="form-control"
                      />
                      {errors.first_name ? (
                        <div className="error">{errors.first_name}</div>
                      ) : null}
                    </div>
                    <div className="col-lg-4 form-group">
                      <label className="control-label" htmlFor="last_name">
                        {t("last_name")}
                      </label>
                      <Field
                        id="last_name"
                        name="last_name"
                        className="form-control"
                      />
                      {errors.last_name ? (
                        <div className="error">{errors.last_name}</div>
                      ) : null}
                    </div> */}

                    <div className="col-lg-6 form-group">
                      <label className="control-label" htmlFor="refUserName">
                        {t("name")}
                      </label>
                      <Field
                        id="refUserName"
                        name="refUserName"
                        className="form-control"
                      />
                      {errors.refUserName ? (
                        <div className="error">{errors.refUserName}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 form-group">
                      <label className="control-label" htmlFor="refUserPicture">
                        {t("photo")}
                      </label>
                      <ExpertCropImageExpert
                        addUpdateProductPicture={addUpdateProductPicture}
                      />
                      {errors.refUserPicture ? (
                        <div className="error">{errors.refUserPicture}</div>
                      ) : null}
                    </div>
                  </div>

                  <fieldset>
                    <legend>{t("links")}</legend>

                    <div className="row">
                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="home_page_term"
                        >
                          {t("home_page_term")}
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="home_page_term"
                          name="home_page_term"
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <label className="control-label" htmlFor="homePageLink">
                          {t("home_page_link")}
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="homePageLink"
                          name="home_page_link"
                        />
                      </div>

                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="newsletterTerm"
                        >
                          {t("news_letter_term")}
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="newsletterTerm"
                          name="newsletter_term"
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="newsletterLink"
                        >
                          {t("news_letter_link")}
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="newsletterLink"
                          name="newsletter_link"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 form-group">
                        <label className="control-label" htmlFor="freebieTerm">
                          {t("freebie_term")}
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="freebieTerm"
                          name="freebie_term"
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <label
                          disabled={false}
                          className="control-label"
                          htmlFor="freebieLink"
                        >
                          {t("freebie_link")}
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="freebieLink"
                          name="freebie_link"
                        />
                      </div>

                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="contactEmailTerm"
                        >
                          {t("contact_email_term")}
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="contactEmailTerm"
                          name="contact_email_term"
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="contactEmailAddress"
                        >
                          {t("contact_email")}
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="contactEmailAddress"
                          name="contact_email_address"
                        />
                      </div>
                    </div>
                  </fieldset>

                  <fieldset>
                    <legend>{t("social_media_links")}</legend>
                    <div className="row">
                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="facebookLink">
                          Facebook link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="facebookLink"
                          name="facebook_link"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <label
                          className="control-label"
                          htmlFor="instagramLink"
                        >
                          Instagram link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="instagramLink"
                          name="instagram_link"
                        />
                      </div>

                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="twitterLink">
                          Twitter link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="twitterLink"
                          name="twitter_link"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="youtubeLink">
                          Youtube link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="youtubeLink"
                          name="youtube_link"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="vimeoLink">
                          Vimeo link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="vimeoLink"
                          name="vimeo_link"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <label
                          className="control-label"
                          htmlFor="pinterestLink"
                        >
                          Pinterest link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="pinterestLink"
                          name="pinterest_link"
                        />
                      </div>

                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="xingLink">
                          Xing link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="xingLink"
                          name="xing_link"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="linkedinLink">
                          Linkedin link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="linkedinLink"
                          name="linkedin_link"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="telegramLink">
                          Telegram link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="telegramLink"
                          name="telegram_link"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="podcastLink">
                          Podcast link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="podcastLink"
                          name="podcast_link"
                        />
                      </div>

                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="spotifyLink">
                          Spotify link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="spotifyLink"
                          name="spotify_link"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <label
                          className="control-label"
                          htmlFor="clubhouseLink"
                        >
                          Clubhouse link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="clubhouseLink"
                          name="clubhouse_link"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 form-group">
                        <label className="control-label" htmlFor="tiktokLink">
                          Tiktok link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="tiktokLink"
                          name="tiktok_link"
                        />
                      </div>
                    </div>
                  </fieldset>

                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <label
                        className="control-label"
                        htmlFor="expert_short_description"
                      >
                        {t("expert_short_description")}
                      </label>
                      <Field
                        disabled={false}
                        component="textarea"
                        className="form-control"
                        id="expert_short_description"
                        name="expert_short_description"
                      />
                    </div>

                    <div className="col-lg-6 form-group">
                      <label
                        className="control-label"
                        htmlFor="expert_long_description"
                      >
                        {t("expert_long_description")}
                      </label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={values?.expert_long_description}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          //console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          setFieldValue(
                            "expert_long_description",
                            editor.getData()
                          );
                          //console.log(editor.getData());
                        }}
                        onBlur={(event, editor) => {
                          //console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                          //console.log('Focus.', editor);
                        }}
                        disabled={false}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 form-group ">
                      <button
                        type="submit"
                        className="pull-right btn btn-primary loadingbtns"
                      >
                        {isLoader ? `${t("loading")}..` : t("save")}
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}

export default AddNewExpertModalNew;
