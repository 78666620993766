import axios from "axios";


export const postMakeExpert = (props) => {
    return axios({
        method: 'POST',
        url: '/user/makeExpert',
        data:props
    })
}
