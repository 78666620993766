import React from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { changeUserPassword } from '../../../Components/action/common'
import { t } from 'i18next'

export default function UserEdit({ show, onHide , isEditData }) {

  const { register,handleSubmit , formState:{errors}}=useForm()

  const onChangePassword=async(data)=>{
    let requestBody={
      "id":isEditData?.id,
      "password":data?.password
    }
    try {
      let reasponse=await changeUserPassword(requestBody)
      onHide()
    } catch ({response}) {
      
    }
  }

  return (
    <Modal className="modala fade in"  style={{display: 'block', paddingLeft: '0px'}} show={show} onHide={() => { onHide() }}>
    
      <div className="modal-contenth" id="changePasswordPanel">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onHide}><span aria-hidden="true"><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>×</font></font></span></button>
          <h4 className="modal-title" id="assignModalLabel"><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>{t('change_password')}</font></font></h4>
        </div>
        <div className="modal-body formLog">
          <div className="comman_response" />
          <form  className="changePasswordForm" onSubmit={handleSubmit(onChangePassword)}>  
            <div className="voucher_outer">
              <div className="row">
                <input type="hidden" name="_token" />
                <div className="form-group">
                  <div className="col-sm-12">
                    <label className="control-label"><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>{t('password')}</font></font></label>
                    <input type="password" className="form-control input-lg"  {...register("password",{
                      required:{
                        value:true,
                        password:"Password is required"
                      }
                    })}/>
                  </div>
                  <div className="error error_new_name" style={{color:'red'}} >{errors?.password?.message}</div>
                </div>
              </div>
            </div>   
            <div className="modal-footer">
          <button type="submit" className="btn btn-primary modelChangePasswordSave loadingbtns">{t('save')}</button>
          {/* <button type="button" className="btn btn-default" id="changePasswordCancel" onClick={onHide}><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Cancel</font></font></button> */}
        </div>                              
    
          </form>
        </div>
        
      </div>
  </Modal>
  )
}
