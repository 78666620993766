import { t } from "i18next";
import React from "react";

const ThanksModal = ({ name, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative bg-green-300 w-11/12 md:w-2/3 lg:w-1/2 rounded-lg p-6 shadow-lg">
        <div className="absolute -top-6 -left-6 bg-green-500 z-50 text-white rounded-full w-14 h-14 flex items-center justify-center shadow-lg">
          <img src="/images/becomeS/check.svg" className="h-6 object-contain" alt="Success Icon" />
        </div>

        <button
          className="absolute top-3 right-3 text-black hover:text-gray-700 text-2xl"
          onClick={onClose}
        >
          &times;
        </button>

        <h2 className="text-3xl font-bold text-center text-black mb-2">
          {t('thank_you')}, {name}
        </h2>
        <p className="text-center text-black mb-6">
         {t('thank_you_recieve')}
        </p>

        <div className="bg-white rounded-lg p-4 shadow-md flex items-center">
          {/* <div className="mr-4">
            <img
              src="https://via.placeholder.com/100"
              alt="User"
              className="w-[150px] h-[150px] rounded-md object-cover"
            />
            <div className="flex items-center mt-2 justify-between">
              {Array.from({ length: 5 }).map((_, i) => (
                <svg
                  key={i}
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10 text-yellow-500"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 .587l3.668 7.431 8.175 1.194-5.909 5.757 1.394 8.131L12 18.763l-7.328 3.837 1.394-8.131-5.909-5.757 8.175-1.194z" />
                </svg>
              ))}
            </div>
          </div> */}

          <div>
            <p className="text-black mb-2">
              {t('thank_you_review')}
            </p>
          </div>
        </div>

        <p className="text-center text-black mt-6 font-semibold">
         {t('your_kongress_team')}
        </p>
      </div>
    </div>
  );
};

export default ThanksModal;
