import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import {
  changeUserPassword,
  deleteUser,
  UseList,
  updateUser,
} from "../../Components/action/common";
import AddUser from "./Modal/AddUser";
import UserEdit from "./Modal/UserEdit";
import SideBar from "./SideBar";
import TopHeader from "./TopHeader";
import { useDispatch, useSelector } from "react-redux";
//import { setUserListData } from '../../redux/slices/admin'
import { Fragment } from "react";
import { authentication } from "../../Components/action/utilities";
import Pagination from "react-js-pagination";
import { Navigate, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import DeleteUser from "./Modal/DeleteUser";
import _ from "lodash";
import Switch from "react-switch";
import { t } from "i18next";

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addUserModal, setAddUserModal] = useState(false);
  const [useEditModal, setUserEditModal] = useState(false);
  const [isEditData, setIsEditData] = useState(null);
  const [isDeletble, setIsDeletable] = useState(false);
  const [deletableData, setDeletableData] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [isCheckedStatus, setIsCheckedState] = useState([]);
  const [userListData, setUserListData] = useState([]);
  const [timer, setTimer] = useState(null);

  const [expandedDomains, setExpandedDomains] = useState({});

  const [params, setParams] = useState({
    pageNumber: 1,
    sortOrder: "desc",
    sortBy: "id",
  });

  // global state
  //const { userListData } = useSelector(state => state.admin)

  const fetchUserList = async (params) => {
    try {
      setIsFetching(true);
      //let response = await UseList(params);
      let response = await axios.get(
        `/users`,
        { params: params } //Pass the cancel token to the current request
      );
      setIsFetching(false);
      setUserListData(response?.data?.responseData);
    } catch ({ response }) {
      // response.status === 401 && navigate("/");
      // response.status === 401 && localStorage.clear();

      setIsFetching(false);
    }
  };

  const onFilterUserList = async (value) => {
    let updatedParams = Object.assign(params, {
      searchText: value,
      pageNumber: 1,
    });
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      fetchUserList(updatedParams);
    }, 500);
    setTimer(newTimer);
    setParams(updatedParams);
  };

  const onSetUserRole = async (value) => {
    let updatedParams = Object.assign(params, { role: value, pageNumber: 1 });
    fetchUserList(updatedParams);
    setParams(updatedParams);
  };

  const onSetUserStatus = async (value) => {
    let updatedParams = JSON.parse(JSON.stringify(params));
    if (value == 0) {
      delete updatedParams.status;
    } else {
      updatedParams = Object.assign(updatedParams, {
        status: value,
        pageNumber: 1,
      });
    }

    fetchUserList(updatedParams);
    setParams(updatedParams);
  };

  useEffect(() => {
    fetchUserList(params);
  }, []);

  const handleStatusChange = async (value, id, index) => {
    try {
      let response = await updateUser({ status: value ? 1 : 0, id: id });
      //dispatch(setUserListData(response?.data?.responseData?.data));
      let uData = JSON.parse(JSON.stringify(userListData));
      console.log(uData, "uuuuuuu");
      uData.data[index] = Object.assign(uData.data[index], {
        status: value ? 1 : 0,
      });
      console.log(uData, "uuuuuuu111");
      setUserListData(uData);
    } catch ({ response }) {
      // response.status === 401 && navigate("/");
      // response.status === 401 && localStorage.clear();
    }
  };

  if (!authentication()) {
    return <Navigate to={"/"} />;
  }

  const handlePageChange = (pageNumber) => {
    let localParams = params;
    localParams = Object.assign(localParams, { pageNumber: pageNumber });
    setParams(localParams);
    fetchUserList(localParams);
  };

  const handleExpandDomainClick = (id) => {
    setExpandedDomains((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const formatDomain = (tagString, id) => {
    if (tagString) {
      const newArray = tagString.split("\n");

      const dataArray = newArray.filter((item) => item !== "" && item !== null);
      return dataArray.map((domain, index) => {
        if (
          !expandedDomains[id] &&
          index > 1 &&
          index !== dataArray.length - 1
        ) {
          return null;
        } else if (
          !expandedDomains[id] &&
          index > 2 &&
          index === dataArray.length - 1
        ) {
          return (
            <span
              title={tagString}
              className="more_domain"
              onClick={() => handleExpandDomainClick(id)}
              key={index}
            >
              {" +"}
              {dataArray.length - 2} {"more"}
            </span>
          );
        }

        const re = new RegExp("^(http|https)://", "i");
        const redirectLink = re.test(domain) ? domain : "https://" + domain;

        return (
          <span className="tag_wrapper" key={index}>
            <a href={redirectLink} target="_blank" rel="noopener noreferrer">
              {domain}
            </a>
          </span>
        );
      });
    }
    return null;
  };

  // const formatDomain = (tagString) => {
  //   if (tagString) {
  //     let dataArray = tagString.split("\n");
  //     let tags = "";
  //     let redirectLink = "";
  //     let spans = dataArray.map((obj, index) => {
  //       if (index > 1 && index != dataArray.length - 1) {
  //         return;
  //       } else if (index > 2 && index == dataArray.length - 1) {
  //         return (
  //           <span
  //             title={tagString}
  //             className="more_domain"
  //             onClick={() => setExpandViewDomainFlag(true)}
  //           >
  //             {" +"}
  //             {dataArray.length - 2} {"more"}
  //           </span>
  //         );
  //       }

  //       let re = new RegExp("^(http|https)://", "i");
  //       let match = re.test(obj);

  //       console.log(match, '++++++++', obj);
  //       if (match == false) {
  //         redirectLink = "https://" + obj;
  //       } else {
  //         redirectLink = obj;
  //       }

  //       return (
  //         <span className="tag_wrapper">
  //           <a href={redirectLink} target="_blank">
  //             {obj}
  //           </a>
  //         </span>
  //       );
  //     });
  //     return spans;
  //   }
  // };

  return (
    <>
      {/* <TopHeader /> */}
      <SideBar />

      <div className="content-page">
        {/* Start content */}
        <div className="UserLoader" />
        <div className="content userpage">
          <div className="container">
            {/* Page-Title */}

            <div className=" filter_options">
              <h4 className="page-title">
                <font style={{ verticalAlign: "inherit" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                    {t('user_management')}
                  </font>
                </font>
              </h4>
              {/* <div className="comman_response" />
              <input
                type="hidden"
                id="filterUser"
                defaultValue="https://oksdevelopment.entwicklungslust.de/admin/nutzer"
              />
              <input
                type="hidden"
                id="userStatus"
                defaultValue="https://oksdevelopment.entwicklungslust.de/admin/userStatus"
              /> */}
              {/*Role Select Drop Down */}
              <div className="filter_options_inner">
                <div className="">
                  <div className="form-group">
                    <input
                      style={{ padding: "3px 0px 5px 5px", width: "100%" }}
                      type="text"
                      className="form-control"
                      name="searchUser"
                      placeholder={t('search')}
                      onChange={(e) => onFilterUserList(e?.target?.value)}
                    />
                  </div>
                </div>
                <div className="">
                  <select
                    name="fUserRole"
                    className="form-control form-white particularRole"
                    onChange={(e) => onSetUserRole(e?.target.value)}
                  >
                    <option value={0}>{t('all_user_roles')}</option>
                    <option value="SuperAdmin">{t('superAdmin')}</option>
                    <option value="manager">{t('manager')}</option>
                    <option value="viewer">{t('viewer')}</option>
                  </select>
                </div>
                {/*Status Drop down*/}
                <div className="">
                  <select
                    name="fUserStatus"
                    className="form-control form-white particularRole"
                    onChange={(e) => onSetUserStatus(e?.target?.value)}
                  >
                    <option value="statuses">{t('all_user_statuses')}</option>
                    <option value={1}>{t('active')}</option>
                    <option value={0}>{t('inactive')}</option>
                  </select>
                </div>

                {/*---Start Search User Form----------*/}

                {/*---End Search User Form----------*/}
                <div className="" onClick={() => setAddUserModal(true)}>
                  <a className="btn btn-theme addNewUser">
                    <font style={{ verticalAlign: "inherit" }}>
                      <font style={{ verticalAlign: "inherit" }}>{t('add_user')}</font>
                    </font>
                  </a>
                </div>
              </div>
            </div>
            <div className="userlisting ">
              <div className="table-responsive custom-center-ui">
                <table
                  id="loginUser"
                  className="display dataTable userManagement"
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th className="th-id">ID</th>
                      <th className="th-name">{t('name')}</th>
                      <th className="th-email">{t('email')}</th>
                      <th className="th-email">{t('allowed_domains')}</th>
                      <th className="th-email">{t('role')}</th>
                      <th className="th-status">{t('status')}</th>
                      <th className="th-edit">{t('actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isFetching == false && userListData?.length == 0 && (
                      <tr>
                        <td colSpan={"100%"}>{t('no_data_found')}</td>
                      </tr>
                    )}

                    {userListData?.data?.map((list, index) => {
                      return (
                        <Fragment key={list?.id}>
                          <tr>
                            <td>{list?.id}</td>
                            <td>{list?.name}</td>

                            <td>{list?.email}</td>
                            <td className="table-col-width">
                              {formatDomain(list?.allowedDomains, list?.id)}
                            </td>

                            {/* <td className="table-col-width">
                              {expandViewDomainFlag ? (
                                <>
                                  {list?.allowedDomains
                                    ?.split("\n")
                                    .map((domain, index) => {
                                      return (
                                        <span className="tag_wrapper">
                                          <a href={domain} target="_blank">
                                            {domain}
                                          </a>
                                        </span>
                                      );
                                    })}
                                </>
                              ) : (
                                formatDomain(list?.allowedDomains)
                              )}
                            </td> */}

                            <td>{list?.role}</td>
                            <td
                              className="userStatus"
                              data-status={1}
                              data-text="Deactivate"
                            >
                              {/* <label className="switch">
                                  <input type="checkbox" name="statuskW5" className="changeStatus" id={list?.id} checked={list?.status} />
                                  <span className="slider round" />
                                </label> */}
                              <Switch
                                width={40}
                                height={14}
                                onChange={(e) =>
                                  handleStatusChange(e, list?.id, index)
                                }
                                checked={list?.status}
                                className={"status_switch"}
                                onColor={"#6b172e"}
                              />
                            </td>
                            <td className="td-action">
                              <img
                                title="Update password"
                                src="./images/lock.png"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setUserEditModal(true);
                                  setIsEditData(list);
                                }}
                              />
                              &nbsp;&nbsp;
                              <img
                                title="Update user"
                                src="./images/edit.png"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setAddUserModal(true);
                                  setIsEditData(list);
                                }}
                              />
                              &nbsp;&nbsp;
                              <img
                                title="Delete user"
                                src="./images/delete.png"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setDeletableData(list);
                                  setIsDeletable(true);
                                }}
                              />
                              {/* <i
                                className="fa fa-trash"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setDeletableData(list);
                                  setIsDeletable(true);
                                }}
                              ></i> */}
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              {userListData?.totalPages > 1 && (
                <div style={{ textAlign: "center" }}>
                  <Pagination
                    activePage={userListData?.page}
                    itemsCountPerPage={userListData?.limit}
                    totalItemsCount={userListData?.totalRecords}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                  />
                </div>
              )}

              <div className="row">
                <div style={{}} className="pagnations">
                  <br />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <button className="btn btn-primary">
                  <font style={{ verticalAlign: "inherit" }}>
                    <font style={{ verticalAlign: "inherit" }}>
                      {/* <CSVLink data={userListData?.data} target="_blank" >Export user data as a CSV file</CSVLink> */}
                    </font>
                  </font>
                </button>
              </div>
              {/*--Syncronise email data --*/}
            </div>
          </div>
        </div>
      </div>

      {addUserModal && (
        <AddUser
          show={addUserModal}
          onHide={() => {
            setAddUserModal(false);
            setIsEditData(null);
            fetchUserList(params);
          }}
          dataObj={isEditData}
        />
      )}

      {useEditModal && (
        <UserEdit
          show={useEditModal}
          onHide={() => {
            setUserEditModal(false);
            setIsEditData(null);
            fetchUserList(params);
          }}
          isEditData={isEditData}
        />
      )}

      {isDeletble && (
        <DeleteUser
          show={isDeletble}
          onHide={() => setIsDeletable(false)}
          deletableData={deletableData}
          fetchUserList={fetchUserList}
        />
      )}
    </>
  );
}

export default Dashboard;
