import React from "react";
import { useEffect, useState } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import {
  accountAssessments,
  assessmentDelete,
  assessmentRestore,
  accountFreeze,
  getSimilarRefUsers,
  setFavouriteStatus,
} from "../../Components/action/common";
import SideBar from "../Dashboard/SideBar";
import TopHeader from "../Dashboard/TopHeader";
import { useDispatch, useSelector } from "react-redux";
import StarRatings from "react-star-ratings";
import { _ } from "lodash";
import Select from "react-select";
import AssessmentEdit from "../Dashboard/Modal/AssessmentEdit";
import CopyToCongressModal from "../Dashboard/Modal/CopyToCongressModal";
import AddNewExpertModal from "../Dashboard/Modal/AddNewExpertModal";
import EditNewExpertModal from "../Dashboard/Modal/EditNewExpertModal";
import { useNavigate, Navigate } from "react-router-dom";
import Comments from "../Dashboard/Modal/Comments";
import { Fragment } from "react";
import Pagination from "react-js-pagination";
import { authentication } from "../../Components/action/utilities";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Rating } from "react-simple-star-rating";
import { GiSheep } from "react-icons/gi";
import { fetchAllTags } from "../../Components/action/tags";
import { Tooltip as ReactTooltip } from "react-tooltip";
import AssessmentMerge from "../Dashboard/Modal/AssessmentMerge";
import { BASE_URL, DEFAULT_LANGUAGE } from "../../Components/Common/constants";
import ManageRecommendedTag from "../Dashboard/Modal/ManageRecommendedTag";
import Switch from "react-switch";
import ManageRecommendedTagNew from "../Dashboard/Modal/ManageRecommendedTagNew";
import AddNewExpertModalNew from "../Dashboard/Modal/AddNewExpertModalNew";
import EditNewExpertModalNew from "../Dashboard/Modal/EditNewExpertModalNew";
import CommentsNew from "../Dashboard/Modal/CommentsNew";
import AssessmentEditNew from "../Dashboard/Modal/AssessmentEditNew";
import CopyToCongressModalNew from "../Dashboard/Modal/CopyToCongressModalNew";
import AssessmentMergeNew from "../Dashboard/Modal/AssessmentMergeNew";

import { Table, Column, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";
import TagsPopUp from "./TagsPopUp";
import AssessmentEditNewModal from "./AssessmentEditNewModal";
import {
  setSearchDataTagsRedux,
  setSearchDataWordsRedux,
} from "../../redux/slices/searchSlice";
import { useTranslation } from 'react-i18next'
import AssessmentMakeExpertModal from "./AssessmentMakeExpertModal";

const lan = DEFAULT_LANGUAGE;
var CancelToken = axios.CancelToken;
var cancel;
function AssessmentNew({ pageType }) {
  const {t}=useTranslation();


  const navigate = useNavigate();

  const { loggedInData } = useSelector((state) => state.admin);
  const [assessmentTitle, setAssessmentTitle] = useState([]);
  const [assessmentList, setAssessmentList] = useState([]);
  const [assessmentListCsv, setAssessmentListCsv] = useState([]);
  const [assessmentAccountList, setAssessmentAccountList] = useState([]);
  const [accountNumber, setAccountNumber] = useState([]);
  const [commentModal, setCommentModal] = useState(false);
  const [assessmentDetails, setAssessmentDetails] = useState(null);
  const [ratingNumber, setRatingNumber] = useState(0);
  const [limits, setLimits] = useState(null);
  const [totalRecords, setTotalRecords] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [accountNameList, setAccountNameList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [csvLoading, setCsvLoading] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [showTagModal, setShowTagModal] = useState(false);
  const [getSortBy, setSortBy] = useState("name");
  const [getSortOrder, setSortOrder] = useState("desc");
  const [recommendedFor, setRecommendedFor] = useState(null);
  const [getFavFlag, setFavFlag] = useState(0);
  const [addNewExpert, setAddNewExpert] = useState(false);
  const [editNewExpert, setEditNewExpert] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [tempId, setTempId] = useState(null);

  const [makeExpertModal,setMakeExpertModal] = useState(false)

  let qParams = {
    pageNumber: 1,
    limit: 10,
    sortOrder: "desc",
    sortBy: "total",
    favFlag: getFavFlag,
  };
  if (pageType == "archieved") {
    qParams = Object.assign(qParams, { viewArchived: true });
  }
  const [params, setParams] = useState(qParams);

  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState(null);
  const [copyToCongressModal, setCopyToCongressModal] = useState(false);

  const [commentPopUp, setCommentPopUp] = useState(false);
  const [mergePopUp, setMergePopUp] = useState(false);

  const [paramsSort, setParamsSort] = useState({
    sortBy: "",
    sortOrder: "asc",
  });

  const [currentData, setCurrentData] = useState([]);

  const [selectedTags, setSelectedTags] = useState([]);
  const [searchWords, setSearchWords] = useState("");

  const [tagsPopUp, setTagsPopUp] = useState(false);
  const [tagsNameData, setTagsNameData] = useState([]);
  const [tagsSelectedNew, setTagsSelectedNew] = useState([]);

  const dispatch = useDispatch();
  const { searchDataWordsRedux, searchDataTagsRedux } = useSelector(
    (state) => state.search
  );

  const fetchTagsListing = async () => {
    try {
      let response = await fetchAllTags();
      console.log(
        response?.data?.responseData?.data,
        "response?.data?.responseData?.data"
      );
      setTagList(response?.data?.responseData?.data);
    } catch ({ response }) {}
  };

  const fetchAssessmentDetail = (params) => {
    if (typeof cancel == "function") {
      cancel();
    }
    setLoading(true);
    axios({
      method: "GET",
      //   params: params,
      url: "/assessmentNew",
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    })
      .then((response) => {
        setLoading(false);
        let account = response?.data?.responseData?.accounts?.map((el) => {
          return el?.id;
        });
        // if (params.searchText) {
        //   setSearchText(params.searchText);
        // } else {
        //   setSearchText(null);
        // }

        // if (searchWords.length > 0 || tagsSelectedNew.length > 0) {
        //   search(tagsSelectedNew, searchWords);
        // }

        let renderData = response?.data?.responseData?.accountData;
        let filteredData = renderData?.filter((assessment) => {
          const matchesSearch = searchWords
            ? assessment.name
                ?.toLowerCase()
                .includes(searchWords.toLowerCase()) ||
              assessment.id?.toString().includes(searchWords) ||
              assessment.digiId
                ?.toLowerCase()
                .includes(searchWords.toLowerCase()) ||
              assessment.email
                ?.toLowerCase()
                .includes(searchWords.toLowerCase())
            : true;
          return matchesSearch;
        });

        if (selectedTags?.length > 0) {
          filteredData = filteredData?.filter((assessment) => {
            const matchesTags = selectedTags.some((tag) =>
              assessment.tagNames
                ?.split(",")
                .map((t) => t.trim().toLowerCase())
                .includes(tag?.name?.toLowerCase())
            );

            return matchesTags;
          });
        }
        setAccountNameList(account);
        setAssessmentTitle(response?.data?.responseData?.assessmentTitle);
        setAssessmentList(response?.data?.responseData?.accountData);
        setCurrentData(filteredData);
        setAssessmentAccountList(response?.data?.responseData?.accounts);
      })
      .catch(({ response }) => {
        // response.status === 401 && navigate("/");
        // response.status === 401 && localStorage.clear();
      });
  };

  // Handle search by text input
  const search = (value) => {
    setSearchWords(value);
    dispatch(setSearchDataWordsRedux(value));
    searchTag(
      selectedTags.map((tag) => tag.name),
      value
    ); // Pass both tags and search term
  };

  const handleChange = (opt) => {
    const tags = opt ? opt.map((tag) => tag.name) : [];
    setSelectedTags(opt || []);
    dispatch(setSearchDataTagsRedux(opt || []));
    setTagsSelectedNew(tags);
    searchTag(tags, searchWords);
  };

  const searchTag = (tags = [], searchValue = "") => {
    const isSearchActive = searchValue.trim().length > 0;
    const areTagsSelected = tags.length > 0;

    const filteredData = assessmentList.filter((assessment) => {
      const matchesSearch = isSearchActive
        ? assessment.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          assessment.id?.toString().includes(searchValue) ||
          assessment.digiId
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          assessment.email?.toLowerCase().includes(searchValue.toLowerCase())
        : true;

      const matchesTags = areTagsSelected
        ? tags.some((tag) =>
            assessment.tagNames
              ?.split(",")
              .map((t) => t.trim().toLowerCase())
              .includes(tag.toLowerCase())
          )
        : true;

      return matchesSearch && matchesTags;
    });

    setCurrentData(filteredData);
  };

  const mergeReloadHandler = async () => {
    try {
      let response = await axios.get("/assessmentNew", {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      });

      const newAssessmentList = response?.data?.responseData?.accountData || [];

      const tags = selectedTags.map((tag) => tag.name);
      const filteredData = newAssessmentList.filter((assessment) => {
        const matchesSearch = searchWords
          ? assessment.name
              ?.toLowerCase()
              .includes(searchWords.toLowerCase()) ||
            assessment.id?.toString().includes(searchWords) ||
            assessment.digiId
              ?.toLowerCase()
              .includes(searchWords.toLowerCase()) ||
            assessment.email?.toLowerCase().includes(searchWords.toLowerCase())
          : true;

        const matchesTags = tags.length
          ? tags.some((tag) =>
              assessment.tagNames
                ?.split(",")
                .map((t) => t.trim().toLowerCase())
                .includes(tag.toLowerCase())
            )
          : true;

        return matchesSearch && matchesTags;
      });

      setAssessmentList(newAssessmentList);
      setCurrentData(filteredData);
    } catch (error) {
      console.error("Error fetching updated data:", error);
    }
  };

  const onViewAccountsCick = (assessment) => {
    navigate("/accounts", { state: { assessment: assessment } });
  };

  useEffect(() => {
    fetchTagsListing();
  }, []);

  useEffect(() => {
    // console.log(paramsSort,'ssss');
    if (paramsSort.sortBy) {
      const sortedData = [...currentData].sort((a, b) => {
        if (paramsSort.sortBy === "others"+tempId) {
          const totalInvitesA =
            a?.invitationData?.find((ele) => ele?.accountId === tempId)
              ?.totalInvites ?? 0;

          const totalInvitesB =
            b?.invitationData?.find((ele) => ele?.accountId === tempId)
              ?.totalInvites ?? 0;

          if (paramsSort.sortOrder === "asc") {
            return totalInvitesB - totalInvitesA;
          } else {
            return totalInvitesA - totalInvitesB;
          }
        } else if (
          paramsSort.sortBy === "seatTotal" ||
          paramsSort.sortBy === "seats"
        ) {
          const seatCountA = a.invitationData ? a.invitationData.length : 0;
          const seatCountB = b.invitationData ? b.invitationData.length : 0;

          if (seatCountA < seatCountB)
            return paramsSort.sortOrder === "asc" ? 1 : -1;
          if (seatCountA > seatCountB)
            return paramsSort.sortOrder === "asc" ? -1 : 1;
          return 0;
        } else if (paramsSort.sortBy === "favourite") {
          if (a.isFav && !b.isFav)
            return paramsSort.sortOrder === "asc" ? -1 : 1;
          if (!a.isFav && b.isFav)
            return paramsSort.sortOrder === "asc" ? 1 : -1;
          return 0;
        } else if (
          paramsSort.sortBy === "invites" ||
          paramsSort.sortBy === "invitesCategory"
        ) {
          const avgInvitesA =
            a.invitationData && a.invitationData.length > 0
              ? a.totalInvites / a.invitationData.length
              : 0;
          const avgInvitesB =
            b.invitationData && b.invitationData.length > 0
              ? b.totalInvites / b.invitationData.length
              : 0;

          if (avgInvitesA < avgInvitesB)
            return paramsSort.sortOrder === "asc" ? 1 : -1;
          if (avgInvitesA > avgInvitesB)
            return paramsSort.sortOrder === "asc" ? -1 : 1;
          return 0;
        } else {
          // Default sorting for other fields
          if (a[paramsSort.sortBy] < b[paramsSort.sortBy])
            return paramsSort.sortOrder === "asc" ? -1 : 1;
          if (a[paramsSort.sortBy] > b[paramsSort.sortBy])
            return paramsSort.sortOrder === "asc" ? 1 : -1;
          return 0;

          // return paramsSort.sortOrder === "asc"
          //   ? a[paramsSort.sortBy] - b[paramsSort.sortBy]
          //   : b[paramsSort.sortBy] - a[paramsSort.sortBy];
        }
      });
      setFavFlag(
        paramsSort.sortOrder === "asc" && paramsSort.sortBy === "favourite"
          ? 1
          : 0
      );

      setAssessmentList(sortedData);
      setCurrentData(sortedData);
    }
  }, [paramsSort, assessmentList, currentData, tempId]);

  useEffect(() => {
    if (pageType == "archieved") {
      let p = { ...qParams };
      p = Object.assign(p, { viewArchived: true });
      setParams(p);

      fetchAssessmentDetail(p);
    } else {
      let p = { ...qParams };
      delete p.viewArchived;
      setParams(p);
      fetchAssessmentDetail(p);
    }
  }, [pageType]);

  useEffect(() => {
    if (ratingNumber > 0) {
      for (let itr = 1; itr <= parseInt(ratingNumber); itr++) {
        const event = document.getElementById(itr.toString());
        event.style.backgroundImage = "url('/images/sheep-01.svg')";
        event.style.paddingBottom = "14px";
      }
    }
  }, [ratingNumber]);

  const onDeleteAssesment = (account) => {
    confirmAlert({
      title: t('confirm_to_delete'),
      message: t('are_you_sure_to_do_this'),
      buttons: [
        {
          label: t('yes'),
          onClick: async () => {
            try {
              await assessmentDelete({ id: account?.id });
              fetchAssessmentDetail(params);
            } catch ({ response }) {
              // response.status === 401 && navigate("/");
              // response.status === 401 && localStorage.clear();
            }
          },
        },
        {
          label: t('no'),
          //onClick: () => alert('Click No')
        },
      ],
    });
  };
  const onFreezeAccount = (account, status, index) => {
    confirmAlert({
      title:t('confirm_to_freeze_unfreeze'),
      message: t('are_you_sure_to_do_this'),
      buttons: [
        {
          label: t('yes'),
          onClick: async () => {
            try {
              await accountFreeze({
                id: account?.id,
                profileFreezed: status ? 1 : 0,
              });
              // let dd = [...assessmentList];
              // dd[index] = { ...dd[index], profileFreezed: status ? 1 : 0 };
              // setAssessmentList(dd);
              fetchAssessmentDetail();
            } catch ({ response }) {
              // response.status === 401 && navigate("/");
              // response.status === 401 && localStorage.clear();
            }
          },
        },
        {
          label:t('no'),
        },
      ],
    });
  };

  const onRestoreAssesment = (account) => {
    confirmAlert({
      title: t('confirm_to_restore'),
      message: t('are_you_sure_to_do_this'),
      buttons: [
        {
          label: t('yes'),
          onClick: async () => {
            try {
              let response = await assessmentRestore({ id: account?.id });
              fetchAssessmentDetail(params);
            } catch ({ response }) {
              // response.status === 401 && navigate("/");
              // response.status === 401 && localStorage.clear();
            }
          },
        },
        {
          label: t('no'),
        },
      ],
    });
  };

  const downloadCsv = async (event, done) => {
    try {
      setCsvLoading(true);
      let params = { isCsv: 1 };
      if (searchWords) {
        params = Object.assign(params, { searchText: searchWords });
      }
      let response = await axios.get(`/assessment`, { params: params });
      setAssessmentListCsv(response?.data?.responseData?.data);
      setCsvLoading(false);
      setTimeout(() => {
        done(false);
      }, 1000);
      // alert('Done  DOne ')
    } catch ({ response }) {
      setCsvLoading(false);
      // response.status === 401 && navigate("/");
      // response.status === 401 && localStorage.clear();
    }
  };

  const formatAverage = (avg) => {
    let imageSrc = "";
    if (avg <= 9) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-01-No-list.svg";
    } else if (avg >= 10 && avg <= 19) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-02-20.svg";
    } else if (avg >= 20 && avg <= 34) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-03-25.svg";
    } else if (avg >= 35 && avg <= 64) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-04-50.svg";
    } else if (avg >= 65 && avg <= 84) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-05-75.svg";
    } else if (avg >= 85 && avg <= 99) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-06-100-.svg";
    } else if (avg >= 100 && avg <= 199) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-07-100+.svg";
    } else if (avg >= 200 && avg <= 299) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-08-200.svg";
    } else if (avg >= 300 && avg <= 399) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-09-300.svg";
    } else if (avg >= 400 && avg <= 449) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-10-400.svg";
    } else if (avg >= 450 && avg <= 499) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-11-500.svg";
    } else if (avg >= 500 && avg <= 900) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-12-500.svg";
    } else if (avg >= 901 && avg <= 1999) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-13-1000.svg";
    } else if (avg >= 2000) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-14-MAX.svg";
    } else {
      return "-";
    }
    return <img style={{ width: "80px" }} src={imageSrc} />;
  };

  const formatSeats = (seats) => {
    let imageSrc = "";
    if (seats <= 1) {
      imageSrc = "/images/Elitespeaker-Icon_SEAT-CAT-1-1.svg";
    } else if (seats == 2) {
      imageSrc = "/images/Elitespeaker-Icon_SEAT-CAT-2-2.svg";
    } else if (seats == 3) {
      imageSrc = "/images/Elitespeaker-Icon_SEAT-CAT-3-3.svg";
    } else if (seats >= 4 && seats <= 5) {
      imageSrc = "/images/Elitespeaker-Icon_SEAT-CAT-4-5.svg";
    } else if (seats >= 6 && seats <= 9) {
      imageSrc = "/images/Elitespeaker-Icon_SEAT-CAT-5-10.svg";
    } else if (seats >= 10 && seats <= 19) {
      imageSrc = "/images/Elitespeaker-Icon_SEAT-CAT-6-10.svg";
    } else if (seats >= 20 && seats <= 29) {
      imageSrc = "/images/Elitespeaker-Icon_SEAT-CAT7-20.svg";
    } else if (seats >= 30 && seats <= 49) {
      imageSrc = "/images/Elitespeaker-Icon_SEAT-CAT8-30.svg";
    } else if (seats >= 50) {
      imageSrc = "/images/Elitespeaker-Icon_SEAT-CAT9-50.svg";
    } else {
      return "-";
    }
    return <img style={{ width: "80px" }} src={imageSrc} />;
  };

  if (!authentication()) return <Navigate to={"/"} />;


  const mergeHandler = async (id) => {
    setMergePopUp(true);
    try {
      const req = await getSimilarRefUsers(id);
      setLoading(true);
    } catch ({ response }) {
      // response.status === 401 && navigate("/");
      // response.status === 401 && localStorage.clear();

      setLoading(false);
    }
  };

  const formatTags = (tagString) => {
    if (!tagString) return null;

    const dataArray = tagString.split(",");
    const visibleTags = dataArray.slice(0, 2);
    const extraTagsCount = dataArray.length - visibleTags.length;

    return (
      <>
        {visibleTags.map((tag, index) => (
          <span key={index} className="tag_wrappernew">
            {tag}
          </span>
        ))}
        {extraTagsCount > 0 && (
          <span
            className="tagmore"
            title={tagString}
            onClick={() => {
              setTagsNameData(dataArray);
              setTagsPopUp(true);
            }}
          >
            {extraTagsCount} {t('more')}
          </span>
        )}
      </>
    );
  };

  const setFavourite = async (id, status, index) => {
    try {
      let response = await setFavouriteStatus({
        id: id,
        isFavourite: status ? 1 : 0,
      });
      let dd = [...assessmentList];
      dd[index] = { ...dd[index], isFavourite: status ? 1 : 0 };
      setAssessmentList(dd);
    } catch ({ response }) {
      // response.status === 401 && navigate("/");
      // response.status === 401 && localStorage.clear();
    }
  };

  const manageTagModal = (rowData) => {
    setShowTagModal(true);
    setRecommendedFor(rowData);
  };

  const handleStatusChange = async (rowData) => {
    try {
      let stat = rowData?.isFav === 0 ? 1 : 0;
      // setFavFlag(stat);

      let response = await setFavouriteStatus({
        id: rowData?.id,
        isFavourite: stat,
      });

      let updatedParams = Object.assign(params, { favFlag: stat });
      const newTimer = setTimeout(() => {
        fetchAssessmentDetail(updatedParams);
      }, 500);
      setParams(updatedParams);
    } catch ({ response }) {
      // response.status === 401 && navigate("/");
      // response.status === 401 && localStorage.clear();
    }
  };
  //   const sortRecords = (sortBy) => {
  //   setParamsSort((prev) => ({
  //     sortBy,
  //     sortOrder: prev.sortBy === sortBy && prev.sortOrder === "asc" ? "desc" : "asc",
  //   }));
  // };

  const sortRecords = (sortBy) => {
    setParamsSort((prev) => {
      const newSortOrder =
        prev.sortBy === sortBy && prev.sortOrder === "asc" ? "desc" : "asc";
      return {
        sortBy,
        sortOrder: newSortOrder,
      };
    });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const handleSort = (accountId) => {
    sortRecords("others"+accountId);
    setTempId(accountId);
  };

  const rowGetter = ({ index }) => currentData[index];

  const calculateColumnWidth = () => {
    const maxLength = currentData?.reduce(
      (max, row) => Math.max(max, row?.email?.length),
      0
    );
    let emailColumnWidth;
    if (searchWords) {
      emailColumnWidth = maxLength * 13;
    } else {
      emailColumnWidth = maxLength * 13 + 100;
    }
    return emailColumnWidth;
  };

  const columnNameWidth = () => {
    const maxLength = currentData?.reduce(
      (max, row) => Math.max(max, row?.name?.length),
      0
    );
    let nameColumnWidth;
    if (searchWords) {
      nameColumnWidth =
        loggedInData?.role === "SUPER_ADMIN"
          ? maxLength * 10 + 160
          : maxLength * 13 + 80;
    } else {
      nameColumnWidth =
        loggedInData?.role === "SUPER_ADMIN"
          ? maxLength * 15
          : maxLength * 15 + 50;
    }
    return nameColumnWidth;
  };

  const columnDIDWidth = () => {
    const maxLength = currentData?.reduce(
      (max, row) => Math.max(max, row?.digiId && row?.digiId?.length),
      0
    );

    let didColumnWidth;
    if (currentData?.length > 0) {
      if (searchWords) {
        didColumnWidth =
          loggedInData?.role === "SUPER_ADMIN"
            ? maxLength * 13 + 30
            : maxLength * 11 + 30;
      } else {
        didColumnWidth =
          loggedInData?.role === "SUPER_ADMIN"
            ? maxLength * 13 + 50
            : maxLength * 11 + 60;
      }
    } else {
      didColumnWidth = 300;
    }
    console.log(didColumnWidth,lan,'dddidd');
    
    return didColumnWidth;
  };

  return (
    <>
      <SideBar />
      <div className="content-page">
        <div className="UserLoader" />
        <div className="content userpage">
          <div className="container">
            <div className=" filter_options">
              <h4 className="page-title">{t('assessment_management')}</h4>
              <div className="filter_options_inner">
                <div className="form-group">
                  <input
                    style={{ padding: "3px 0px 5px 5px" }}
                    type="text"
                    className="form-control"
                    name="searchUser"
                    placeholder={t('search')}
                    onChange={(e) => search(e?.target?.value)}
                  />
                </div>

                <div className="form-group" style={{ minWidth: "300px" }}>
                  <Select
                    options={tagList}
                    getOptionLabel={(opt) => opt?.name}
                    getOptionValue={(opt) => opt?.id}
                    isClearable={true}
                    styles={customStyles}
                    value={selectedTags}
                    onChange={handleChange}
                    isMulti={true}
                    placeholder={`${t('select')}...`}
                  
                  />
                </div>

                {loggedInData?.role == "SUPER_ADMIN" && (
                  <button
                    className="border-none btn-theme"
                    onClick={downloadCsv}
                  >
                    <font style={{ verticalAlign: "inherit" }}>
                      <font style={{ verticalAlign: "inherit" }}>{t('export')}</font>
                    </font>
                  </button>
                )}

                {(loggedInData?.role == "SUPER_ADMIN" ||
                  loggedInData?.role == "MANAGER") && (
                  <button
                    className="border-none btn-theme"
                    onClick={() => setAddNewExpert(true)}
                  >
                    <font style={{ verticalAlign: "inherit" }}>
                      <font style={{ verticalAlign: "inherit" }}>
                        {t('add_expert')}
                      </font>
                    </font>
                  </button>
                )}

                {/* {loggedInData?.role == "VIEWER" && (
                  <div className=" filter_options">
                    <button
                      className="btn btn-theme"
                      style={{ marginBottom: "-21px", marginLeft: "auto" }}
                      onClick={() => manageTagModal(null)}
                    >
                      Neuen Tag vorschlagen
                    </button>
                  </div>
                )} */}
                {assessmentListCsv && assessmentListCsv.length > 0 && (
                  <CSVDownload data={assessmentListCsv} target="_blank">
                    Download me
                  </CSVDownload>
                )}
              </div>

              {/* </div> */}
            </div>

            <div className="userlisting row">
              <div className="assessement-table">
                {loading ? <div className="loading">{t('loading')}...</div> : <></>}

                <AutoSizer>
                  {({ height, width }) => (
                    <Table
                      width={
                        loggedInData?.role !== "SUPER_ADMIN"
                          ? Math.max(width, 3000)
                          : loggedInData?.role=== "SUPER_ADMIN" && lan==="de" ?Math.max(width, 7200):Math.max(width, 7000)
                      }
                      height={height}
                      headerHeight={144}
                      rowHeight={55}
                      rowCount={currentData.length}
                      rowGetter={rowGetter}
                      className="virtualized-table"
                    >
                      {/* Rank Column */}
                      <Column
                        className="nameTable"
                        label={t("rank")}
                        dataKey="rank"
                        width={80}
                        cellRenderer={({ rowData }) =>
                          assessmentList.indexOf(rowData) + 1
                        }
                      />

                      {/* Name Column */}
                      <Column
                        className="nameTable"
                        label={t('name')}
                        dataKey="name"
                        width={columnNameWidth() || 200}
                        cellRenderer={({ rowData }) => (
                          <>
                            <img
                              src={rowData?.image ?? "/images/profile_pic.png"}
                              alt="profile"
                              style={{
                                width: "40px",
                                height: "40px",
                                marginRight: "10px",
                                borderRadius: "100%",
                              }}
                            />
                            {rowData.name}
                          </>
                        )}
                        headerRenderer={() => {
                          const sort =
                            paramsSort?.sortBy === "name"
                              ? paramsSort?.sortOrder === "asc"
                                ? "asc"
                                : "desc"
                              : "dee";
                          return (
                            <a
                              href="#"
                              onClick={() => sortRecords("name")}
                              className="flex items-end gap-1"
                            >
                             {t('name')}{" "}
                              <img
                                className="h-6 w-6 opacity-70"
                                src={
                                  sort === "asc"
                                    ? "/images/sortIcons/sortUp.png"
                                    : sort === "desc"
                                    ? "/images/sortIcons/sortDown.png"
                                    : "/images/sortIcons/sortD.png"
                                }
                                alt="Sort Icon"
                              />
                            </a>
                          );
                        }}
                      />

                      {/* Favorite Column */}
                      <Column
                        label=""
                        dataKey="isFav"
                        width={50}
                        // headerRenderer={() => {
                        //   return (
                        //     <img
                        //       src={
                        //         getFavFlag
                        //           ? "/images/heart.png"
                        //           : "/images/heart_blank.png"
                        //       }
                        //       width="20px"
                        //     />
                        //   );
                        // }}

                        headerRenderer={() => {
                          const sort =
                            paramsSort?.sortBy === "favourite"
                              ? paramsSort?.sortOrder === "asc"
                                ? "asc"
                                : "desc"
                              : "dee";
                          return (
                            <a
                              href="#"
                              onClick={() => sortRecords("favourite")}
                              className="flex items-end gap-1"
                            >
                              <img
                                src={
                                  getFavFlag
                                    ? "/images/heart.png"
                                    : "/images/heart_blank.png"
                                }
                                width="20px"
                              />{" "}
                              <img
                                className="h-6 w-6 opacity-70"
                                src={
                                  sort === "asc"
                                    ? "/images/sortIcons/sortUp.png"
                                    : sort === "desc"
                                    ? "/images/sortIcons/sortDown.png"
                                    : "/images/sortIcons/sortD.png"
                                }
                                alt="Sort Icon"
                              />
                            </a>
                          );
                        }}
                        cellRenderer={({ rowData }) => (
                          <img
                            src={
                              rowData.isFav
                                ? "/images/heart.png"
                                : "/images/heart_blank.png"
                            }
                            width="20px"
                            onClick={() => handleStatusChange(rowData)}
                          />
                        )}
                      />

                      <Column
                        // label="Link"
                        dataKey="link"
                        width={60}
                        cellRenderer={({ rowData }) => (
                          <>
                            {rowData && rowData?.link ? (
                              <a
                                // href={rowData?.link}
                                href={
                                  rowData?.link?.startsWith("https://") ||
                                  rowData?.link?.startsWith("http://")
                                    ? rowData.link
                                    : `https://${rowData.link}`
                                }
                                target="_blank"
                              >
                                <img
                                  style={{ width: "25px" }}
                                  src="/images/internet.svg"
                                />
                              </a>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      />

                      {/* D24-ID Column */}
                      <Column
                        label="D24-ID"
                        dataKey="digiId"
                        width={columnDIDWidth()||300}
                        className="sorting"
                        headerRenderer={() => {
                          const sort =
                            paramsSort?.sortBy === "digiId"
                              ? paramsSort?.sortOrder === "asc"
                                ? "asc"
                                : "desc"
                              : "dee";
                          return (
                            <a
                              className="flex items-end gap-1"
                              href="#"
                              onClick={() => sortRecords("digiId")}
                            >
                              D24-ID{" "}
                              <img
                                className="h-6 w-6 opacity-70"
                                src={
                                  sort === "asc"
                                    ? "/images/sortIcons/sortUp.png"
                                    : sort === "desc"
                                    ? "/images/sortIcons/sortDown.png"
                                    : "/images/sortIcons/sortD.png"
                                }
                                alt="Sort Icon"
                              />
                            </a>
                          );
                        }}
                      />

                      {/* Email Column */}
                      <Column
                        label={t('email')}
                        dataKey="email"
                        width={calculateColumnWidth() || 200}
                        headerRenderer={() => {
                          console.log("Rendering header");

                          const sort =
                            paramsSort?.sortBy === "email"
                              ? paramsSort?.sortOrder === "asc"
                                ? "asc"
                                : "desc"
                              : "dee";
                          return (
                            <a
                              className="flex items-end gap-1"
                              href="#"
                              onClick={() => sortRecords("email")}
                            >
                             {t('email')}{" "}
                              <img
                                className="h-6 w-6 opacity-70"
                                src={
                                  sort === "asc"
                                    ? "/images/sortIcons/sortUp.png"
                                    : sort === "desc"
                                    ? "/images/sortIcons/sortDown.png"
                                    : "/images/sortIcons/sortD.png"
                                }
                                alt="Sort Icon"
                              />
                            </a>
                          );
                        }}
                      />

                      <Column
                        label={t('seats_category')}
                        dataKey="seatsCategory"
                        width={300}
                        cellRenderer={({ rowData }) => (
                          <>
                            {rowData && rowData.invitationData ? (
                              formatSeats(rowData.invitationData.length)
                            ) : (
                              <>-</>
                            )}
                          </>
                        )}
                        headerRenderer={() => {
                          const sort =
                            paramsSort?.sortBy === "seats"
                              ? paramsSort?.sortOrder === "asc"
                                ? "asc"
                                : "desc"
                              : "dee";
                          return (
                            <a
                              className="flex items-end gap-1"
                              href="#"
                              onClick={() => sortRecords("seats")}
                            >
                              {t('seats_category')}{" "}
                              <img
                                className="h-6 w-6 opacity-70"
                                src={
                                  sort === "asc"
                                    ? "/images/sortIcons/sortUp.png"
                                    : sort === "desc"
                                    ? "/images/sortIcons/sortDown.png"
                                    : "/images/sortIcons/sortD.png"
                                }
                                alt="Sort Icon"
                              />
                            </a>
                          );
                        }}
                      />

                      {loggedInData?.role == "SUPER_ADMIN" && (
                        <Column
                          label={t('seats')}
                          dataKey="seats"
                          width={150}
                          cellRenderer={({ rowData }) => (
                            <>
                              {rowData && rowData.invitationData
                                ? rowData.invitationData.length
                                : 0}
                            </>
                          )}
                          headerRenderer={() => {
                            const sort =
                              paramsSort?.sortBy === "seatTotal"
                                ? paramsSort?.sortOrder === "asc"
                                  ? "asc"
                                  : "desc"
                                : "dee";
                            return (
                              <a
                                className="flex items-end gap-1"
                                href="#"
                                onClick={() => sortRecords("seatTotal")}
                              >
                                {t('seats')}{" "}
                                <img
                                  className="h-6 w-6 opacity-70"
                                  src={
                                    sort === "asc"
                                      ? "/images/sortIcons/sortUp.png"
                                      : sort === "desc"
                                      ? "/images/sortIcons/sortDown.png"
                                      : "/images/sortIcons/sortD.png"
                                  }
                                  alt="Sort Icon"
                                />
                              </a>
                            );
                          }}
                        />
                      )}

                      {loggedInData?.role == "SUPER_ADMIN" && (
                        <Column
                          label={t('total')}
                          dataKey="total"
                          width={150}
                          cellRenderer={({ rowData }) => (
                            <>{rowData?.totalInvites ?? "-"}</>
                          )}
                          headerRenderer={() => {
                            const sort =
                              paramsSort?.sortBy === "totalInvites"
                                ? paramsSort?.sortOrder === "asc"
                                  ? "asc"
                                  : "desc"
                                : "dee";

                            return (
                              <a
                                className="flex items-end gap-1"
                                href="#"
                                onClick={() => sortRecords("totalInvites")}
                              >
                                {t('total')}{" "}
                                <img
                                  className="h-6 w-6 opacity-70"
                                  src={
                                    sort === "asc"
                                      ? "/images/sortIcons/sortUp.png"
                                      : sort === "desc"
                                      ? "/images/sortIcons/sortDown.png"
                                      : "/images/sortIcons/sortD.png"
                                  }
                                  alt="Sort Icon"
                                />
                              </a>
                            );
                          }}
                        />
                      )}

                      <Column
                        label={t('invite_category')}
                        dataKey="inviteCategory"
                        width={loggedInData?.role == "SUPER_ADMIN" && lan==="de"?340:330}
                        cellRenderer={({ rowData }) => (
                          <>
                            {" "}
                            {rowData &&
                              rowData.invitationData &&
                              formatAverage(
                                Math.round(
                                  rowData?.totalInvites /
                                    rowData?.invitationData.length
                                )
                              )}
                          </>
                        )}
                        headerRenderer={() => {
                          const sort =
                            paramsSort?.sortBy === "invitesCategory"
                              ? paramsSort?.sortOrder === "asc"
                                ? "asc"
                                : "desc"
                              : "dee";
                          return (
                            <a
                              className="flex items-end gap-1"
                              href="#"
                              onClick={() => sortRecords("invitesCategory")}
                            >
                              {t('invite_category')}{" "}
                              <img
                                className="h-6 w-6 opacity-70"
                                src={
                                  sort === "asc"
                                    ? "/images/sortIcons/sortUp.png"
                                    : sort === "desc"
                                    ? "/images/sortIcons/sortDown.png"
                                    : "/images/sortIcons/sortD.png"
                                }
                                alt="Sort Icon"
                              />
                            </a>
                          );
                        }}
                      />

                      {loggedInData?.role == "SUPER_ADMIN" && (
                        <Column
                          label={t('invites')}
                          dataKey="invites"
                          width={lan==="de"?220:150}
                          cellRenderer={({ rowData }) => (
                            <>
                              {" "}
                              {rowData &&
                                rowData.invitationData &&
                                Math.round(
                                  rowData?.totalInvites /
                                    rowData?.invitationData.length
                                )}
                            </>
                          )}
                          headerRenderer={() => {
                            const sort =
                              paramsSort?.sortBy === "invites"
                                ? paramsSort?.sortOrder === "asc"
                                  ? "asc"
                                  : "desc"
                                : "dee";
                            return (
                              <a
                                className="flex items-end gap-1"
                                href="#"
                                onClick={() => sortRecords("invites")}
                              >
                                {t('invites')}{" "}
                                <img
                                  className="h-6 w-6 opacity-70"
                                  src={
                                    sort === "asc"
                                      ? "/images/sortIcons/sortUp.png"
                                      : sort === "desc"
                                      ? "/images/sortIcons/sortDown.png"
                                      : "/images/sortIcons/sortD.png"
                                  }
                                  alt="Sort Icon"
                                />
                              </a>
                            );
                          }}
                        />
                      )}

                      {/* Rating Column */}
                      <Column
                        label={t('rating')}
                        dataKey="rating"
                        width={235}
                        cellRenderer={({ rowData }) => (
                          <span className="star-rating">
                            <Rating
                              initialValue={rowData.rating}
                              readonly
                              size={25}
                            />
                          </span>
                        )}
                        headerRenderer={() => {
                          const sort =
                            paramsSort?.sortBy === "rating"
                              ? paramsSort?.sortOrder === "asc"
                                ? "asc"
                                : "desc"
                              : "dee";
                          return (
                            <a
                              className="flex items-end gap-1"
                              href="#"
                              onClick={() => sortRecords("rating")}
                            >
                             {t('rating')}{" "}
                              <img
                                className="h-6 w-6 opacity-70"
                                src={
                                  sort === "asc"
                                    ? "/images/sortIcons/sortUp.png"
                                    : sort === "desc"
                                    ? "/images/sortIcons/sortDown.png"
                                    : "/images/sortIcons/sortD.png"
                                }
                                alt="Sort Icon"
                              />
                            </a>
                          );
                        }}
                      />
                      <Column
                        label={t('sheep_rating')}
                        dataKey="pigRating"
                        width={loggedInData?.role == "SUPER_ADMIN" && lan==="de"?270:260}
                        cellRenderer={({ rowData }) => (
                          <span className="star-rating">
                            <Rating
                              initialValue={rowData?.pigRating}
                              fillIcon={<GiSheep size={25} color={"#000000"} />}
                              emptyIcon={
                                <GiSheep size={25} color={"#cccccc"} />
                              }
                              readonly
                            />
                          </span>
                        )}
                        headerRenderer={() => {
                          const sort =
                            paramsSort?.sortBy === "pigRating"
                              ? paramsSort?.sortOrder === "asc"
                                ? "asc"
                                : "desc"
                              : "dee";
                          return (
                            <a
                              className="flex items-end gap-1"
                              href="#"
                              onClick={() => sortRecords("pigRating")}
                            >
                             {t('sheep_rating')}{" "}
                              <img
                                className="h-6 w-6 opacity-70"
                                src={
                                  sort === "asc"
                                    ? "/images/sortIcons/sortUp.png"
                                    : sort === "desc"
                                    ? "/images/sortIcons/sortDown.png"
                                    : "/images/sortIcons/sortD.png"
                                }
                                alt="Sort Icon"
                              />
                            </a>
                          );
                        }}
                      />

                      <Column
                        label={t('tags')}
                        dataKey="tags"
                        width={880}
                        cellRenderer={({ rowData }) => (
                          <> {formatTags(rowData?.tagNames)} </>
                        )}
                      />

                      {/* Actions Column */}
                      <Column
                        label={t('actions')}
                        dataKey="actions"
                        width={530}
                        cellRenderer={({ rowData, rowIndex }) => (
                          <div className="action-icons">
                            {pageType === "archived" ? (
                              (loggedInData?.role === "SUPER_ADMIN" ||
                                loggedInData?.role === "MANAGER") && (
                                <>
                                  <i
                                    title="Restore"
                                    className="fa fa-window-restore"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => onRestoreAssesment(rowData)}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                </>
                              )
                            ) : (
                              <>
                                {(loggedInData?.role === "SUPER_ADMIN" ||
                                  loggedInData?.role === "MANAGER") && (
                                  <>
                                    <img
                                       className="h-10 w-10"
                                      title={
                                        rowData.profileFreezed === 0
                                          ? t('unfreeze_account')
                                          : t('freeze_account')
                                      }
                                      src={
                                        rowData.profileFreezed === 0
                                          ? "./images/assessmentIcons/unfreezeIcon.svg"
                                          : "./images/assessmentIcons/freezeIcon.svg"
                                      }
                                      onClick={() =>
                                        onFreezeAccount(
                                          rowData,
                                          !rowData.profileFreezed,
                                          rowIndex
                                        )
                                      }
                                    />
                                    <img
                                        className="h-10 w-10"
                                      title={t('edit_tags')}
                                      src="./images/assessmentIcons/tagIcon.svg"
                                      onClick={() => {
                                        setCommentPopUp(true);
                                        setAssessmentDetails(rowData);
                                      }}
                                    />
                                  </>
                                )}

                                <img
                                    className="h-10 w-10"
                                  title={t('remarks')}
                                  src="./images/assessmentIcons/remarksIcon.svg"
                                  onClick={() => {
                                    setCommentModal(true);
                                    setAssessmentDetails(rowData);
                                  }}
                                />

                                {loggedInData?.role === "SUPER_ADMIN" && (
                                  <img
                                      className="h-10 w-10"
                                    title={t('email_list')}
                                    src="./images/assessmentIcons/emailListIcon.svg"
                                    onClick={() => onViewAccountsCick(rowData)}
                                  />
                                )}

                                {(loggedInData?.role === "SUPER_ADMIN" ||
                                  loggedInData?.role === "MANAGER") && (
                                  <img
                                      className="h-10 w-10"
                                    title={t('delete')}
                                    src="./images/assessmentIcons/deleteIcon.svg"
                                    onClick={() => onDeleteAssesment(rowData)}
                                  />
                                )}

                                <img
                                    className="h-10 w-10"
                                  title={t('transporter')}
                                  src="./images/assessmentIcons/transportIcon.svg"
                                  onClick={() => {
                                    setCopyToCongressModal(true);
                                    setAssessmentDetails(rowData);
                                  }}
                                />

                                {(loggedInData?.role === "SUPER_ADMIN" ||
                                  loggedInData?.role === "MANAGER") && (
                                  <img
                                      className="h-10 w-10"
                                    title={t('edit')}
                                    src="./images/assessmentIcons/editIcon.svg"
                                    onClick={() => {
                                      setEditNewExpert(true);
                                      setAssessmentDetails(rowData);
                                    }}
                                  />
                                )}

                                {(loggedInData?.role === "SUPER_ADMIN" ||
                                  loggedInData?.role === "MANAGER") && (
                                  <>
                                  <img
                                      className="h-10 w-10"
                                    title={t('merge')}
                                    src="./images/assessmentIcons/mergeIcon.svg"
                                    onClick={() => {
                                      setMergePopUp(true);
                                      setAssessmentDetails(rowData);
                                    }}
                                  />
                                  <img
                                      className=""
                                    title={t('make_expert')}
                                    src="./images/assessmentIcons/userExpert.svg"
                                    onClick={() => {
                                      setMakeExpertModal(true)
                                      setAssessmentDetails(rowData);
                                    }}
                                  />
                                  </>
                                )}

                                {loggedInData?.role === "VIEWER" && (
                                  <img
                                      className="h-10 w-10"
                                    src="./images/assessmentIcons/tagIcon.svg"
                                    width="18"
                                    onClick={() => manageTagModal(rowData)}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        )}
                      />

                      {loggedInData?.role === "SUPER_ADMIN" &&
                        assessmentAccountList?.map((account, index) => {
                          const displayName =
                            account?.name?.length > 10
                              ? account?.name.slice(0, 10) + "..."
                              : account?.name;
                          const linkName = account?.domainName;
                          const sort =
                            paramsSort?.sortBy === "others" + account?.id
                              ? paramsSort?.sortOrder === "asc"
                                ? "asc"
                                : "desc"
                              : "dee";
                          return (
                            <Column
                              key={account.id || index}
                              label={"displayName"}
                              dataKey={account.id}
                              width={70}
                              headerRenderer={() => (
                                <>
                                  <font style={{ verticalAlign: "inherit" }}>
                                    <a
                                      href="#"
                                      data-tooltip-id={"my-tooltip" + index}
                                      // className="my-tooltip verticalHeader"
                                     
                                      dangerouslySetInnerHTML={{
                                        __html: displayName.replaceAll(
                                          " ",
                                          "&nbsp;"
                                        ),
                                      }}
                                    />

                                    

                                    <div className="sort-view">
                                    <a
                                      href={`${linkName}`}
                                      target="blank"
                                      data-tooltip-id={
                                        "my-tooltip-link" + index
                                      }
                                      style={{ marginBottom: "5px" }}
                                    >
                                      <img
                                        src="./images/view.svg"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </a>

                                    <img
                                     onClick={() => handleSort(account?.id)}
                                      className="h-6 w-6 opacity-70"
                                      src={
                                        sort === "asc"
                                          ? "/images/sortIcons/sortUp.png"
                                          : sort === "desc"
                                          ? "/images/sortIcons/sortDown.png"
                                          : "/images/sortIcons/sortD.png"
                                      }
                                      alt="Sort Icon"
                                    />
                                    </div>
                                  </font>
                                  <ReactTooltip
                                    id={"my-tooltip" + index}
                                    place="left"
                                    content={account?.name}
                                  />
                                  <ReactTooltip
                                    id={"my-tooltip-link" + index}
                                    place="left"
                                    content={account?.name}
                                  />
                                </>
                              )}
                              cellRenderer={({ rowData }) => (
                                <>
                                  {rowData.invitationData?.find(
                                    (ele) => ele.accountId === account?.id
                                  )?.totalInvites ?? "-"}
                                </>
                              )}
                            />
                          );
                        })}
                    </Table>
                  )}
                </AutoSizer>
              </div>

              <div className="row">
                <div style={{}} className="pagnations">
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {mergePopUp && (
        <AssessmentMergeNew
          show={mergePopUp}
          onHide={() => setMergePopUp(false)}
          assessment={assessmentDetails}
          fetchAssessmentDetail={fetchAssessmentDetail}
          mergeReloadHandler={mergeReloadHandler}
          params={params}
          loggedInData={loggedInData}
        />
      )}
      {copyToCongressModal && (
        <CopyToCongressModalNew
          show={copyToCongressModal}
          onHide={() => setCopyToCongressModal(false)}
          assessment={assessmentDetails}
          fetchAssessmentDetail={fetchAssessmentDetail}
          params={params}
          loggedInData={loggedInData}
        />
      )}

      {commentPopUp && (
        <AssessmentEditNewModal
          show={commentPopUp}
          onHide={() => setCommentPopUp(false)}
          assessment={assessmentDetails}
          fetchAssessmentDetail={fetchAssessmentDetail}
          params={params}
          loggedInData={loggedInData}
        />
      )}

      {
        makeExpertModal && <AssessmentMakeExpertModal
        onHide={() => setMakeExpertModal(false)}
        assessment={assessmentDetails}
        show={makeExpertModal}

        />
      }

      {tagsPopUp && (
        <TagsPopUp
          show={tagsPopUp}
          onHide={() => setTagsPopUp(false)}
          tagsNameData={tagsNameData}
        />
      )}

      {commentModal && (
        <CommentsNew
          show={commentModal}
          onHide={() => {
            setCommentModal(false);
          }}
          assessment={assessmentDetails}
          fetchAssessmentDetail={fetchAssessmentDetail}
          params={params}
          loggedInData={loggedInData}
        />
      )}
      {showTagModal && (
        <ManageRecommendedTagNew
        fetchAssessmentDetail={ fetchAssessmentDetail}
          recommendedFor={recommendedFor}
          show={showTagModal}
          onHide={() => {
            setShowTagModal(false);
            
          }}
        />
      )}

      {addNewExpert && (
        <AddNewExpertModalNew
          show={addNewExpert}
          onHide={() => setAddNewExpert(false)}
          assessment={assessmentDetails}
          fetchAssessmentDetail={fetchAssessmentDetail}
          params={params}
          loggedInData={loggedInData}
        />
      )}

      {editNewExpert && (
        <EditNewExpertModalNew
          show={editNewExpert}
          onHide={() => setEditNewExpert(false)}
          assessment={assessmentDetails}
          fetchAssessmentDetail={fetchAssessmentDetail}
          params={params}
          loggedInData={loggedInData}
        />
      )}
    </>
  );
}

export default AssessmentNew;
