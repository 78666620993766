import './TailwindComponent.css';
import BecomeSpeakerForm from "./BecomeSpeakerForm";
import BecomeWelcome from "./BecomeWelcome";
import { useEffect, useState } from 'react';
import { getExpertGeneralSettings } from '../../Components/action/becomeSpeakerAction';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

const BecomeSpeaker = () => {
  const navigate = useNavigate();
  const [dataSettings, setDataSettings] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchGeneralSettings = async () => {
    setLoading(true);
    await getExpertGeneralSettings().then((res) => {
      const data = res?.data?.responseData?.applicationSettings;
      if (data) {
        setDataSettings(data);
      }
      setLoading(false);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchGeneralSettings();
  }, []);

  return (
    <section className="font-montserrat bg-white flex flex-col max-w-[100vw] h-full items-center text-[16px] border-none">
      {loading && <div className="loading">{t('loading')}...</div>}

      <div className="mt-10 w-full flex justify-start px-[10%]">
        <button
          className="bg-btnColor border-none px-6 text-white rounded-lg p-2 hover:scale-105"
          onClick={() => {
            navigate("/");
          }}
        >
          {t('back')}
        </button>
      </div>

      <div className="become-speaker-body w-full flex flex-col mb-10 justify-center">
        <BecomeWelcome dataSettings={dataSettings} />
        <BecomeSpeakerForm dataSettings={dataSettings} />
      </div>
    </section>
  );
};

export default BecomeSpeaker;
