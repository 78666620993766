import axios from "axios";


export const getEmailTemplateList = (params) => {
    return axios({
        method: 'GET',
        url: '/email/template/list',
        params:params
    })
}

export const deleteEmailTemplate = (id) => {
    return axios({
        method: 'DELETE',
        url: `/email/template/${id}`,
        // data:{id}
        
    })
}

export const updateEmailTemplate = (id,props) => {
    return axios({
        method:  'PATCH',
        url:  `/email/template/${id}`,
        data:props
    })
}

export const postEmailTemplate=(props)=>{
    return axios({
        method:'POST',
        url:"/email/template",
        data:props
    })
}
