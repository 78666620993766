import React from "react";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  KONGRESS_ATUH_KEY,
  COPY_KONGRESS_END_POINT,
} from "../../../Components/Common/constants";
import StarRatings from "react-star-ratings";
import {
  speakerDetail,
  deleteProduct,
  fetchDeletedProducts,
  restoreArchievedProduct,
  editNewAssesmentUser,
} from "../../../Components/action/common";
import { TagsInput } from "react-tag-input-component";
import Select from "react-select";
import _ from "lodash";
import axios from "axios";
import "./style.css";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import { validationSchema } from "./editschema";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ExpertCropImageExpert from "../formImageExpert/ExpertCropImageExpert";
import { t } from "i18next";

function EditNewExpertModalNew({
  show,
  onHide,
  assessment,
  fetchAssessmentDetail,
  params,
  loggedInData,
}) {
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [commentValue, setCommentValue] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [domainName, setDomainName] = useState("");
  const [formInitialValues, setFormInitialValues] = useState({});
  const [deletedProductsArray, setDeletedProductsArray] = useState([]);
  const [fetchingData, setFetchingData] = useState({});
  const [apiUrl, setApiUrl] = useState("");
  const [domainError, setDomainError] = useState("");

  const [imagePath, setImagePath] = useState(null);

  const [errorImg, setErrorImg] = useState(null);
  const [errors, setErrors] = useState({});

  const isValidUrl = (str) => {
    if (
      /^(http(s?):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
        str
      )
    ) {
      console.log("YES");
      return true;
    } else {
      console.log("NO");
      return false;
    }
  };
  useEffect(() => {
    console.log(assessment?.id, "aaaaaa");
    fetchSpeakerDetail(assessment?.id);
    fetchArchievedProducts();
  }, []);

  const fetchSpeakerDetail = async (params) => {
    try {
      let response = await speakerDetail(params);
      console.log(
        response?.data?.responseData,
        "response?.data?.responseData?.data"
      );
      let data = response?.data?.responseData;
      data = Object.assign(data, { domainName: "" });
      setFormInitialValues(data);
      console.log("dddddd", data);
      setTimeout(() => {
        setFetchingData(false);
      }, 500);
    } catch ({ response }) {
      setFetchingData(false);
    }
  };

  const addUpdateProductPicture = (croppedImage) => {
    setImagePath(croppedImage);
  };

  const handleRemoveImage = () => {
    setFormInitialValues((prev) => ({
      ...prev,
      refUserPicture: "",
    }));
  };

  const validateForm = (formData) => {
    let errorList = {};
    let isValid = true;

    // if (!formData.title) {
    //   isValid = false;
    //   errorList.title = t("error_title_required");
    // }

    if (!formData.refUserName) {
      isValid = false;
      errorList.refUserName = t("name_is_required");
    }
    if (!formData.refUserDigistoreId) {
      isValid = false;
      errorList.refUserDigistoreId = t("digistore_id_required");
    }

    if (!formData.refUserEmail) {
      isValid = false;
      errorList.refUserEmail = t("email_address_required");
    } else if (!/\S+@\S+\.\S+/.test(formData.refUserEmail)) {
      isValid = false;
      errorList.refUserEmail = t("valid_email_required");
    }
   

    setErrors(errorList);
    return isValid;
  };

  const onSubmit = async (formData) => {
    // if ((!formData.refUserPicture || formData.refUserPicture.trim() === "") && (!imagePath || imagePath.trim() === "")) {
    //     setErrorImg("Profile image is required!");
    //     return;
    // }

    // console.log("====a===a==", formData);
    delete formData?.id;
    delete formData?.refUserPictureAll;
    delete formData?.profileFreezed;
    delete formData?.groupAccount;
    delete formData?.createdAt;
    delete formData?.updatedAt;
    delete formData?.deletedAt;
    delete formData?.RefUserProducts;
    delete formData?.domainName;
    // delete formData?.refUserPhone;
    // delete formData?.displayDigistoreId;
    delete formData?.userId;
    if (validateForm(formData)) {
    setIsLoader(true);
    if (imagePath) {
      formData.refUserPicture = imagePath;
    }

    try {
      await editNewAssesmentUser(formData, assessment?.id);
      setIsLoader(false);
      fetchAssessmentDetail();
      onHide();
    } catch ({ response }) {
      setIsLoader(false);
    }}
  };

  const updateTags = (obj) => {
    setSelectedTags(obj);
  };

  const deleteUserProduct = async (obj, index) => {
    console.log(obj, "ooooooo");
    try {
      let response = await deleteProduct(obj?.id);
      fetchSpeakerDetail(assessment?.id);
      fetchArchievedProducts();
    } catch ({ response }) {
      setFetchingData(false);
    }
  };
  const restoreProduct = async (obj, index) => {
    console.log(obj, "ooooooo");
    try {
      let response = await restoreArchievedProduct(obj?.id);
      fetchSpeakerDetail(assessment?.id);
      fetchArchievedProducts();
    } catch ({ response }) {
      setFetchingData(false);
    }
  };

  const fetchArchievedProducts = async () => {
    try {
      let response = await fetchDeletedProducts({ refUserId: assessment?.id });
      setDeletedProductsArray(response?.data?.responseData);
    } catch ({ response }) {
      //setFetchingData(false);
    }
  };

  console.log("selectedTags", deletedProductsArray);

  return (
    <Modal
      className="modal copy_modal"
      show={show}
      onHide={() => {
        onHide();
      }}
    >
      <div className="modal-lg">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <span aria-hidden="true">
              <font style={{ verticalAlign: "inherit" }}>
                <font style={{ verticalAlign: "inherit" }}>×</font>
              </font>
            </span>
          </button>
          <h4 className="modal-title" id="assignModalLabel">
            <font style={{ verticalAlign: "inherit" }}>
              <font style={{ verticalAlign: "inherit" }}>
                {t("edit_expert")}
              </font>
            </font>
          </h4>
        </div>
        <div className="modal-body formLog">
          <div className="comman_response" />
          <Formik
            enableReinitialize={true}
            initialValues={formInitialValues}
            // validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ touched, values, setFieldValue }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="col-lg-12 form-group">
                      <label
                        className="control-label"
                        htmlFor="refUserDigistoreId"
                      >
                        Digistore ID User Reference
                      </label>
                      <Field
                        id="refUserDigistoreId"
                        name="refUserDigistoreId"
                        className="form-control"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <label
                        className="control-label"
                        htmlFor="displayDigistoreId"
                      >
                        Digistore ID
                      </label>
                      <Field
                        id="displayDigistoreId"
                        name="displayDigistoreId"
                        placeholder=""
                        className="form-control"
                        // value={values.displayDigistoreId??values.refUserDigistoreId}
                      />
                      {errors.displayDigistoreId ? (
                        <div className="error">{errors.displayDigistoreId}</div>
                      ) : null}
                    </div>
                    <div className="col-lg-6 form-group">
                      <label className="control-label" htmlFor="refUserPhone">
                        {t("mobile_number")}
                      </label>
                      <Field
                        id="refUserPhone"
                        name="refUserPhone"
                        placeholder=""
                        className="form-control"
                      />
                      {/* {errors.displayDigistoreId ? (
                        <div className="error">{errors.displayDigistoreId}</div>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="col-lg-4 form-group">
                      <label
                        className="control-label"
                        htmlFor="title"
                      >
                        {t("title")}
                      </label>
                      <Field id="title" name="title" className="form-control" />

                      {errors.title ? (
                        <div className="error">{errors.title}</div>
                      ) : null}
                    </div>
                    <div className="col-lg-4 form-group">
                      <label className="control-label" htmlFor="first_name">
                        {t("first_name")}
                      </label>
                      <Field
                        id="first_name"
                        name="first_name"
                        className="form-control"
                      />
                      {errors.first_name ? (
                        <div className="error">{errors.first_name}</div>
                      ) : null}
                    </div>
                    <div className="col-lg-4 form-group">
                      <label className="control-label" htmlFor="last_name">
                        {t("last_name")}
                      </label>
                      <Field
                        id="last_name"
                        name="last_name"
                        className="form-control"
                      />
                      {errors.last_name ? (
                        <div className="error">{errors.last_name}</div>
                      ) : null}
                    </div> */}

                    <div className="col-lg-6 form-group">
                      <label className="control-label" htmlFor="lastName">
                        {t("email")}
                      </label>
                      <Field
                        id="refUserEmail"
                        name="refUserEmail"
                        className="form-control"
                      />
                      {errors.refUserEmail ? (
                        <div className="error">{errors.refUserEmail}</div>
                      ) : null}
                    </div>

                    <div className="col-lg-6 form-group">
                      <label className="control-label" htmlFor="refUserName">
                        {t("name")}
                      </label>
                      <Field
                        id="refUserName"
                        name="refUserName"
                        className="form-control"
                      />
                      {errors.refUserName ? (
                        <div className="error">{errors.refUserName}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 form-group">
                      {/* <label className="control-label" htmlFor="refUserPicture"> */}
                      <label className="control-label">{t("photo")}</label>
                      {/* <Field
                                                id="refUserPicture"
                                                name="refUserPicture"
                                                className="form-control"
                                            /> */}

                      {formInitialValues?.refUserPicture ? (
                        <div className="mb-2 relative w-[148px] h-[148px]">
                          <img
                            src={formInitialValues?.refUserPicture}
                            alt="Cropped Preview"
                            className="w-full h-full object-cover rounded border border-gray-300"
                          />
                          <img
                            className="absolute top-1 right-1 w-10 h-10 p-2 bg-white rounded-full hover:cursor-pointer hover:scale-105"
                            title="Delete"
                            src="./images/delete.png"
                            alt="Delete Icon"
                            onClick={handleRemoveImage}
                          />
                        </div>
                      ) : (
                        <ExpertCropImageExpert
                          addUpdateProductPicture={addUpdateProductPicture}
                        />
                      )}
                      {/* {errorImg  && 
                        <div className="error">{errorImg}</div>
                      } */}

                      {/* {errors.refUserPicture ? (
                        <div className="error">{errors.refUserPicture}</div>
                      ) : null} */}
                    </div>
                  </div>

                  <fieldset>
                    <legend>{t("links")}</legend>

                    <div className="row">
                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="home_page_term"
                        >
                          {t("home_page_term")}
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="home_page_term"
                          name="home_page_term"
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <label className="control-label" htmlFor="homePageLink">
                          {t("home_page_link")}
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="homePageLink"
                          name="home_page_link"
                        />
                      </div>

                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="newsletterTerm"
                        >
                          {t("news_letter_term")}
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="newsletterTerm"
                          name="newsletter_term"
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="newsletterLink"
                        >
                          {t("news_letter_link")}
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="newsletterLink"
                          name="newsletter_link"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 form-group">
                        <label className="control-label" htmlFor="freebieTerm">
                          {t("freebie_term")}
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="freebieTerm"
                          name="freebie_term"
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <label
                          disabled={false}
                          className="control-label"
                          htmlFor="freebieLink"
                        >
                          {t("freebie_link")}
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="freebieLink"
                          name="freebie_link"
                        />
                      </div>

                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="contactEmailTerm"
                        >
                          {t("contact_email_term")}
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="contactEmailTerm"
                          name="contact_email_term"
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="contactEmailAddress"
                        >
                          {t("contact_email")}
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="contactEmailAddress"
                          name="contact_email_address"
                        />
                      </div>
                    </div>
                  </fieldset>

                  <fieldset>
                    <legend>{t("social_media_links")}</legend>
                    <div className="row">
                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="facebookLink">
                          Facebook link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="facebookLink"
                          name="facebook_link"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <label
                          className="control-label"
                          htmlFor="instagramLink"
                        >
                          Instagram link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="instagramLink"
                          name="instagram_link"
                        />
                      </div>

                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="twitterLink">
                          Twitter link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="twitterLink"
                          name="twitter_link"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="youtubeLink">
                          Youtube link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="youtubeLink"
                          name="youtube_link"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="vimeoLink">
                          Vimeo link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="vimeoLink"
                          name="vimeo_link"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <label
                          className="control-label"
                          htmlFor="pinterestLink"
                        >
                          Pinterest link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="pinterestLink"
                          name="pinterest_link"
                        />
                      </div>

                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="xingLink">
                          Xing link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="xingLink"
                          name="xing_link"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="linkedinLink">
                          Linkedin link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="linkedinLink"
                          name="linkedin_link"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="telegramLink">
                          Telegram link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="telegramLink"
                          name="telegram_link"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="podcastLink">
                          Podcast link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="podcastLink"
                          name="podcast_link"
                        />
                      </div>

                      <div className="col-lg-3 form-group">
                        <label className="control-label" htmlFor="spotifyLink">
                          Spotify link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="spotifyLink"
                          name="spotify_link"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <label
                          className="control-label"
                          htmlFor="clubhouseLink"
                        >
                          Clubhouse link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="clubhouseLink"
                          name="clubhouse_link"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 form-group">
                        <label className="control-label" htmlFor="tiktokLink">
                          Tiktok link
                        </label>
                        <Field
                          disabled={false}
                          className="form-control"
                          id="tiktokLink"
                          name="tiktok_link"
                        />
                      </div>
                    </div>
                  </fieldset>
                  {fetchingData == false && (
                    <div className="row">
                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="expert_short_description"
                        >
                          {t("expert_short_description")}
                        </label>
                        <Field
                          disabled={false}
                          component="textarea"
                          className="form-control"
                          id="expert_short_description"
                          name="expert_short_description"
                        />
                      </div>

                      <div className="col-lg-6 form-group">
                        <label
                          className="control-label"
                          htmlFor="expert_long_description"
                        >
                          {t("expert_long_description")}
                        </label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={values?.expert_long_description}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            //console.log('Editor is ready to use!', editor);
                          }}
                          onChange={(event, editor) => {
                            setFieldValue(
                              "expert_long_description",
                              editor.getData()
                            );
                            //console.log(editor.getData());
                          }}
                          onBlur={(event, editor) => {
                            //console.log('Blur.', editor);
                          }}
                          onFocus={(event, editor) => {
                            //console.log('Focus.', editor);
                          }}
                          disabled={false}
                        />
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-lg-12 form-group ">
                      <button
                        type="submit"
                        className="pull-right btn btn-primary loadingbtns"
                      >
                        {isLoader ? `${t("loading")}..` : t("save")}
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}

export default EditNewExpertModalNew;
